"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttackingMidfielderRoleBonuses = exports.AttackingMidfielderExpandedRoles = void 0;
// attackingMidfielderRoles.ts
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var AttackingMidfielderExpandedRoles;
(function (AttackingMidfielderExpandedRoles) {
    AttackingMidfielderExpandedRoles["RAPID_ATTACKING_MIDFIELDER"] = "RAPID_ATTACKING_MIDFIELDER";
    AttackingMidfielderExpandedRoles["POWERFUL_ATTACKING_MIDFIELDER"] = "POWERFUL_ATTACKING_MIDFIELDER";
    AttackingMidfielderExpandedRoles["ATHLETIC_ATTACKING_MIDFIELDER"] = "ATHLETIC_ATTACKING_MIDFIELDER";
    AttackingMidfielderExpandedRoles["ATTACKING_PLAYMAKER"] = "ATTACKING_PLAYMAKER";
    AttackingMidfielderExpandedRoles["TRICKY_ATTACKING_MIDFIELDER"] = "TRICKY_ATTACKING_MIDFIELDER";
    AttackingMidfielderExpandedRoles["TRICKY_PLAYMAKER"] = "TRICKY_PLAYMAKER";
    AttackingMidfielderExpandedRoles["DETERMINED_ATTACKER"] = "DETERMINED_ATTACKER";
    AttackingMidfielderExpandedRoles["HEROIC_ATTACKER"] = "HEROIC_ATTACKER";
    AttackingMidfielderExpandedRoles["ATTACKING_TALISMAN"] = "ATTACKING_TALISMAN";
    AttackingMidfielderExpandedRoles["CATALYST"] = "CATALYST";
    AttackingMidfielderExpandedRoles["TIKI_TAKA_FORWARD"] = "TIKI_TAKA_FORWARD";
})(AttackingMidfielderExpandedRoles || (exports.AttackingMidfielderExpandedRoles = AttackingMidfielderExpandedRoles = {}));
exports.AttackingMidfielderRoleBonuses = {
    ATTACKING_MIDFIELDER: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            pass: 3,
            shoot: 2,
            run: 1,
        },
        positionsEnabled: [zones_1.Zones.MIDFIELD, zones_1.Zones.ATTACK], //Midfield and attack
        playerTraits: [],
    },
    RAPID_ATTACKING_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_ATTACKING_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    ATHLETIC_ATTACKING_MIDFIELDER: {
        parentRoles: [
            AttackingMidfielderExpandedRoles.RAPID_ATTACKING_MIDFIELDER,
            AttackingMidfielderExpandedRoles.POWERFUL_ATTACKING_MIDFIELDER,
        ],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    ATTACKING_PLAYMAKER: {
        parentRoles: [baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.PLAYMAKER],
    },
    TRICKY_ATTACKING_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR],
    },
    TRICKY_PLAYMAKER: {
        parentRoles: [AttackingMidfielderExpandedRoles.TRICKY_ATTACKING_MIDFIELDER, AttackingMidfielderExpandedRoles.ATTACKING_PLAYMAKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    DETERMINED_ATTACKER: {
        parentRoles: [baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DETERMINED],
    },
    HEROIC_ATTACKER: {
        parentRoles: [AttackingMidfielderExpandedRoles.DETERMINED_ATTACKER, AttackingMidfielderExpandedRoles.TRICKY_ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.HERO],
    },
    ATTACKING_TALISMAN: {
        parentRoles: [AttackingMidfielderExpandedRoles.HEROIC_ATTACKER, AttackingMidfielderExpandedRoles.TRICKY_PLAYMAKER],
        rarity: rarity_1.Rarity.LEGENDARY,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    CATALYST: {
        parentRoles: [AttackingMidfielderExpandedRoles.ATTACKING_PLAYMAKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DETERMINED],
    },
    TIKI_TAKA_FORWARD: {
        parentRoles: [baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.TIKI_TAKA],
    },
};
