// --- PitchComponent.tsx ---
import { Box, Card, Orientation } from '@mui/material';
import React from 'react';

interface PitchProps {
    children?: React.ReactNode;
    orientation?: Orientation;
    height?: string;
    width?: string;
}

// Defaults for dimension calculations
const defaultHeight = '50vh';
const defaultWidth = '35vh';
const defaultOrientation = 'vertical';

export const PitchComponent = (props: PitchProps) => {
    const height = props.height ?? defaultHeight;
    const width = props.width ?? defaultWidth;
    const orientation = props.orientation ?? defaultOrientation;

    const cardHeight = orientation === 'vertical' ? height : width;
    const cardWidth = `min(${orientation === 'vertical' ? width : height}, 95vw)`;

    const imageTransform: React.CSSProperties = {};
    if (orientation === 'horizontal') {
        imageTransform.transform = 'rotate(90deg)';
    }

    const imageHeight = orientation === 'vertical' ? cardHeight : cardWidth;
    const imageWidth = orientation === 'vertical' ? cardWidth : cardHeight;

    const topOffset = orientation === 'horizontal'
        ? `calc((${imageWidth} - ${imageHeight}) / 2)`
        : '0px';
    const leftOffset = orientation === 'horizontal'
        ? `calc((${imageHeight} - ${imageWidth}) / 2)`
        : '0px';

    return (
        <Card
            sx={{
                position: 'relative',
                width: cardWidth,
                height: cardHeight,
                overflow: 'hidden',
                flexGrow: 0,
                flexShrink: 0,
            }}
        >
            {/* Background Image Container */}
            <Box sx={{
                position: 'absolute',
                top: topOffset,
                left: leftOffset,
                width: imageWidth,
                height: imageHeight,
                ...imageTransform,
            }}>
                <img
                    src='/images/boards/mikeCheck.svg'
                    style={{
                        width: '100%',
                        height: '100%',
                        // Explicitly set 'fill' to stretch the image
                        objectFit: 'fill',
                    }}
                    alt='pitchBackground'
                />
            </Box>

            {/* Container for positioned children */}
            <Box sx={{ position: 'relative', width: '100%', height: '100%', zIndex: 1 }}>
                 {props.children}
            </Box>
        </Card>
    );
};