import { Stack, Typography } from '@mui/material';
import React from "react";
import { BadgeComponent } from '../../../base/badge/badge';
import './Match.css';
import { HasMatchState } from "./MatchStateViewer";

import '@fontsource/norwester';

const impactFonts = ['Norwester', 'sans-serif'].join(',');

export const ScoreboardComponent = (props:HasMatchState) => {
    const homeTeam = props.matchState.teams[props.matchState.homeTeam]
    const awayTeam = props.matchState.teams[props.matchState.awayTeam]
    return <Stack direction='row' alignContent={'center'} justifyContent={'center'} sx={{ margin: '2vmin' }}>
        <BadgeComponent {...homeTeam}/>
        <Stack alignContent={'center'} justifyContent={'center'} sx={{ margin: '2vmin' }} >
            <Typography variant="h2" sx={{textAlign: 'center', fontSize:'min(10vw,5vh)', fontFamily: impactFonts}}>{homeTeam.score}:{awayTeam.score}</Typography>
            <Typography variant="h5" sx={{textAlign: 'center', fontSize:'min(3vw, 1.5vh)', fontFamily: impactFonts}}> {(props.matchState.rounds.length-1)*5}:00</Typography>
        </Stack>
        <BadgeComponent {...awayTeam} />
    </Stack>
}