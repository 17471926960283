// --- TeamNav.tsx --- (Conditionally render My Team and Play buttons)

import GroupsIcon from '@mui/icons-material/Groups';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TeamContext } from "./TeamContext";

export const TeamNav = () => {
    const history = useHistory();
    const teamContext = useContext(TeamContext);
    const baseUrl = teamContext.getBaseUrl();
    const location = useLocation();

    const [selected, setSelected] = useState<string | undefined>(undefined);

    // --- Determine visibility conditions ---
    const teamConfig = teamContext.getTeamConfig();
    const team = teamContext.getTeam();
    const hasPlayers = (team?.players?.length ?? 0) > 0;
    const isDefaultLineupComplete = teamConfig?.lineups?.default?.startingLineup?.length === 11;
    // --- End Visibility Checks ---


    useEffect(() => {
        const path = location.pathname;
        let currentSelectedItem: string | undefined = undefined;

        // Determine selected based on path and *visibility* of the section
        if (path.startsWith(`${baseUrl}/options`)) {
            currentSelectedItem = 'options';
        } else if (isDefaultLineupComplete && path.startsWith(`${baseUrl}/play`)) { // Check visibility
            currentSelectedItem = 'play';
        } else if (hasPlayers && path.startsWith(`${baseUrl}/myteam/editLineup/default`)) { // Check visibility
            currentSelectedItem = 'myteam/editLineup/default';
        } else if (path.startsWith(`${baseUrl}/news`) || path === baseUrl || path === `${baseUrl}/`) {
            // Default to 'news' if it matches or if it's the base path
             currentSelectedItem = 'news';
        }
        // If none match (e.g., invalid URL), selected might remain undefined or keep previous value
        setSelected(currentSelectedItem);

    }, [location.pathname, baseUrl, hasPlayers, isDefaultLineupComplete]); // Add visibility flags as dependencies


    return (<>
        {/* Invisible Spacer - Apply same conditional rendering */}
        <Paper sx={{ opacity: 0, pointerEvents: 'none' }}><BottomNavigation>
            <BottomNavigationAction label="News" value="news" icon={<NewspaperIcon />} />
            {hasPlayers && (
                <BottomNavigationAction label="My Team" value="myteam/editLineup/default" icon={<GroupsIcon />} />
            )}
            {isDefaultLineupComplete && (
                <BottomNavigationAction label="Play" value="play" icon={<SportsSoccerIcon />} />
            )}
            <BottomNavigationAction label="Options" value="options" icon={<SettingsApplicationsIcon />} />
        </BottomNavigation></Paper>

        {/* Actual Fixed Bottom Navigation */}
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={selected}
                onChange={(event, newValue) => {
                    // Prevent navigation if somehow clicked while hidden (shouldn't happen)
                    if (newValue === 'myteam' && !hasPlayers) return;
                    if (newValue === 'play' && !isDefaultLineupComplete) return;
                    history.push(`${baseUrl}/${newValue}`);
                }}
            >
                {/* News is always visible */}
                <BottomNavigationAction label="News" value="news" icon={<NewspaperIcon />} />

                {/* Conditionally render My Team */}
                {hasPlayers && (
                     <BottomNavigationAction label="My Team" value="myteam/editLineup/default" icon={<GroupsIcon />} />
                )}

                {/* Conditionally render Play */}
                {isDefaultLineupComplete && (
                     <BottomNavigationAction label="Play" value="play" icon={<SportsSoccerIcon />} />
                )}

                 {/* Options is always visible */}
                <BottomNavigationAction label="Options" value="options" icon={<SettingsApplicationsIcon />} />
            </BottomNavigation>
        </Paper></>
    );
};