"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Level = exports.PlayerLevel = exports.PackLevel = void 0;
var PackLevel;
(function (PackLevel) {
    // IRON = 1,
    PackLevel[PackLevel["COPPER"] = 1] = "COPPER";
    PackLevel[PackLevel["BRONZE"] = 5] = "BRONZE";
    PackLevel[PackLevel["SILVER"] = 9] = "SILVER";
    PackLevel[PackLevel["GOLD"] = 12] = "GOLD";
    PackLevel[PackLevel["PLATINUM"] = 15] = "PLATINUM";
    PackLevel[PackLevel["DIAMOND"] = 17] = "DIAMOND";
})(PackLevel || (exports.Level = exports.PackLevel = PackLevel = {}));
var PlayerLevel;
(function (PlayerLevel) {
    // Amateur Levels (1-4)
    // Kickabout with friends, Sunday league, early development.
    PlayerLevel[PlayerLevel["AMATEUR_BEGINNER"] = 1] = "AMATEUR_BEGINNER";
    PlayerLevel[PlayerLevel["AMATEUR_RECREATIONAL"] = 2] = "AMATEUR_RECREATIONAL";
    PlayerLevel[PlayerLevel["AMATEUR_SERIOUS"] = 3] = "AMATEUR_SERIOUS";
    PlayerLevel[PlayerLevel["AMATEUR_ALMOST_PRO"] = 4] = "AMATEUR_ALMOST_PRO";
    // Lower League Levels (5-8)
    // Semi-professional or lower professional leagues.
    PlayerLevel[PlayerLevel["LOWER_LEAGUE_NATIONAL_LEAGUE"] = 5] = "LOWER_LEAGUE_NATIONAL_LEAGUE";
    PlayerLevel[PlayerLevel["LOWER_LEAGUE_LEAGUE_2"] = 6] = "LOWER_LEAGUE_LEAGUE_2";
    PlayerLevel[PlayerLevel["LOWER_LEAGUE_LEAGUE_1"] = 7] = "LOWER_LEAGUE_LEAGUE_1";
    PlayerLevel[PlayerLevel["LOWER_LEAGUE_CHAMPIONSHIP"] = 8] = "LOWER_LEAGUE_CHAMPIONSHIP";
    // Premier League Levels (9-12)
    // Established players in top national leagues.
    PlayerLevel[PlayerLevel["PREMIER_LEAGUE_RELEGATION_BATTLER"] = 9] = "PREMIER_LEAGUE_RELEGATION_BATTLER";
    PlayerLevel[PlayerLevel["PREMIER_LEAGUE_BOTTOM_HALF"] = 10] = "PREMIER_LEAGUE_BOTTOM_HALF";
    PlayerLevel[PlayerLevel["PREMIER_LEAGUE_MID_TABLE"] = 11] = "PREMIER_LEAGUE_MID_TABLE";
    PlayerLevel[PlayerLevel["PREMIER_LEAGUE_TOP_HALF"] = 12] = "PREMIER_LEAGUE_TOP_HALF";
    // Champions League Levels (13-16)
    // Consistent performers at the highest continental level.
    PlayerLevel[PlayerLevel["CHAMPIONS_LEAGUE_GROUP_STAGES"] = 13] = "CHAMPIONS_LEAGUE_GROUP_STAGES";
    PlayerLevel[PlayerLevel["CHAMPIONS_LEAGUE_R8"] = 14] = "CHAMPIONS_LEAGUE_R8";
    PlayerLevel[PlayerLevel["CHAMPIONS_LEAGUE_SEMI_FINALISTS"] = 15] = "CHAMPIONS_LEAGUE_SEMI_FINALISTS";
    PlayerLevel[PlayerLevel["CHAMPIONS_LEAGUE_CHAMPION"] = 16] = "CHAMPIONS_LEAGUE_CHAMPION";
    // Legendary Levels (17-20)
    // Transcendent talents, among the greatest of their generation.
    PlayerLevel[PlayerLevel["LEGEND_NATIONAL"] = 17] = "LEGEND_NATIONAL";
    PlayerLevel[PlayerLevel["LEGEND_CONTINENTAL"] = 18] = "LEGEND_CONTINENTAL";
    PlayerLevel[PlayerLevel["LEGEND_GLOBAL"] = 19] = "LEGEND_GLOBAL";
    PlayerLevel[PlayerLevel["LEGEND_GOAT"] = 20] = "LEGEND_GOAT";
})(PlayerLevel || (exports.PlayerLevel = PlayerLevel = {}));
