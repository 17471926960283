import { RoundResult, RoundState, TeamState } from "@alienheadwars/football-card-manager-model"
import { Card } from "@mui/material"
import React, { useState } from "react"
import { localiseAndReplace } from "../../../../../locale/locale"


export interface CommentaryProps {
    roundState: RoundState;
    teams: {
        [key: string]: TeamState;
    },
    showResult: boolean,
    fontSize?:string
}
export const CommentaryComponent = (props: CommentaryProps) => {

    console.log(`showResult:${props.showResult} roundResult:${props.roundState.roundResult}`)

    const colours = props.teams[props.roundState.attackingTeam].colours
    const attackingTeamBackgroundColour = colours?.primaryBackgroundColour ?? 'white'
    const attackingTeamFontColour = colours?.primaryTextColour ?? 'black'
    const defendingTeamBackgroundColour = props.teams[props.roundState.defendingTeam].colours?.primaryBackgroundColour ?? 'white'
    const defendingTeamFontColour = props.teams[props.roundState.defendingTeam].colours?.primaryTextColour ?? 'black'
    const goalBackgroundColour = colours?.primaryColour ?? 'grey'

    const [backgroundColour, setBackgroundColour] = useState(attackingTeamBackgroundColour)
    const [fontColour, setFontColour] = useState(colours?.primaryTextColour ?? 'black')

    // If goal is scored, for 3 seconds alternate the background colour every 250ms
    React.useEffect(() => {
        if (props.showResult && props.roundState.roundResult && [RoundResult.DEFENSE_WIN, RoundResult.SHOT_SAVED, RoundResult.ATTACK_STALL].includes(props.roundState.roundResult)) {
            setBackgroundColour(defendingTeamBackgroundColour)
            setFontColour(defendingTeamFontColour)
        }
        else if (props.showResult && props.roundState.roundResult === RoundResult.GOAL) {
            let count = 0;
            const interval = setInterval(() => {
                setBackgroundColour(count % 2 === 0 ? goalBackgroundColour : attackingTeamBackgroundColour);
                count++;
            }, 250);
            setTimeout(() => {
                clearInterval(interval);
                setBackgroundColour(attackingTeamBackgroundColour);
            }, 3000);
        }
        else {
            setBackgroundColour(attackingTeamBackgroundColour)
            setFontColour(attackingTeamFontColour)
        }
    }, [
        props.showResult,
        props.roundState.roundResult,
         goalBackgroundColour, 
         attackingTeamBackgroundColour, 
         defendingTeamBackgroundColour, 
         attackingTeamFontColour, 
         defendingTeamFontColour, 
         fontColour
        ]);



    let commentaryText = ''
    if (props.roundState.attackingPlayer === undefined) {
        commentaryText = localiseAndReplace({ id: 'match.commentary.kickoff', replacements: { 'teamInPossession': props.teams[props.roundState.attackingTeam].name } })
    }
    else if (!props.roundState.roundFinished) (
        commentaryText = localiseAndReplace({
            id: `match.commentary.possession.${props.roundState.zone}`, replacements: {
                'teamInPossession': props.teams[props.roundState.attackingTeam].name,
                'playerInPossession': `player.${props.roundState.attackingHand[props.roundState.attackingPlayer].playerDetails.id}`
            }
        })
    )
    else if(!props.showResult){
        commentaryText = localiseAndReplace({
            id: `match.commentary.action.${props.roundState.action}`, replacements: {
                'teamInPossession': props.teams[props.roundState.attackingTeam].name,
                'playerInPossession': `player.${props.roundState.attackingHand[props.roundState.attackingPlayer].playerDetails.id}`,
                'targetPlayer': `player.${props.roundState.attackingHand[props.roundState.attackingTargetPlayer!]?.playerDetails?.id}`,
                'defender': `player.${props.roundState.defendingHand[props.roundState.defendingPlayer!]?.playerDetails?.id}`,
                'goalie': `player.${props.roundState.defendingHand[0].playerDetails.id}`
            }
        })
    }
    else {
        commentaryText = localiseAndReplace({
            id: `match.commentary.roundResult.${props.roundState.roundResult}`, replacements: {
                'teamInPossession': props.teams[props.roundState.attackingTeam].name,
                'playerInPossession': `player.${props.roundState.attackingHand[props.roundState.attackingPlayer].playerDetails.id}`,
                'targetPlayer': `player.${props.roundState.attackingHand[props.roundState.attackingTargetPlayer!]?.playerDetails?.id}`,
                'defender': `player.${props.roundState.defendingHand[props.roundState.defendingPlayer!]?.playerDetails?.id}`,
                'goalie': `player.${props.roundState.defendingHand[0].playerDetails.id}`
            }
        })
    }
    const fontSize =props.fontSize ?? '1.5vh'
    return <Card sx={{
        backgroundColor: backgroundColour,
        color: fontColour,
        width: '100%',
        // height: '4vh',
        padding: `calc(${fontSize}/3)`,
        lineHeight: `calc(${fontSize}*4/3)`,
        fontSize: fontSize,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '1vh'
    }}>{commentaryText}</Card>
}
