import React from 'react';
// 1. Import createPortal
import ReactDOM from 'react-dom';
import './Overlay.css';

export interface OverlayProps {
    children?: any;
    onclick?(): void;
}

const doNothing = () => {};

export const Overlay = (props: OverlayProps) => {
    // 2. Create the overlay div structure
    const overlayContent = (
        // The className 'overlay' should contain position: fixed/absolute, z-index: 1000, etc.
        <div className='overlay' onClick={props.onclick ?? doNothing} style={{ margin: 0 }}>
            {props.children}
        </div>
    );

    // 3. Get the portal target (usually document.body)
    // Ensure this runs only client-side if using SSR frameworks like Next.js
    const portalTarget = typeof window !== 'undefined' ? document.body : null;

    // 4. Render using createPortal if target exists
    return portalTarget ? ReactDOM.createPortal(overlayContent, portalTarget) : null;
    // Alternatively, if SSR isn't a concern:
    // return ReactDOM.createPortal(overlayContent, document.body);
};