import LocalizedStrings from 'react-localization';
import { enWorldTourPlayerNames } from './organised/playerInfo/worldTourPlayers'
import { enTraits } from './organised/playerInfo/traits'
import { enPlayerRoles } from './organised/playerInfo/playerRoles'
import { enRaces } from './organised/playerInfo/races'
import { enNationalities } from './organised/playerInfo/nationalities'
export const strings = new LocalizedStrings({
  en: {
    ...enWorldTourPlayerNames,
    ...enTraits,
    ...enPlayerRoles,
    ...enRaces,
    ...enNationalities,

    starterPack: 'Starter Pack',
    copperPack: 'Consolation Copper Pack',
    bronzePack: 'Brilliant Bronze Pack',
    silverPack: 'Super Silver Pack',
    goldPack: 'Great Gold Pack',
    platinumPack: 'Phenomenal Platinum Pack',
    diamondPack: 'Dazzling Diamond Pack',

    'packSummary.newItems': 'New items',
    'packSummary.duplicatedItems': 'Duplicated items',
    'pack.confirmButton': 'Awesome!',

    // Add these keys to your en: { ... } block
    'button.done': 'Done',
    'lineup.select': 'Select', // Action button text on replacement card
    'lineup.selectPitchPlayerPrompt': 'Select a player from the pitch to choose a replacement.', // Prompt when no pitch player selected

    'lineup.replacing': 'You are replacing',
    'lineup.replace': 'Replace',
    'lineup.replacementPlayers': 'Replacement Players',
    'lineup.swap': 'Swap Position',
    'lineup.noPlayersFound': 'No players match the currently provided filters',
    'search.label': 'Search',
    'button.cancel': 'Cancel',

    COPPER: 'copper',
    BRONZE: 'bronze',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum',
    DIAMOND: 'diamond',

    'gb-eng': 'England',
    'gb-sct': 'Scotland',
    'gb-wls': 'Wales',
    'it': 'Italy',

    'match.set.attackingPlayer.help': 'Choose a player to receive kick off',
    'match.set.attackingTargetPlayer.help': 'Choose a player to either receive the ball or make a dummy run',
    'match.set.defendingPlayer.help': 'Choose a player to challenge the attacker',
    'match.attackStall.help': 'Choose this option to do ... nothing. Risky. You will probably lose possession.',
    'match.attackStall': 'Stall',
    'match.pass.help': 'Pass the ball to the chosen player. The current player in possession will be discarded if succesfull or unsuccesful',
    'match.pass': 'Pass',
    'match.run.help': 'Advance up the pitch by running with the ball. The chosen player will make a dummy run and will be discarded if succesfull. The current player in possession will be discarded if unsuccesfull.',
    'match.run': 'Run',
    'match.shoot.help': 'Take a shot on goal. This may be contested by a defender and the goalkeeper. The player shooting will be discarded if succesful or unsuccesful',
    'match.shoot': 'Shoot',
    'match.defendStall.help': 'This option chooses to do nothing, allow the attacker to do what they want unopposed by any defender (Goalies may still attempt a save). It will be your only option if you run out of cards.',
    'match.defendStall': 'Ignore',
    'match.contest.help': 'Contest the attacker. If succesful the defender gains possession, if unsuccessful the defender gets discarded',
    'match.contest': 'Contest',
    'match.kickOff.help': 'Kick off the match',
    'match.kickOff': 'Receive kick off',

    cancel: 'Cancel',
    chooseMatchAction: 'Choose an action',

    'openPack.helpText': 'Tap to open!',

    'PASS': 'Pass',
    'RUN': 'Run',
    'SHOOT': 'Shoot',
    'SURRENDER_BALL': 'Give up',
    'match.commentary.kickoff': '_teamInPossession_ kick off!',
    'match.commentary.possession.ATTACK': '_teamInPossession_ have the ball in attack, with _playerInPossession_ in possession',
    'match.commentary.possession.MIDFIELD': '_teamInPossession_ have the ball in midfield, with _playerInPossession_ in possession',
    'match.commentary.possession.DEFENSE': '_teamInPossession_ have the ball in defense, with _playerInPossession_ in possession',
    'match.commentary.roundResult.GOAL': 'GOAL! GOAL! GOAL! _playerInPossession_ scores for _teamInPossession_',
    'match.commentary.roundResult.SHOT_SAVED': '_goalie_ saves it!',
    'match.commentary.roundResult.PASS_SUCCESSFUL': '_playerInPossession_ plays it around _defender_ to _targetPlayer_',
    'match.commentary.action.PASS': '_playerInPossession_ attempts to pass to _targetPlayer_, _defender_ is in his way',
    'match.commentary.action.RUN': '_playerInPossession_ tries to run past _defender_',
    'match.commentary.action.SHOOT': '_playerInPossession_ shoots at goal, _defender_ and _goalie_ stand in his way',

    'match.commentary.roundResult.RUN_UNCONTESTED': '_playerInPossession_ runs into space',
    'match.commentary.roundResult.PASS_UNCONTESTED': '_playerInPossession_ passes to _targetPlayer_ in space',
    'match.commentary.roundResult.ATTACK_STALL': '_playerInPossession_ seems to have run out of steam, _defender_ takes the ball',
    'match.commentary.roundResult.BOTH_STALL': '_playerInPossession_ is dawdling, but no one wants the ball',

    'match.commentary.roundResult.RUN_SUCCESSFUL': '_playerInPossession_ runs past _defender_',
    'match.commentary.roundResult.DEFENSE_WIN': '_defender_ wins the ball from _playerInPossession_',
    'match.commentary.roundResult.DRAW': '_playerInPossession_ and _defender_ battle for the ball, _playerInPossession_ still has it',


    'modifier.level': "Level",
    'modifier.pass': "Passing skill",
    'modifier.run': "Running skill",
    'modifier.shoot': "Shooting skill",
    'modifier.tackle': "Tackling skill",
    'modifier.block': "Blocking skill",
    'modifier.intercept': "Intercepting skill",
    'modifier.POWERFUL': "Powerful trait",
    'modifier.RAPID': "Rapid trait",
    'modifier.LUCKY': "Lucky trait",
    'modifier.FLAIR': "Flair trait",
    'modifier.TIKI_TAKA': "Tiki Taka trait",
    'modifier.UNDERDOG': "Underdog trait",
    'modifier.DETERMINED': "Determined trait",
    'modifier.DOMINANT': "Dominant trait",
    'modifier.HERO': "Hero trait",
    'modifier.STAR': "Star trait",
    'modifier.INTELLIGENCE': "Intelligence trait",
    'modifier.MANS_BEST_FRIEND': "Man's Best Friend trait",
    'modifier.PRODIGY': "Prodigy trait",
    'modifier.ADAPTABLE': "Adaptable trait",
    'modifier.RELIABLE': "Reliable trait",
    'modifier.LEGEND_OF_LEGENDS': "Legend of Legends trait",
    'modifier.SOUL_SAP': "Soul Sap trait",
    'modifier.COVERING_TACKLER': "Covering Tackler trait",
    'modifier.SWEEPER': "Sweeper trait",
    'modifier.GOAL_KEEPER': "Goal Keeper trait",
    'modifier.DIRECT': "Direct trait",
    'modifier.PRESS_RESISTANT': "Press Resistant trait",
    'modifier.CHARGE': "Charge trait",
    'modifier.CLINICAL': "Clinical trait",
    'modifier.UNYIELDING': "Unyielding trait",
    'modifier.preferredOpponent': "Bonus vs",



    'challenge.playerscore.total': 'Total',

    'playerroles.bonuses': 'Role bonuses',
    'playerroles.traits': 'Role traits',


    'races.bonuses': 'Racial bonuses',
    'races.traits': 'Racial traits',


    //national levels
    'national.level.HERO': 'Hero',
    'national.level.ICON': 'Icon',

    'national.bonus': 'National bonus',
    'national.favoured-enemy': 'Favoured enemy',
    'national.hero-trait': 'National hero trait',
    'national-icon': 'National icon',

    //player names
    'player.goats/goat': 'GOAT',
    'player.goats/portugese-devil': 'Portuguese Devil',


    'world-tour.league.great-britain.name': 'Great Britain',
    'world-tour.league.south-europe.name': 'Southern Europe',


    'world-tour.name': 'World Tour',

    'world-tour.locktext.previous-team': 'Beat the previous team to unlock this one',

    'world-tour.region.great-britain.name': 'Great Britain',
    'world-tour.region.great-britain.description': 'Embrace the thrill of the fight as you begin your tour in Great Britain, where unexpected results and passionate play rule the day.',
    // africa
    'world-tour.region.africa.name': 'Africa',
    'world-tour.region.africa.description': 'Embark on a vibrant adventure across Africa, where diverse landscapes and resilient teams await your challenge.',

    // asia
    'world-tour.region.asia.name': 'Asia',
    'world-tour.region.asia.description': 'Traverse the vast continent of Asia, encountering legendary teams steeped in tradition and unwavering spirit.',

    // central-europe
    'world-tour.region.central-europe.name': 'Central Europe',
    'world-tour.region.central-europe.description': 'Navigate the strategic landscape of Central Europe, where disciplined teams and a legacy of tactical innovation await.',

    // north-america
    'world-tour.region.north-america.name': 'North America',
    'world-tour.region.north-america.description': 'Test your skills in North America, home to fast-paced play and teams driven by relentless ambition.',

    // oceania
    'world-tour.region.oceania.name': 'Oceania',
    'world-tour.region.oceania.description': 'Explore the island nations of Oceania, where passionate teams play with a fierce and unique island spirit.',

    // south-america
    'world-tour.region.south-america.name': 'South America',
    'world-tour.region.south-america.description': 'Experience the fiery passion of South America, where technical brilliance and undeniable flair define the teams.',

    // south-europe
    'world-tour.region.south-europe.name': 'Southern Europe',
    'world-tour.region.south-europe.description': 'Embrace the warmth of Southern Europe, where expressive play and a flair for the dramatic define the competition.',

    'world-tour.league.great-britain/sunday-league.name': 'Sunday League',
    'world-tour.league.great-britain/sunday-league.description': 'Where passion outpaces polish and camaraderie clashes with competitive fire.',

    'world-tour.league.great-britain/welsh-league.name': 'Wales',
    'world-tour.league.great-britain/welsh-league.description': 'Prepare to test your mettle against teams steeped in passion, resilience, and a touch of magic.',
    'world-tour.league.great-britain/welsh-league.locktext': 'Beat every team in the Sunday league to unlock Wales',

    'world-tour.league.great-britain/scottish-league.name': 'Scotland',
    'world-tour.league.great-britain/scottish-league.description': 'A crucible of soccer heritage, brimming with fervent teams that embody the rugged, indomitable spirit of Scotland.', 'world-tour.league.great-britain/scottish-league.locktext': 'Beat every team in Wales to unlock Scotland',
    'world-tour.league.scotland.locktext': 'Beat every team in Wales to unlock Scotland',

    'world-tour.league.great-britain/english-league.name': 'England',
    'world-tour.league.great-britain/english-league.description': 'Step into the heart of football nobility where legends are born and rivalries run deep.',
    'world-tour.league.great-britain/english-league.locktext': 'Beat every team in Scotland to unlock England',

    'world-tour.league.south-europe/italian-league.name': 'Serie Novanta',
    'world-tour.league.south-europe/italian-league.description': "Enter the Gladiator's Arena: 'Step into a league where every match is a battle and legends are forged in the heat of competition",
    'world-tour.league.south-europe/italian-league.locktext': 'Beat every team in England to unlock Italy',

    'world-tour.challenge.play': 'Play a game against this team',
    'world-tour.challenge.win': 'Defeat this team',
    'world-tour.challenge.win-by-x': 'Defeat this team by _x_ or more goals',
    'world-tour.challenge.win-same-league': 'Defeat this team by using only players from the _league_ league',
    'world-tour.challenge.win-same-nation': 'Defeat this team by using only players from the _nation_ nation',
    'world-tour.challenge.win-same-league-or-nation': 'Defeat this team by using only players from the _league_ league or the _nation_ nation',

    'worldTourTeamCard.unlock': 'Defeat the previous team to unlock',


    'world-tour.challenge.BEAT': 'Defeat this team',
    'world-tour.challenge.x-team-stars': 'Complete _x_ team challenges',
    'world-tour.challenge.x-league-stars': 'Complete _x_ league challenges',

    'pack.starter-pack.name': 'Starter pack',
    'pack.starter-pack.description': "Contains 14 players to start your club's journey",

    'pack.world-tour-pack.name': 'World tour level _level_ pack',
    'pack.world-tour-pack.description': 'Contains 3 world tour players, with 1 guaranteed to be _level_ or higher.',

    'pack.world-tour-team-pack.name': 'World tour _teamName_ level _level_ pack',
    'pack.world-tour-team-pack.description': "Contains 3 world tour players, with 1 player guaranteed to be level _level_ or higher from '_teamName_'.",

    'pack.world-tour-league-pack.name': 'World tour _leagueId_ level _level_ pack',
    'pack.world-tour-league-pack.description': "Contains 3 world tour players, with 1 guaranteed to be level _level_ or higher from the '_leagueId_'",

    'pack.world-tour-nation-pack.name': 'World tour _nation_ level _level_ pack',
    'pack.world-tour-nation-pack.description': "Contains 3 world tour players, with 1 guaranteed to be level _level_ or higher from '_nation_'",




    'tutorial.newPack': 'You have a pack of football cards to open!'

  },
});

export const localise = (propertyName: string): string => {
  //@ts-expect-error
  return strings[propertyName] ?? propertyName
}

export const localiseAndReplace = (props: { id: string, replacements?: { [key: string]: string |number } }): string => {
  var localised = localise(props.id)
  for (const [key, value] of Object.entries(props.replacements ?? {})) {
    localised = localised.replaceAll(`_${key}_`, localise(value.toString()))
  }
  return localised
}