import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
    ReactNode,
    useCallback
} from 'react';

interface VisibilityContextType {
    visibilityState: DocumentVisibilityState;
    registerVisibilityCallback: (visibilityCallback: () => (() => void) | void) => void; // Using generic name visibilityCallback
}

const VisibilityContext = createContext<VisibilityContextType | undefined>(undefined);

interface VisibilityProviderProps {
    children: ReactNode;
}

export const VisibilityProvider: React.FC<VisibilityProviderProps> = ({ children }) => {
    const [visibilityState, setVisibilityState] = useState(document.visibilityState);
    const visibilityCallbacks = useRef<(() => (() => void) | void)[]>([]); // Ref to store an array of visibility callbacks. These callbacks are executed based on visibility changes.

    const registerVisibilityCallback = useCallback((visibilityCallback: () => (() => void) | void) => { // Using generic name visibilityCallback
        console.log('Registering and immediately invoking visibility callback');
        visibilityCallbacks.current.push(visibilityCallback);
        visibilityCallback(); // **Immediately invoke visibilityCallback to perform initial action on registration.**
    }, []);


    const handleVisibilityChange = useCallback(() => {
        setVisibilityState(document.visibilityState);
        if (document.visibilityState === 'visible') {
            console.log("Page became visible. Executing visibility callbacks.");
            // Execute all registered visibility callbacks.
            visibilityCallbacks.current.forEach(visibilityCallback => {
                visibilityCallback(); // Execute visibility callback on becoming visible.
            });
        } else {
            console.log("Page became hidden. Cleaning up (unsubscribing) visibility callbacks.");
            // Cleanup (unsubscribe) all visibility callbacks if they return unsubscribe functions.
            visibilityCallbacks.current.forEach(visibilityCallback => {
                const unsubscribeFn = visibilityCallback();
                if (unsubscribeFn) {
                    unsubscribeFn();
                }
            });
        }
    }, []);


    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // **Initial Callback Execution on Mount:** - Correct place to execute callbacks when VisibilityProvider mounts.
        if (document.visibilityState === 'visible') {
            console.log("Initial execution of visibility callbacks (page visible on mount).");
            visibilityCallbacks.current.forEach(visibilityCallback => {
                visibilityCallback(); // **Initial execution of visibility callbacks - CORRECT PLACE - in useEffect on mount**
            });
        }

        // **Capture current ref value for cleanup:** - To avoid stale closure issues, capture the current ref value for use in the cleanup function.
        const currentVisibilityCallbacks = visibilityCallbacks.current;

        return () => {
            console.log("Visibility Provider unmounting. Cleaning up all visibility callbacks and removing visibility listener.");
            // Use the captured ref value (currentVisibilityCallbacks) in the cleanup function.
            currentVisibilityCallbacks.forEach(visibilityCallback => {
                const unsubscribeFn = visibilityCallback();
                if (unsubscribeFn) {
                    unsubscribeFn();
                }
            });
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [handleVisibilityChange]); // Dependency array includes handleVisibilityChange useCallback to prevent infinite effect loops.


    const value: VisibilityContextType = {
        visibilityState,
        registerVisibilityCallback, // Using generic name registerVisibilityCallback
    };

    return (
        <VisibilityContext.Provider value={value}>
            {children}
        </VisibilityContext.Provider>
    );
};

export const useVisibility = () => {
    const context = useContext(VisibilityContext);
    if (!context) {
        throw new Error("useVisibility must be used within a VisibilityProvider");
    }
    return context;
};