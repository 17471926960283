import { CalculatedPlayer, Colours, Modifier, PlayerRoundScore, RoundState, TeamState } from "@alienheadwars/football-card-manager-model";
import React, { useEffect, useMemo, useState } from "react";
import { Overlay } from "../../../../base/overlay/Overlay";
import { PlayerCardComponent, PlayerStat } from "../../../../cards/playerCards/v2/PlayerCard";

import '@fontsource/norwester';
import { Card, Stack, Typography } from "@mui/material";
import { localise } from "../../../../../locale/locale";
import { IconId } from "../../../../base/icon/Icon";
import { MediumCardDimensions } from "../../../../cards/playerCards/v2/CardDimensions";
import { CommentaryComponent } from "../commentary/RoundCommentary";
import { D20 } from "../dice/D20";

const impactFonts = ['Norwester', 'sans-serif'].join(',');

export interface ContestProps {
    onClick(): void,
    playerTeamId: string,
    roundState: RoundState,

    teams: {
        [key: string]: TeamState;
    },
}

export const Contest = (props: ContestProps) => {
    useEffect(() => {
        if (!props.roundState.contests) {
            props.onClick()
        }
    }, [props.roundState.contests, props.onClick, props])
    if (!props.roundState.contests) {
        return null
    }
    return <ShowContests {...props} />
}

interface ContestInfo {
    topPlayer: CalculatedPlayer,
    topPlayerScore: PlayerRoundScore,
    bottomPlayer: CalculatedPlayer,
    bottomPlayerScore: PlayerRoundScore,
}



const ShowContests = (props: ContestProps) => {

    const playerIsAttacking = props.roundState.attackingTeam === props.playerTeamId
    const [contestIndex, setContestIndex] = useState(0)

    const contestInfo:ContestInfo = useMemo(() => {
        const contest = props.roundState.contests[contestIndex]
        const attackingPlayer = props.roundState.attackingHand[contest.attackingPlayerIndex].playerDetails
        const defendingPlayer = props.roundState.defendingHand[contest.defendingPlayerIndex].playerDetails

        return {
            topPlayer: playerIsAttacking ? attackingPlayer : defendingPlayer,
            bottomPlayer: playerIsAttacking ? defendingPlayer : attackingPlayer,
            topPlayerScore: playerIsAttacking ? contest.attackingScore : contest.defendingScore,
            bottomPlayerScore: playerIsAttacking ? contest.defendingScore : contest.attackingScore,
        }
    }, [contestIndex, playerIsAttacking, props.roundState.contests, props.roundState.attackingHand, props.roundState.defendingHand])


    const [skipRoll, setSkipRoll] = useState(false)
    const [showTopRoll, setShowTopRoll] = useState(true)
    const [showBottomRoll, setShowBottomRoll] = useState(true)
    const [complete, setComplete] = useState(false)


    const onClick = () => {
        if (!skipRoll) {
            completeRollCallback()
        }
        else if (contestIndex === props.roundState.contests.length - 1) {
            props.onClick()
        }
        else {
            setSkipRoll(false)
            setContestIndex(old => old + 1)
        }

    }

    const completeRollCallback = () => {
        setSkipRoll(true)

        //The following is to only show the attackers roll once, to make clearer that the roll holds over against multiple opponents in the same round
        if (playerIsAttacking){
            setShowTopRoll(false)
        }
        else{
            setShowBottomRoll(false)
        }

        if (contestIndex === props.roundState.contests.length - 1) {
            setComplete(true)
        }
    }

    return <Overlay onclick={onClick}>
        <Stack direction='column' sx={{
            justifyContent: 'space-evenly',
            height: '90vh',
        }}>
            <PlayerContest
                key={`contest-${props.roundState.roundId}-${contestIndex}`}
                skipRoll={skipRoll}
                showTopRoll={showTopRoll}
                showBottomRoll={showBottomRoll}
                completeRollCallback={completeRollCallback}
                {...contestInfo}
                roundState={props.roundState}
                teams={props.teams}
                complete={complete}
            />
        </Stack>
    </Overlay>
}

interface PlayerContestProps extends ContestInfo {
    roundState: RoundState,
    teams,
    skipRoll: boolean,
    completeRollCallback(): void,
    complete: boolean
    showTopRoll:boolean
    showBottomRoll:boolean
}

export const PlayerContest = (props: PlayerContestProps) => {
    const { topPlayer, topPlayerScore, bottomPlayer, bottomPlayerScore, completeRollCallback } = props

    const [topRollCompleted, setTopRollCompleted] = useState(false)
    const [bottomRollCompleted, setBottomRollCompleted] = useState(false)

    useEffect(() => {
        if (topRollCompleted && bottomRollCompleted) {
            completeRollCallback()
        }
    }, [topRollCompleted, bottomRollCompleted, completeRollCallback])

    return <Stack direction='column'
        sx={{
            justifyContent: 'flex-start',
            height: '90vh',
            alignItems: 'center',
        }}>
        <RoundScores completeRollCallback={() => setTopRollCompleted(true)}
            skipRoll={props.skipRoll || !props.showTopRoll}
            key={topPlayer.id}
            player={topPlayer}
            playerScore={topPlayerScore}
            stackDirection='row'
            rollTime={ROLL_TIME} />
        <Stack sx={{
            height: '10vh',
            width: "100vw",
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <svg width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
                <line x1="0" y1="100%" x2="100%" y2="0" stroke="black" strokeWidth="2vh" />
            </svg>
        </Stack>
        {(topRollCompleted || props.skipRoll) && <RoundScores completeRollCallback={() => setBottomRollCompleted(true)}
            skipRoll={props.skipRoll || !props.showBottomRoll}
            key={bottomPlayer.id}
            player={bottomPlayer}
            playerScore={bottomPlayerScore}
            stackDirection='row-reverse'
            rollTime={ROLL_TIME} />
        }
        {props.complete && <CommentaryComponent {...props} showResult={props.skipRoll} fontSize={'2.4vh'} />}

    </Stack>
}

interface RoundScoresProps {
    player: CalculatedPlayer,
    playerScore: PlayerRoundScore,
    stackDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse',
    skipRoll: boolean,
    completeRollCallback(): void,
    rollTime: number,
}

const ROLL_TIME = 2000
const SCORE_FADE_TIME = 2000
export const RoundScores = (props: RoundScoresProps) => {

    const { player, playerScore, stackDirection } = props


    const [showModifiers, setShowModifiers] = useState(false)

    const [fadeIncrement, setFadeIncrement] = useState(0)
    const [fadedChildren, setFadedChildren] = useState(undefined as any)
    const fadeChildren = useMemo(() => {
        const modifiers = playerScore
            .modifiers
            .filter(modifier => modifier.bonus > 0)
            .map(
                modifier => <ModifierDisplay key={player.id + modifier.key} modifier={modifier} colours={player.colours} />
            )
        return [
            ...modifiers,
            <ResultingScore score={playerScore?.score!} colours={player.colours}
            />]
    }, [playerScore, player.colours, player.id])

    useEffect(() => {
        if (props.skipRoll) {
            setFadedChildren(fadeChildren)
        }
        else {
            setFadedChildren(fadeChildren.map((child, index) => <div style={{ opacity: Math.max(0, fadeIncrement - index * 10) * 0.1 }}>{child}</div>))
        }
    }, [props.skipRoll, fadeChildren, fadeIncrement])

    useEffect(() => {
        if (showModifiers) {
            const interval = setInterval(() => {
                setFadeIncrement(old => old + 1)
            }, SCORE_FADE_TIME / (fadeChildren.length * 10));
            setTimeout(() => {
                clearInterval(interval);
                props.completeRollCallback()
            }, SCORE_FADE_TIME);
        }
    }, [showModifiers, fadeChildren, props.completeRollCallback, props])

    return <Stack direction={stackDirection}>
        <PlayerCardComponent size='medium' player={player} />
        <Card sx={{
            width: '50%',
            backgroundColor: player.colours.primaryBackgroundColour,
            color: player.colours.primaryTextColour,
        }}>
            <Stack direction='column' sx={{
                justifyContent: 'space-between',
                padding: '2vh'
            }}>
                <Stack direction='row' sx={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginBottom: '1vh'
                }}><D20 rollTime={props.rollTime} roll={!props.skipRoll} result={playerScore?.roll!} colours={player.colours} rollCompleteCallback={() => setShowModifiers(true)} />
                </Stack>
                {fadedChildren}
            </Stack>
        </Card>
    </Stack>
}

interface ResultingScoreProps {
    score: number, colours: Colours
}


export const ResultingScore = (props: ResultingScoreProps) => {
    return (
        <Typography
            variant="h4"
            component="div"
            sx={{
                fontFamily: impactFonts,
                width: '100%',
                borderTop: `1vh solid ${props.colours.primaryTextColour}`, // Combine width, style, and color
                borderBottom: `1vh solid ${props.colours.primaryTextColour}`, // Combine width, style, and color
                alignSelf: 'flex-end'
            }}
        >
            {localise('challenge.playerscore.total')} {props.score}
        </Typography>
    );
};

interface ModifierDisplayProps {
    colours: Colours,
    modifier: Modifier
}

export const ModifierDisplay = (props: ModifierDisplayProps) => {
    if (props.modifier.key === 'preferredOpponent') {
        return <Stack direction='row' sx={{
            justifyContent: 'flex-end',
            alignItems: 'center'
        }}>
            <PreferredOpponent {...props} />
        </Stack>
    }
    const modifier = <AttributeModifier {...props} />
    return <Stack direction='row' sx={{
        justifyContent: 'flex-end',
        alignItems: 'center'
    }}>{modifier}</Stack>
}

const PreferredOpponent = (props: ModifierDisplayProps) => {
    const nationId = props.modifier.params!.nationId[0]
    return <>{localise(`modifier.${props.modifier.key}`)}
        <img
            src={`/images/flags/192x144/${props.modifier.params!.nationId[0]}.png`}
            alt={`${nationId} flag`}
            style={{ height: '2vh', marginRight: '1vmin', marginLeft: '1vmin' }}
        />
        <PlayerStat value={props.modifier.bonus} iconId={props.modifier.key.toUpperCase() as IconId} colours={props.colours} dimensions={MediumCardDimensions} />
    </>
}


const AttributeModifier = (props: ModifierDisplayProps) => {
    return <>{localise(`modifier.${props.modifier.key}`)}
        <PlayerStat value={props.modifier.bonus} iconId={props.modifier.key.toUpperCase() as IconId} colours={props.colours} dimensions={MediumCardDimensions} />
    </>
}