export const enPlayerRoles: { [key: string]: string } = {

  'baserole.shortHand.GOAL_KEEPER': 'GK',
  'baserole.shortHand.CENTER_BACK': 'CB',
  'baserole.shortHand.FULL_BACK': 'FB',
  'baserole.shortHand.DEFENSIVE_MIDFIELDER': 'DM',
  'baserole.shortHand.CENTRAL_MIDFIELDER': 'CM',
  'baserole.shortHand.ATTACKING_MIDFIELDER': 'AM',
  'baserole.shortHand.WIDE_MIDFIELDER': 'WM',
  'baserole.shortHand.WINGER': 'W',
  'baserole.shortHand.FORWARD': 'FW',
  'baserole.shortHand.STRIKER': 'ST',
  'playerroles.bonuses': 'Bonuses',
  'playerroles.traits': 'Traits',

  'playerroles.GOAL_KEEPER': 'Goalkeeper',
  'playerroles.SWEEPER_KEEPER': 'Sweeper Keeper',
  'playerroles.DISTRIBUTOR': 'Distributor',
  'playerroles.SHOT_BLOCKER': 'Shot Blocker',
  'playerroles.BALL_PLAYING_GOALIE': 'Ball Playing Goalie',
  'playerroles.COMPLETE_GOALKEEPER': 'Complete Goalkeeper',
  'playerroles.ECCENTRIC_GOALKEEPER': 'Eccentric Goalkeeper',

  'playerroles.FULL_BACK': 'Full Back',
  'playerroles.RAPID_FULL_BACK': 'Rapid Full Back',
  'playerroles.POWERFUL_FULL_BACK': 'Powerful Full Back',
  'playerroles.WING_BACK': 'Wing Back',
  'playerroles.RAPID_WING_BACK': 'Rapid Wing Back',
  'playerroles.TENACIOUS_FULL_BACK': 'Tenacious Full Back',
  'playerroles.AGGRESSIVE_FULLBACK': 'Aggressive Fullback',
  'playerroles.ROBUST_FULLBACK': 'Robust Fullback',

  'playerroles.CENTER_BACK': 'Center Back',
  'playerroles.RAPID_CENTER_BACK': 'Rapid Center Back',
  'playerroles.POWERFUL_CENTER_BACK': 'Powerful Center Back',
  'playerroles.ATHLETIC_CENTER_BACK': 'Athletic Center Back',
  'playerroles.PLAYMAKING_CENTER_BACK': 'Playmaking Center Back',
  'playerroles.ALL_ROUND_CENTER_BACK': 'All Round Center Back',
  'playerroles.TENACIOUS_CENTER_BACK': 'Tenacious Center Back',
  'playerroles.AGGRESSIVE_CENTERBACK': 'Aggressive Centerback',
  'playerroles.ROBUST_CENTERBACK': 'Robust Centerback',
  'playerroles.HEROIC_CENTERBACK': 'Heroic Centerback',

  'playerroles.DEFENSIVE_MIDFIELDER': 'Defensive Midfielder',
  'playerroles.DEEP_LYING_PLAYMAKER': 'Deep Lying Playmaker',
  'playerroles.PRESS_RESISTANT': 'Press Resistant',
  'playerroles.SHIELD': 'Shield',
  'playerroles.RAPID_DEFENSIVE_MIDFIELDER': 'Rapid Defensive Midfielder',
  'playerroles.POWERFUL_DEFENSIVE_MIDFIELDER': 'Powerful Defensive Midfielder',
  'playerroles.ATHLETIC_DEFENSIVE_MIDFIELDER': 'Athletic Defensive Midfielder',
  'playerroles.TENACIOUS_DEFENSIVE_MIDFIELDER': 'Tenacious Defensive Midfielder',
  'playerroles.ROBUST_DEFENSIVE_MIDFIELDER': 'Robust Defensive Midfielder',
  'playerroles.AGGRESSIVE_DEFENSIVE_MIDFIELDER': 'Aggressive Defensive Midfielder',
  'playerroles.DETERMINED_DEFENSIVE_MIDFIELDER': 'Determined Defensive Midfielder',
  'playerroles.TOUGH_DEFENSIVE_MIDFIELDER': 'Tough Defensive Midfielder',

  'playerroles.WIDE_MIDFIELDER': 'Wide Midfielder',
  'playerroles.RAPID_WIDE_MIDFIELDER': 'Rapid Wide Midfielder',
  'playerroles.POWERFUL_WIDE_MIDFIELDER': 'Powerful Wide Midfielder',
  'playerroles.ATHLETIC_WIDE_MIDFIELDER': 'Athletic Wide Midfielder',
  'playerroles.TENACIOUS_WIDE_MIDFIELDER': 'Tenacious Wide Midfielder',
  'playerroles.AGGRESSIVE__WIDE_MIDFIELDER': 'Aggressive Wide Midfielder',
  'playerroles.ROBUST_WIDE_MIDFIELDER': 'Robust Wide Midfielder',
  'playerroles.HEROIC_WIDE_MIDFIELDER': 'Heroic Wide Midfielder',

  'playerroles.CENTRAL_MIDFIELDER': 'Central Midfielder',
  'playerroles.BOX_TO_BOX_MIDFIELDER': 'Box to Box Midfielder',
  'playerroles.COMPLETE_MIDFIELDER': 'Complete Midfielder',
  'playerroles.RAPID_CENTRAL_MIDFIELDER': 'Rapid Central Midfielder',
  'playerroles.POWERFUL_CENTRAL_MIDFIELDER': 'Powerful Central Midfielder',
  'playerroles.ATHLETIC_CENTRAL_MIDFIELDER': 'Athletic Central Midfielder',
  'playerroles.TIKI_TAKA_MIDFIELDER': 'Tiki Taka Midfielder',
  'playerroles.PLAYMAKER': 'Playmaker',
  'playerroles.TENACIOUS_MIDFIELDER': 'Tenacious Midfielder',
  'playerroles.ROBUST_MIDFIELDER': 'Robust Midfielder',
  'playerroles.TRICKY_MIDFIELDER': 'Tricky Midfielder',
  'playerroles.DETERMINED_MIDFIELDER': 'Determined Midfielder',
  'playerroles.HEROIC_MIDFIEDER': 'Heroic Midfielder',
  'playerroles.MIDFIELD_TALISMAN': 'Midfield Talisman',


  'playerroles.ATTACKING_MIDFIELDER': 'Attacking Midfielder',
  'playerroles.RAPID_ATTACKING_MIDFIELDER': 'Rapid Attacking Midfielder',
  'playerroles.POWERFUL_ATTACKING_MIDFIELDER': 'Powerful Attacking Midfielder',
  'playerroles.ATHLETIC_ATTACKING_MIDFIELDER': 'Athletic Attacking Midfielder',
  'playerroles.ATTACKING_PLAYMAKER': 'Attacking Playmaker',
  'playerroles.TRICKY_ATTACKING_MIDFIELDER': 'Tricky Attacking Midfielder',
  'playerroles.TRICKY_PLAYMAKER': 'Tricky Playmaker',
  'playerroles.DETERMINED_ATTACKER': 'Determined Attacker',
  'playerroles.HEROIC_ATTACKER': 'Heroic Attacker',
  'playerroles.ATTACKING_TALISMAN': 'Attacking Talisman',
  'playerroles.CATALYST': 'Catalyst',
  'playerroles.TIKI_TAKA_FORWARD': 'Tiki Taka Forward',

  'playerroles.STRIKER': 'Striker',
  'playerroles.RAPID_STRIKER': 'Rapid Striker',
  'playerroles.POWERFUL_STRIKER': 'Powerful Striker',
  'playerroles.POWERFUL_FINISHER': 'Powerful Finisher',
  'playerroles.ATHLETIC_STRIKER': 'Athletic Striker',
  'playerroles.CLINICAL_STRIKER': 'Clinical Striker',
  'playerroles.FINISHER': 'Finisher',
  'playerroles.PREDATOR': 'Predator',
  'playerroles.TRICKY_STRIKER': 'Tricky Striker',
  'playerroles.RAPID_TRICKY_STRIKER': 'Rapid Tricky Striker',
  'playerroles.POWERFUL_TRICKY_STRIKER': 'Powerful Tricky Striker',
  'playerroles.COMPLETE_FORWARD': 'Complete Forward',
  'playerroles.TENACIOUS_STRIKER': 'Tenacious Striker',
  'playerroles.DOMINANT_STRIKER': 'Dominant Striker',
  'playerroles.DETERMINED_STRIKER': 'Determined Striker',
  'playerroles.HEROIC_STRIKER': 'Heroic Striker',

  'playerroles.WINGER': 'Winger',
  'playerroles.RAPID_WINGER': 'Rapid Winger',
  'playerroles.POWERFUL_WINGER': 'Powerful Winger',
  'playerroles.ATHLETIC_WINGER': 'Athletic Winger',
  'playerroles.TRICKY_WINGER': 'Tricky Winger',
  'playerroles.INSIDE_FORWARD': 'Inside Forward',
  'playerroles.RAPID_INSIDE_FORWARD': 'Rapid Inside Forward',
  'playerroles.POWERFUL_INSIDE_FORWARD': 'Powerful Inside Forward',
  'playerroles.TRICKY_INSIDE_FORWARD': 'Tricky Inside Forward',
  'playerroles.RAPID_TRICKY_WINGER': 'Rapid Tricky Winger',
  'playerroles.POWERFUL_TRICKY_WINGER': 'Powerful Tricky Winger',
  'playerroles.HEROIC_WINGER': 'Heroic Winger',
  'playerroles.TALISMANIC_WINGER': 'Talismanic Winger',
  'playerroles.FLYING_WINGER': 'Flying Winger',
};