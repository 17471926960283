"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionToAttributes = exports.ActionType = exports.Actions = void 0;
var Actions;
(function (Actions) {
    Actions["RUN"] = "RUN";
    Actions["SHOOT"] = "SHOOT";
    Actions["PASS"] = "PASS";
    Actions["STALL"] = "STALL";
})(Actions || (exports.Actions = Actions = {}));
var ActionType;
(function (ActionType) {
    ActionType[ActionType["ATTACK"] = 0] = "ATTACK";
    ActionType[ActionType["DEFENSE"] = 1] = "DEFENSE";
})(ActionType || (exports.ActionType = ActionType = {}));
exports.ActionToAttributes = {
    [Actions.RUN]: { [ActionType.ATTACK]: 'run', [ActionType.DEFENSE]: 'tackle' },
    [Actions.SHOOT]: { [ActionType.ATTACK]: 'shoot', [ActionType.DEFENSE]: 'block' },
    [Actions.PASS]: { [ActionType.ATTACK]: 'pass', [ActionType.DEFENSE]: 'intercept' }
};
