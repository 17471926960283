"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WideMidfielderRoleBonuses = exports.WideMidfielderExpandedRoles = void 0;
// wideMidfielderRoles.ts
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var WideMidfielderExpandedRoles;
(function (WideMidfielderExpandedRoles) {
    WideMidfielderExpandedRoles["RAPID_WIDE_MIDFIELDER"] = "RAPID_WIDE_MIDFIELDER";
    WideMidfielderExpandedRoles["POWERFUL_WIDE_MIDFIELDER"] = "POWERFUL_WIDE_MIDFIELDER";
    WideMidfielderExpandedRoles["ATHLETIC_WIDE_MIDFIELDER"] = "ATHLETIC_WIDE_MIDFIELDER";
    WideMidfielderExpandedRoles["TENACIOUS_WIDE_MIDFIELDER"] = "TENACIOUS_WIDE_MIDFIELDER";
    WideMidfielderExpandedRoles["AGGRESSIVE__WIDE_MIDFIELDER"] = "AGGRESSIVE__WIDE_MIDFIELDER";
    WideMidfielderExpandedRoles["ROBUST_WIDE_MIDFIELDER"] = "ROBUST_WIDE_MIDFIELDER";
    WideMidfielderExpandedRoles["HEROIC_WIDE_MIDFIELDER"] = "HEROIC_WIDE_MIDFIELDER";
})(WideMidfielderExpandedRoles || (exports.WideMidfielderExpandedRoles = WideMidfielderExpandedRoles = {}));
exports.WideMidfielderRoleBonuses = {
    WIDE_MIDFIELDER: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            run: 3,
            pass: 2,
            tackle: 1,
        },
        positionsEnabled: [zones_1.Zones.DEFENSE, zones_1.Zones.MIDFIELD, zones_1.Zones.ATTACK],
        playerTraits: [],
    },
    RAPID_WIDE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_WIDE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL]
    },
    ATHLETIC_WIDE_MIDFIELDER: {
        parentRoles: [WideMidfielderExpandedRoles.RAPID_WIDE_MIDFIELDER, WideMidfielderExpandedRoles.POWERFUL_WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: []
    },
    TENACIOUS_WIDE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.UNDERDOG]
    },
    AGGRESSIVE__WIDE_MIDFIELDER: {
        parentRoles: [WideMidfielderExpandedRoles.TENACIOUS_WIDE_MIDFIELDER, WideMidfielderExpandedRoles.RAPID_WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: []
    },
    ROBUST_WIDE_MIDFIELDER: {
        parentRoles: [WideMidfielderExpandedRoles.TENACIOUS_WIDE_MIDFIELDER, WideMidfielderExpandedRoles.POWERFUL_WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: []
    },
    HEROIC_WIDE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.WIDE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.HERO]
    }
};
