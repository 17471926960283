import { MatchState } from "@alienheadwars/football-card-manager-model"
import React, { useContext, useState } from "react" // Removed useEffect, useCallback, useRef
import { useHistory, useParams } from "react-router-dom"
import { MatchStateComponent } from "./MatchStateViewer"
import { TeamContext } from "../../TeamContext"
import {useFirestoreDocumentListener} from '../../../../firebase/useFirestoreDocumentListener'

export interface MatchUrlParams {
    teamId: string,
    matchId: string,
}

//TODO very dirty, need to refactor
interface WorldTourMatchState extends MatchState {
    matchMeta: {
        region: string,
        league: string,
        opponentId: string
    },
    callbacks: string[]
}

export const MatchHome = () => {
    const regionUrlParams = useParams<MatchUrlParams>()
    const [matchState, setMatchState] = useState(undefined as unknown as WorldTourMatchState)
    const teamContext = useContext(TeamContext)
    const history = useHistory()

    console.log('useFirestoreDocumentListener')
    useFirestoreDocumentListener<WorldTourMatchState>(`matches/${regionUrlParams.matchId}`, setMatchState); 

    if (!matchState) {
        return <></>
    }

    const onComplete = () => {
        history.push(`/teams/${regionUrlParams.teamId}/play/world-tour/${matchState.matchMeta.league}`)
    }
    return <MatchStateComponent playerTeamId={teamContext.getTeam().id} matchState={matchState} onComplete={onComplete} />
}