import { PackInstance } from "@alienheadwars/football-card-manager-model";
// Import Divider
import { Box, Card, Divider, Typography } from "@mui/material";
import React from "react";
import { ExpandablePlayerCard } from "../../../cards/playerCards/v2/PlayerCard";
import { localise } from "../../../../locale/locale";
import { SimsStyleLoading } from "../../../base/loading/SimsStyleLoading";

export interface PackSummaryProps {
    pack: PackInstance;
}

export const PackSummary = (props: PackSummaryProps) => {
    const { pack } = props;

    const flexContainerStyles = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 2,
        paddingY: 2,
        // Changed to left-align items within the container
        justifyContent: 'flex-start',
    };

    // Ensure assignedItems exists before filtering
    const assignedItems = pack.assignedItems || [];
    const newItems = assignedItems.filter(item => !item.discarded);
    const duplicatedItems = assignedItems.filter(item => item.discarded);

    // Determine if the divider should be shown
    const showDivider = newItems.length > 0 && duplicatedItems.length > 0;

    // Should be openend before we get here, but in case method is slow/user taps quick show loading
    if(!pack.opened){
        return <SimsStyleLoading/>
    }

    return (
        <Card sx={{ padding: 2 }}>
            {/* --- New Items Section --- */}
            {newItems.length > 0 && (
                // Use Fragment <> to group elements for conditional rendering
                <>
                    <Typography variant="h6" component="div" gutterBottom>
                        {localise('packSummary.newItems')}
                    </Typography>
                    <Box sx={flexContainerStyles}>
                        {newItems.map(item => <ExpandablePlayerCard key={item.id} player={item} />)}
                    </Box>
                </>
            )}

            {/* --- Divider --- */}
            {/* Only show divider if both sections have items */}
            {showDivider && (
                <Divider sx={{ marginY: 2 }} /> // Add vertical margin to the divider
            )}

            {/* --- Duplicated Items Section --- */}
            {duplicatedItems.length > 0 && (
                <>
                    {/* Removed marginTop from Typography here, Divider handles spacing */}
                    <Typography variant="h6" component="div" gutterBottom >
                        {localise('packSummary.duplicatedItems')}
                    </Typography>
                    <Box sx={flexContainerStyles}>
                        {duplicatedItems.map(item => <ExpandablePlayerCard key={item.id} player={item} />)}
                    </Box>
                </>
             )}
        </Card>
    );
};