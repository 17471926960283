import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyDBv7RQPvOV9XtZ4iZdxnahb3jjt83o2-Y",
    authDomain: "football-card-manager.firebaseapp.com",
    projectId: "football-card-manager",
    storageBucket: "football-card-manager.appspot.com",
    messagingSenderId: "944323898008",
    appId: "1:944323898008:web:5c9668ba5db411b87dfeed",
    measurementId: "G-6Q9C4QTNKV"
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

export const auth = getAuth();
export const providers = auth.providers


export const functions = getFunctions(firebaseApp, 'europe-west2');

const functionPrefix = process.env.REACT_APP_ENVIRONMENT === 'branch_deploy' ? 'TEST-' : ''
console.log(`process.env.REACT_APP_ENVIRONMENT = ${process.env.REACT_APP_ENVIRONMENT} function prefix: ${functionPrefix}`)

// Check if emulators are running
const usingEmulators = process.env.REACT_APP_USE_EMULATORS && process.env.REACT_APP_USE_EMULATORS.toLowerCase() !== 'false'

if (usingEmulators) {
    console.log('PWA: Connecting to Firebase emulators');
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectFirestoreEmulator(db, 'localhost', 8083);
    console.log('PWA: Connected to Firebase emulators');
} else {
    console.log('PWA: Using remote Firebase project for Firestore and Functions');
}

export const getCallableFunction = (functionName) =>{
    return httpsCallable(functions, functionPrefix + functionName)
}


export const toJson = (object) => {
    return JSON.parse(JSON.stringify(object))
}