export const enWorldTourPlayerNames: { [key: string]: string } = {
    'player.great-britain/english-league/diamond-dogs/colin-hughes':"Colin Hughes",
'player.great-britain/english-league/diamond-dogs/daniela-rojas':"Daniela Rojas",
'player.great-britain/english-league/diamond-dogs/gabi-luna':"Gabi Luna",
'player.great-britain/english-league/diamond-dogs/isaac-mcadoo':"Isaac McAdoo",
'player.great-britain/english-league/diamond-dogs/james-taylor':"James Taylor",
'player.great-britain/english-league/diamond-dogs/jan-maas':"Jan Maas",
'player.great-britain/english-league/diamond-dogs/leslie-higgins':"Leslie Higgins",
'player.great-britain/english-league/diamond-dogs/renee-kent':"Renee Kent",
'player.great-britain/english-league/diamond-dogs/richard-zorehkey':"Richard Zorehkey",
'player.great-britain/english-league/diamond-dogs/samantha-obisanya':"Samantha Obisanya",
'player.great-britain/english-league/diamond-dogs/thierry-zorehkey':"Thierry Zorehkey",
'player.great-britain/english-league/gull-pride/alex-rivera':"Alex Rivera",
'player.great-britain/english-league/gull-pride/arielle-dupont':"Arielle Dupont",
'player.great-britain/english-league/gull-pride/casey-smith':"Casey Smith",
'player.great-britain/english-league/gull-pride/jamie-lee':"Jamie Lee",
'player.great-britain/english-league/gull-pride/jordan-kim':"Jordan Kim",
'player.great-britain/english-league/gull-pride/luca-martinez':"Luca Martinez",
'player.great-britain/english-league/gull-pride/morgan-patil':"Morgan Patil",
'player.great-britain/english-league/gull-pride/niko-garcía':"Niko García",
'player.great-britain/english-league/gull-pride/sam-taylor':"Sam Taylor",
'player.great-britain/english-league/gull-pride/sasha-ivanov':"Sasha Ivanov",
'player.great-britain/english-league/gull-pride/taylor-“tj”-james':"Taylor “TJ” James",
'player.great-britain/english-league/hellhounds/andrea-earps':"Andrea Earps",
'player.great-britain/english-league/hellhounds/ella-fernandes':"Ella Fernandes",
'player.great-britain/english-league/hellhounds/hannah-dalot':"Hannah Dalot",
'player.great-britain/english-league/hellhounds/hayley-maguire':"Hayley Maguire",
'player.great-britain/english-league/hellhounds/katie-mainoo':"Katie Mainoo",
'player.great-britain/english-league/hellhounds/lucia-garnacho':"Lucia Garnacho",
'player.great-britain/english-league/hellhounds/luke-le-tissier':"Luke Le Tissier",
'player.great-britain/english-league/hellhounds/marcus-russo':"Marcus Russo",
'player.great-britain/english-league/hellhounds/mcterminator':"McTerminator",
'player.great-britain/english-league/hellhounds/millie-martínez':"Millie Martínez",
'player.great-britain/english-league/hellhounds/rasmus-parris':"Rasmus Parris",
'player.great-britain/english-league/invincibles/fredrik-ljindersson':"Fredrik Ljindersson",
'player.great-britain/english-league/invincibles/gilberto-silvana':"Gilberto Silvana",
'player.great-britain/english-league/invincibles/granite':"Granite",
'player.great-britain/english-league/invincibles/ice':"Ice",
'player.great-britain/english-league/invincibles/jasmin-lens':"Jasmin Lens",
'player.great-britain/english-league/invincibles/kaylie-tourette':"Kaylie Tourette",
'player.great-britain/english-league/invincibles/lauren-cooper':"Lauren Cooper",
'player.great-britain/english-league/invincibles/obsidian':"Obsidian",
'player.great-britain/english-league/invincibles/roberta-perrin':"Roberta Perrin",
'player.great-britain/english-league/invincibles/steel':"Steel",
'player.great-britain/english-league/invincibles/titanium':"Titanium",
'player.great-britain/english-league/knights-forge/carla-cole':"Carla Cole",
'player.great-britain/english-league/knights-forge/julianne-dicks':"Julianne Dicks",
'player.great-britain/english-league/knights-forge/lennox-devonshire':"Lennox Devonshire",
'player.great-britain/english-league/knights-forge/martina-allen':"Martina Allen",
'player.great-britain/english-league/knights-forge/paula-di-canio':"Paula Di Canio",
'player.great-britain/english-league/knights-forge/pia-martino':"Pia Martino",
'player.great-britain/english-league/knights-forge/robbie-macallister':"Robbie MacAllister",
'player.great-britain/english-league/knights-forge/sir-bobby':"Sir Bobby",
'player.great-britain/english-league/knights-forge/sir-geoff':"Sir Geoff",
'player.great-britain/english-league/knights-forge/sir-trevor':"Sir Trevor",
'player.great-britain/english-league/knights-forge/victoria-bonds':"Victoria Bonds",
'player.great-britain/english-league/london-blues/christina-nkunku':"Christina Nkunku",
'player.great-britain/english-league/london-blues/esmine-flores':"Esmine Flores",
'player.great-britain/english-league/london-blues/greg-connors':"Greg Connors",
'player.great-britain/english-league/london-blues/martin-cucurella':"Martin Cucurella",
'player.great-britain/english-league/london-blues/mykhaila-martyn':"Mykhaila Martyn",
'player.great-britain/english-league/london-blues/nicole-jones':"Nicole Jones",
'player.great-britain/english-league/london-blues/rae-smith':"Rae Smith",
'player.great-britain/english-league/london-blues/rebecca-james':"Rebecca James",
'player.great-britain/english-league/london-blues/roberta-sánchez':"Roberta Sánchez",
'player.great-britain/english-league/london-blues/thierry-santos':"Thierry Santos",
'player.great-britain/english-league/london-blues/wesley-franco':"Wesley Franco",
'player.great-britain/english-league/mersey-mullets/alan-murdoch':"Alan Murdoch",
'player.great-britain/english-league/mersey-mullets/craig-mcallister':"Craig McAllister",
'player.great-britain/english-league/mersey-mullets/john-walker':"John Walker",
'player.great-britain/english-league/mersey-mullets/kenneth-arthur':"Kenneth Arthur",
'player.great-britain/english-league/mersey-mullets/mad-mullet':"Mad Mullet",
'player.great-britain/english-league/mersey-mullets/moustached-marvel':"Moustached Marvel",
'player.great-britain/english-league/mersey-mullets/phil-kingston':"Phil Kingston",
'player.great-britain/english-league/mersey-mullets/red-king':"Red King",
'player.great-britain/english-league/mersey-mullets/simon-dalby':"Simon Dalby",
'player.great-britain/english-league/mersey-mullets/spaghetti-grobbs':"Spaghetti Grobbs",
'player.great-britain/english-league/mersey-mullets/steve-mccann':"Steve McCann",
'player.great-britain/english-league/mersey-phoenix/alice-becker':"Alice Becker",
'player.great-britain/english-league/mersey-phoenix/andrea-robertson':"Andrea Robertson",
'player.great-britain/english-league/mersey-phoenix/fabiana':"Fabiana",
'player.great-britain/english-league/mersey-phoenix/georgia-henderson':"Georgia Henderson",
'player.great-britain/english-league/mersey-phoenix/georgina-wijnaldum':"Georgina Wijnaldum",
'player.great-britain/english-league/mersey-phoenix/josephine-gomez':"Josephine Gomez",
'player.great-britain/english-league/mersey-phoenix/maureen-salah':"Maureen Salah",
'player.great-britain/english-league/mersey-phoenix/roberta-firmino':"Roberta Firmino",
'player.great-britain/english-league/mersey-phoenix/sasha-mane':"Sasha Mane",
'player.great-britain/english-league/mersey-phoenix/trisha-alexander-arnold':"Trisha Alexander-Arnold",
'player.great-britain/english-league/mersey-phoenix/virgilia-van-dijk':"Virgilia van Dijk",
'player.great-britain/english-league/night-watch/arya-delgado':"Arya Delgado",
'player.great-britain/english-league/night-watch/benjen-bannan':"Benjen Bannan",
'player.great-britain/english-league/night-watch/brandon-valentin':"Brandon Valentin",
'player.great-britain/english-league/night-watch/eddison-windass':"Eddison Windass",
'player.great-britain/english-league/night-watch/jon-waddle':"Jon Waddle",
'player.great-britain/english-league/night-watch/lyanna-pressman':"Lyanna Pressman",
'player.great-britain/english-league/night-watch/mance-ugbo':"Mance Ugbo",
'player.great-britain/english-league/night-watch/samwell-beadle':"Samwell Beadle",
'player.great-britain/english-league/night-watch/sansa-bannan':"Sansa Bannan",
'player.great-britain/english-league/night-watch/tormund-nilsson':"Tormund Nilsson",
'player.great-britain/english-league/night-watch/ygritte-palmer':"Ygritte Palmer",
'player.great-britain/english-league/oil-fc/amandine-lefevre':"Amandine Lefevre",
'player.great-britain/english-league/oil-fc/carla-correia':"Carla Correia",
'player.great-britain/english-league/oil-fc/eduarda-souza':"Eduarda Souza",
'player.great-britain/english-league/oil-fc/fernanda-foster':"Fernanda Foster",
'player.great-britain/english-league/oil-fc/greta-gerwig':"Greta Gerwig",
'player.great-britain/english-league/oil-fc/helena-halvorsen':"Helena Halvorsen",
'player.great-britain/english-league/oil-fc/isabelle-dujardin':"Isabelle Dujardin",
'player.great-britain/english-league/oil-fc/julia-greenway':"Julia Greenway",
'player.great-britain/english-league/oil-fc/karla-walton':"Karla Walton",
'player.great-britain/english-league/oil-fc/rafaela-duarte':"Rafaela Duarte",
'player.great-britain/english-league/oil-fc/rita-ramos':"Rita Ramos",
'player.great-britain/english-league/sherwood-forest/alan-a-dale':"Alan-a-Dale",
'player.great-britain/english-league/sherwood-forest/brother-tuck':"Brother Tuck",
'player.great-britain/english-league/sherwood-forest/eleanor-dale':"Eleanor Dale",
'player.great-britain/english-league/sherwood-forest/friar-shilton':"Friar Shilton",
'player.great-britain/english-league/sherwood-forest/gilbert-whitehand':"Gilbert Whitehand",
'player.great-britain/english-league/sherwood-forest/lady-cluck':"Lady Cluck",
'player.great-britain/english-league/sherwood-forest/little-des':"Little Des",
'player.great-britain/english-league/sherwood-forest/maid-marian':"Maid Marian",
'player.great-britain/english-league/sherwood-forest/much-the-millers-son':"Much the Miller's Son",
'player.great-britain/english-league/sherwood-forest/robin-hood':"Robin Hood",
'player.great-britain/english-league/sherwood-forest/vivian-scarlet':"Vivian Scarlet",
'player.great-britain/english-league/treble-devils/adam-goals':"Adam Goals",
'player.great-britain/english-league/treble-devils/baby-faced-assassin':"Baby Faced Assassin",
'player.great-britain/english-league/treble-devils/david-hull':"David Hull",
'player.great-britain/english-league/treble-devils/dein-marcsen':"Dein Marcsen",
'player.great-britain/english-league/treble-devils/great-dane':"Great Dane",
'player.great-britain/english-league/treble-devils/dennis-keane':"Dennis Keane",
'player.great-britain/english-league/treble-devils/gary-phillips':"Gary Phillips",
'player.great-britain/english-league/treble-devils/ginger-ninja':"Ginger Ninja",
'player.great-britain/english-league/treble-devils/golden-boy':"Golden boy",
'player.great-britain/english-league/treble-devils/jens-stan':"Jens Stan",
'player.great-britain/english-league/treble-devils/john-ronsen':"John Ronsen",
'player.great-britain/english-league/treble-devils/red-devil':"Red Devil",
'player.great-britain/english-league/treble-devils/welsh-wizard':"Welsh Wizard",
'player.great-britain/english-league/villains/bertie-traoré':"Bertie Traoré",
'player.great-britain/english-league/villains/douglas-luísa':"Douglas Luísa",
'player.great-britain/english-league/villains/emily-martins':"Emily Martins",
'player.great-britain/english-league/villains/ezrianna-konsa':"Ezrianna Konsa",
'player.great-britain/english-league/villains/jacqueline-galloway':"Jacqueline Galloway",
'player.great-britain/english-league/villains/leon-bailey':"Leon Bailey",
'player.great-britain/english-league/villains/lucas-digney':"Lucas Digney",
'player.great-britain/english-league/villains/matthew-chasen':"Matthew Chasen",
'player.great-britain/english-league/villains/morgan-sanson':"Morgan Sanson",
'player.great-britain/english-league/villains/olivia-watkins':"Olivia Watkins",
'player.great-britain/english-league/villains/tyra-minga':"Tyra Minga",
'player.great-britain/english-league/vixens/christina-fuchs':"Christina Fuchs",
'player.great-britain/english-league/vixens/danielle-drinkwater':"Danielle Drinkwater",
'player.great-britain/english-league/vixens/danielle-simpson':"Danielle Simpson",
'player.great-britain/english-league/vixens/gemma-vardy':"Gemma Vardy",
'player.great-britain/english-league/vixens/kat-schmeichel':"Kat Schmeichel",
'player.great-britain/english-league/vixens/madeleine-albrighton':"Madeleine Albrighton",
'player.great-britain/english-league/vixens/natalia-kante':"Natalia Kante",
'player.great-britain/english-league/vixens/riya-mahrez':"Riya Mahrez",
'player.great-britain/english-league/vixens/roberta-huth':"Roberta Huth",
'player.great-britain/english-league/vixens/sasha-okazaki':"Sasha Okazaki",
'player.great-britain/english-league/vixens/wendy-morgan':"Wendy Morgan",
'player.great-britain/scottish-league/aberdeen-angus/alexia-mcdougall':"Alexia McDougall",
'player.great-britain/scottish-league/aberdeen-angus/charlotte-glass':"Charlotte Glass",
'player.great-britain/scottish-league/aberdeen-angus/daniela-simmers':"Daniela Simmers",
'player.great-britain/scottish-league/aberdeen-angus/erin-strachan':"Erin Strachan",
'player.great-britain/scottish-league/aberdeen-angus/euan-mcmaster':"Euan McMaster",
'player.great-britain/scottish-league/aberdeen-angus/frances-mcpherson':"Frances McPherson",
'player.great-britain/scottish-league/aberdeen-angus/joanne-smith':"Joanne Smith",
'player.great-britain/scottish-league/aberdeen-angus/marley-cooper':"Marley Cooper",
'player.great-britain/scottish-league/aberdeen-angus/roberta-mcleish':"Roberta McLeish",
'player.great-britain/scottish-league/aberdeen-angus/simone-paatelainen':"Simone Paatelainen",
'player.great-britain/scottish-league/aberdeen-angus/willa-miller':"Willa Miller",
'player.great-britain/scottish-league/blue-men-of-minch/angus-wavebreaker':"Angus Wavebreaker",
'player.great-britain/scottish-league/blue-men-of-minch/callum-whirlpool':"Callum Whirlpool",
'player.great-britain/scottish-league/blue-men-of-minch/dougal-mariner':"Dougal Mariner",
'player.great-britain/scottish-league/blue-men-of-minch/duncan-stormrider':"Duncan Stormrider",
'player.great-britain/scottish-league/blue-men-of-minch/ewan-mctide':"Ewan McTide",
'player.great-britain/scottish-league/blue-men-of-minch/fergus-tidesinger':"Fergus Tidesinger",
'player.great-britain/scottish-league/blue-men-of-minch/finn-maccool':"Finn MacCool",
'player.great-britain/scottish-league/blue-men-of-minch/hamish-oceanheart':"Hamish Oceanheart",
'player.great-britain/scottish-league/blue-men-of-minch/iain-vortex':"Iain Vortex",
'player.great-britain/scottish-league/blue-men-of-minch/lachlan-deepwave':"Lachlan Deepwave",
'player.great-britain/scottish-league/blue-men-of-minch/rory-seastorm':"Rory Seastorm",
'player.great-britain/scottish-league/celtic-warriors/bradley-livingston':"Bradley Livingston",
'player.great-britain/scottish-league/celtic-warriors/brendan-macnally':"Brendan MacNally",
'player.great-britain/scottish-league/celtic-warriors/declan-mccarthy':"Declan McCarthy",
'player.great-britain/scottish-league/celtic-warriors/erika-larsson':"Erika Larsson",
'player.great-britain/scottish-league/celtic-warriors/hanna-lundquist':"Hanna Lundquist",
'player.great-britain/scottish-league/celtic-warriors/jenny-johnstone':"Jenny Johnstone",
'player.great-britain/scottish-league/celtic-warriors/jesse-fraser':"Jesse Fraser",
'player.great-britain/scottish-league/celtic-warriors/sarah-keane':"Sarah Keane",
'player.great-britain/scottish-league/celtic-warriors/tristan-gallagher':"Tristan Gallagher",
'player.great-britain/scottish-league/celtic-warriors/virginia-voss':"Virginia Voss",
'player.great-britain/scottish-league/celtic-warriors/warrior-king':"Warrior King",
'player.great-britain/scottish-league/celtic-warriors/niall-lennox':"Niall Lennox",

'player.great-britain/scottish-league/niall-lennox':"Niall Lennox",
'player.great-britain/scottish-league/dundee-dragons/aurora-flamehart':"Aurora Flamehart",
'player.great-britain/scottish-league/dundee-dragons/cassia-flamehart':"Cassia Flamehart",
'player.great-britain/scottish-league/dundee-dragons/drakon':"Drakon",
'player.great-britain/scottish-league/dundee-dragons/eldora-flamehart':"Eldora Flamehart",
'player.great-britain/scottish-league/dundee-dragons/iris-flamehart':"Iris Flamehart",
'player.great-britain/scottish-league/dundee-dragons/luna-flamehart':"Luna Flamehart",
'player.great-britain/scottish-league/dundee-dragons/lyra-flamehart':"Lyra Flamehart",
'player.great-britain/scottish-league/dundee-dragons/martin-stormshield':"Martin Stormshield",
'player.great-britain/scottish-league/dundee-dragons/selena-flamehart':"Selena Flamehart",
'player.great-britain/scottish-league/dundee-dragons/sylvia-flamehart':"Sylvia Flamehart",
'player.great-britain/scottish-league/dundee-dragons/terra-flamehart':"Terra Flamehart",
'player.great-britain/scottish-league/glasgow-guardians/aaron-gable':"Aaron Gable",
'player.great-britain/scottish-league/glasgow-guardians/alexandra-morgan':"Alexandra Morgan",
'player.great-britain/scottish-league/glasgow-guardians/benjamin-foster':"Benjamin Foster",
'player.great-britain/scottish-league/glasgow-guardians/brandon-lloyd':"Brandon Lloyd",
'player.great-britain/scottish-league/glasgow-guardians/diana-walker':"Diana Walker",
'player.great-britain/scottish-league/glasgow-guardians/isaac-dalton':"Isaac Dalton",
'player.great-britain/scottish-league/glasgow-guardians/maxwell-harper':"Maxwell Harper",
'player.great-britain/scottish-league/glasgow-guardians/patricia-greenwood':"Patricia Greenwood",
'player.great-britain/scottish-league/glasgow-guardians/rachel-warner':"Rachel Warner",
'player.great-britain/scottish-league/glasgow-guardians/rebecca-grant':"Rebecca Grant",
'player.great-britain/scottish-league/glasgow-guardians/thomas-blake':"Thomas Blake",
'player.great-britain/scottish-league/highland-raptors/ava-griffiths':"Ava Griffiths",
'player.great-britain/scottish-league/highland-raptors/callum-macleod':"Callum MacLeod",
'player.great-britain/scottish-league/highland-raptors/connor-murphy':"Connor Murphy",
'player.great-britain/scottish-league/highland-raptors/dylan-macgregor':"Dylan MacGregor",
'player.great-britain/scottish-league/highland-raptors/erin-sinclair':"Erin Sinclair",
'player.great-britain/scottish-league/highland-raptors/evan-jones':"Evan Jones",
'player.great-britain/scottish-league/highland-raptors/fiona-douglas':"Fiona Douglas",
'player.great-britain/scottish-league/highland-raptors/isla-campbell':"Isla Campbell",
'player.great-britain/scottish-league/highland-raptors/liam-odonnell':"Liam O'Donnell",
'player.great-britain/scottish-league/highland-raptors/maeve-oneil':"Maeve O'Neil",
'player.great-britain/scottish-league/highland-raptors/rhys-williams':"Rhys Williams",
'player.great-britain/scottish-league/loch-ness-monsters/brooke-eddy':"Brooke Eddy",
'player.great-britain/scottish-league/loch-ness-monsters/caspian-flow':"Caspian Flow",
'player.great-britain/scottish-league/loch-ness-monsters/finn-oswim':"Finn O'Swim",
'player.great-britain/scottish-league/loch-ness-monsters/gillian-waters':"Gillian Waters",
'player.great-britain/scottish-league/loch-ness-monsters/laken-torrent':"Laken Torrent",
'player.great-britain/scottish-league/loch-ness-monsters/lochlan-wavecrest':"Lochlan Wavecrest",
'player.great-britain/scottish-league/loch-ness-monsters/marina-splash':"Marina Splash",
'player.great-britain/scottish-league/loch-ness-monsters/nessie-mcguard':"Nessie McGuard",
'player.great-britain/scottish-league/loch-ness-monsters/ripple-mcsplash':"Ripple McSplash",
'player.great-britain/scottish-league/loch-ness-monsters/scaleigh-mctackle':"Scaleigh McTackle",
'player.great-britain/scottish-league/loch-ness-monsters/wade-banks':"Wade Banks",
'player.great-britain/scottish-league/loch-ness-monsters/ness':"Ness",

'player.great-britain/scottish-league/redcap-rovers/angus-fraser':"Angus Fraser",
'player.great-britain/scottish-league/redcap-rovers/callum-stewart':"Callum Stewart",
'player.great-britain/scottish-league/redcap-rovers/connor-murray':"Connor Murray",
'player.great-britain/scottish-league/redcap-rovers/duncan-macgregor':"Duncan MacGregor",
'player.great-britain/scottish-league/redcap-rovers/eilidh-wallace':"Eilidh Wallace",
'player.great-britain/scottish-league/redcap-rovers/fiona-macdonald':"Fiona MacDonald",
'player.great-britain/scottish-league/redcap-rovers/gavin-reid':"Gavin Reid",
'player.great-britain/scottish-league/redcap-rovers/iona-campbell':"Iona Campbell",
'player.great-britain/scottish-league/redcap-rovers/kirsty-thomson':"Kirsty Thomson",
'player.great-britain/scottish-league/redcap-rovers/liam-ross':"Liam Ross",
'player.great-britain/scottish-league/redcap-rovers/skye-mclean':"Skye McLean",
'player.great-britain/scottish-league/silver-hoofs/aiden-orourke':"Aiden O'Rourke",
'player.great-britain/scottish-league/silver-hoofs/bronwyn-llewellyn':"Bronwyn Llewellyn",
'player.great-britain/scottish-league/silver-hoofs/ciara-byrne':"Ciara Byrne",
'player.great-britain/scottish-league/silver-hoofs/connor-maccormack':"Connor MacCormack",
'player.great-britain/scottish-league/silver-hoofs/ewan-mackinnon':"Ewan MacKinnon",
'player.great-britain/scottish-league/silver-hoofs/fiona-odonell':"Fiona O'Donell",
'player.great-britain/scottish-league/silver-hoofs/gareth-davies':"Gareth Davies",
'player.great-britain/scottish-league/silver-hoofs/gwendolyn-morgan':"Gwendolyn Morgan",
'player.great-britain/scottish-league/silver-hoofs/liam-fraser':"Liam Fraser",
'player.great-britain/scottish-league/silver-hoofs/owen-gwynn':"Owen Gwynn",
'player.great-britain/scottish-league/silver-hoofs/rhona-macleod':"Rhona MacLeod",
'player.great-britain/scottish-league/thistle-knights/aiden-macpherson':"Aiden MacPherson",
'player.great-britain/scottish-league/thistle-knights/callum-macleod':"Callum MacLeod",
'player.great-britain/scottish-league/thistle-knights/emma-gordon':"Emma Gordon",
'player.great-britain/scottish-league/thistle-knights/ewan-robertson':"Ewan Robertson",
'player.great-britain/scottish-league/thistle-knights/fiona-macgregor':"Fiona MacGregor",
'player.great-britain/scottish-league/thistle-knights/grace-anderson':"Grace Anderson",
'player.great-britain/scottish-league/thistle-knights/isla-hamilton':"Isla Hamilton",
'player.great-britain/scottish-league/thistle-knights/kirsty-fraser':"Kirsty Fraser",
'player.great-britain/scottish-league/thistle-knights/liam-stewart':"Liam Stewart",
'player.great-britain/scottish-league/thistle-knights/rachel-douglas':"Rachel Douglas",
'player.great-britain/scottish-league/thistle-knights/scott-mckenzie':"Scott McKenzie",
'player.great-britain/scottish-league/wisp-wanderers/aiden-griffiths':"Aiden Griffiths",
'player.great-britain/scottish-league/wisp-wanderers/caitlin-oneal':"Caitlin O'Neal",
'player.great-britain/scottish-league/wisp-wanderers/dylan-hughes':"Dylan Hughes",
'player.great-britain/scottish-league/wisp-wanderers/eilidh-macintyre':"Eilidh MacIntyre",
'player.great-britain/scottish-league/wisp-wanderers/finlay-ross':"Finlay Ross",
'player.great-britain/scottish-league/wisp-wanderers/gwen-powell':"Gwen Powell",
'player.great-britain/scottish-league/wisp-wanderers/liam-doyle':"Liam Doyle",
'player.great-britain/scottish-league/wisp-wanderers/morgan-davies':"Morgan Davies",
'player.great-britain/scottish-league/wisp-wanderers/niamh-murphy':"Niamh Murphy",
'player.great-britain/scottish-league/wisp-wanderers/rowan-oconnell':"Rowan O'Connell",
'player.great-britain/scottish-league/wisp-wanderers/siobhan-kennedy':"Siobhan Kennedy",
'player.great-britain/sunday-league/golden-oldies/amazing-alan':"Amazing Alan",
'player.great-britain/sunday-league/golden-oldies/big-john':"Big John",
'player.great-britain/sunday-league/golden-oldies/copper-chris':"Copper Chris",
'player.great-britain/sunday-league/golden-oldies/ian-incredible':"Ian Incredible",
'player.great-britain/sunday-league/golden-oldies/matt-the-injured':"Matt the Injured",
'player.great-britain/sunday-league/golden-oldies/mike-the-ref':"Mike the Ref",
'player.great-britain/sunday-league/golden-oldies/nifty-nickie':"Nifty Nickie",
'player.great-britain/sunday-league/golden-oldies/pirate-john':"Pirate John",
'player.great-britain/sunday-league/golden-oldies/rob-reliable':"Rob Reliable",
'player.great-britain/sunday-league/golden-oldies/rockstar-mark':"Rockstar Mark",
'player.great-britain/sunday-league/golden-oldies/terrific-taylor':"Terrific Taylor",
'player.great-britain/sunday-league/tangerine-dream/awesome-andy':"Awesome Andy",
'player.great-britain/sunday-league/tangerine-dream/beautiful-dan':"Beautiful Dan",
'player.great-britain/sunday-league/tangerine-dream/ben-the-diver':"Ben The Diver",
'player.great-britain/sunday-league/tangerine-dream/big-al':"Big Al",
'player.great-britain/sunday-league/tangerine-dream/chris-the-tactician':"Chris The Tactician",
'player.great-britain/sunday-league/tangerine-dream/craig-the-muffin':"Craig the Muffin",
'player.great-britain/sunday-league/tangerine-dream/daring-dave':"Daring Dave",
'player.great-britain/sunday-league/tangerine-dream/paul-pace-abuse':"Paul Pace Abuse",
'player.great-britain/sunday-league/tangerine-dream/peter-bale':"Peter Bale",
'player.great-britain/sunday-league/tangerine-dream/phil-phenom':"Phil Phenom",
'player.great-britain/sunday-league/tangerine-dream/rocket-shot-ash':"Rocket Shot Ash",
'player.great-britain/sunday-league/team-chimera/alex-the-great':"Alex the Great",
'player.great-britain/sunday-league/team-chimera/ash':"Ash",
'player.great-britain/sunday-league/team-chimera/chopper-curtis':"Chopper Curtis",
'player.great-britain/sunday-league/team-chimera/dan-darling':"Dan Darling",
'player.great-britain/sunday-league/team-chimera/humbaba':"Humbaba",
'player.great-britain/sunday-league/team-chimera/iron-man-andy':"Iron Man Andy",
'player.great-britain/sunday-league/team-chimera/jack-russell':"Jack Russell",
'player.great-britain/sunday-league/team-chimera/mike-ruggers':"Mike Ruggers",
'player.great-britain/sunday-league/team-chimera/molla':"Molla",
'player.great-britain/sunday-league/team-chimera/robbie-rotweiler':"Robbie Rotweiler",
'player.great-britain/sunday-league/team-chimera/skrog-skullcrusher':"Skrog Skullcrusher",
'player.great-britain/sunday-league/thursday-football/adam-adams':"Adam Adams",
'player.great-britain/sunday-league/thursday-football/alan-allen':"Alan Allen",
'player.great-britain/sunday-league/thursday-football/bob-roberts':"Bob Roberts",
'player.great-britain/sunday-league/thursday-football/brett-the-hitman':"Brett The Hitman",
'player.great-britain/sunday-league/thursday-football/daniel-daniels':"Daniel Daniels",
'player.great-britain/sunday-league/thursday-football/jack-johnson':"Jack Johnson",
'player.great-britain/sunday-league/thursday-football/jon-jackson':"Jon Jackson",
'player.great-britain/sunday-league/thursday-football/michael-michaels':"Michael Michaels",
'player.great-britain/sunday-league/thursday-football/mickey-michaels':"Mickey Michaels",
'player.great-britain/sunday-league/thursday-football/paul-ralls':"Paul Ralls",
'player.great-britain/sunday-league/thursday-football/tom-thompson':"Tom Thompson",
'player.great-britain/welsh-league/blue-dragons/alan-rambo':"Alan Rambo",
'player.great-britain/welsh-league/blue-dragons/andrew-taylor':"Andrew Taylor",
'player.great-britain/welsh-league/blue-dragons/capital-dragon':"Capital Dragon",
'player.great-britain/welsh-league/blue-dragons/danielle-collins':"Danielle Collins",
'player.great-britain/welsh-league/blue-dragons/freda-chopra':"Freda Chopra",
'player.great-britain/welsh-league/blue-dragons/michelle-hudson':"Michelle Hudson",
'player.great-britain/welsh-league/blue-dragons/patricia-kavanagh':"Patricia Kavanagh",
'player.great-britain/welsh-league/blue-dragons/patricia-wittingham':"Patricia Wittingham",
'player.great-britain/welsh-league/blue-dragons/robert-blake':"Robert Blake",
'player.great-britain/welsh-league/blue-dragons/steven-manson':"Steven Manson",
'player.great-britain/welsh-league/blue-dragons/tom-heather':"Tom Heather",
'player.great-britain/welsh-league/gelerts-guardians/bailey-williams':"Bailey Williams",
'player.great-britain/welsh-league/gelerts-guardians/bella-jones':"Bella Jones",
'player.great-britain/welsh-league/gelerts-guardians/biff-pearce':"Biff Pearce",
'player.great-britain/welsh-league/gelerts-guardians/charlie-vaughan':"Charlie Vaughan",
'player.great-britain/welsh-league/gelerts-guardians/mali-edwards':"Mali Edwards",
'player.great-britain/welsh-league/gelerts-guardians/max-evans':"Max Evans",
'player.great-britain/welsh-league/gelerts-guardians/molly-lloyd':"Molly Lloyd",
'player.great-britain/welsh-league/gelerts-guardians/ottie-pearce':"Ottie Pearce",
'player.great-britain/welsh-league/gelerts-guardians/rosie-powell':"Rosie Powell",
'player.great-britain/welsh-league/gelerts-guardians/tessa-edwards':"Tessa Edwards",
'player.great-britain/welsh-league/gelerts-guardians/twm-edwards':"Twm Edwards",
'player.great-britain/welsh-league/gelerts-guardians/rose-cole':"Rose Cole",
'player.great-britain/welsh-league/gelerts-guardians/max-smith':"Max Smith",
'player.great-britain/welsh-league/gelerts-guardians/bailey':"Bailey",
'player.great-britain/welsh-league/gelerts-guardians/bella':"Bella",
'player.great-britain/welsh-league/gelerts-guardians/biff':"Biff",
'player.great-britain/welsh-league/gelerts-guardians/charlie':"Charlie",
'player.great-britain/welsh-league/gelerts-guardians/mali':"Mali",
'player.great-britain/welsh-league/gelerts-guardians/max':"Max",
'player.great-britain/welsh-league/gelerts-guardians/molly':"Molly",
'player.great-britain/welsh-league/gelerts-guardians/ottie':"Ottie",
'player.great-britain/welsh-league/gelerts-guardians/rosie':"Rosie",
'player.great-britain/welsh-league/gelerts-guardians/tessa':"Tess",
'player.great-britain/welsh-league/gelerts-guardians/twm':"Twm",
'player.great-britain/welsh-league/gelerts-guardians/rose':"Rose",


'player.great-britain/welsh-league/knights-of-the-round-table/king-arthur':"King Arthur",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-bedivere':"Sir Bedivere",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-bors':"Sir Bors",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-gaheris':"Sir Gaheris",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-galahad':"Sir Galahad",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-gareth':"Sir Gareth",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-gawain':"Sir Gawain",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-kay':"Sir Kay",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-lancelot':"Sir Lancelot",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-percival':"Sir Percival",
'player.great-britain/welsh-league/knights-of-the-round-table/sir-tristan':"Sir Tristan",
'player.great-britain/welsh-league/ladies-of-the-lakes/alys-hughes':"Alys Hughes",
'player.great-britain/welsh-league/ladies-of-the-lakes/bronwen-lloyd':"Bronwen Lloyd",
'player.great-britain/welsh-league/ladies-of-the-lakes/carys-john':"Carys John",
'player.great-britain/welsh-league/ladies-of-the-lakes/cerys-morgan':"Cerys Morgan",
'player.great-britain/welsh-league/ladies-of-the-lakes/eirlys-parry':"Eirlys Parry",
'player.great-britain/welsh-league/ladies-of-the-lakes/gwyneth-ellis':"Gwyneth Ellis",
'player.great-britain/welsh-league/ladies-of-the-lakes/isla-vaughan':"Isla Vaughan",
'player.great-britain/welsh-league/ladies-of-the-lakes/mali-thomas':"Mali Thomas",
'player.great-britain/welsh-league/ladies-of-the-lakes/nerys-evans':"Nerys Evans",
'player.great-britain/welsh-league/ladies-of-the-lakes/rhianwen-powell':"Rhianwen Powell",
'player.great-britain/welsh-league/ladies-of-the-lakes/seren-griffith':"Seren Griffith",
'player.great-britain/welsh-league/ladies-of-the-lakes/jane-smith':"Jane Smith",
'player.great-britain/welsh-league/ladies-of-the-lakes/morag-campbell':"Morag Campbell",
'player.great-britain/welsh-league/ladies-of-the-lakes/fiona-gallagher':"Fiona Gallagher",
'player.great-britain/welsh-league/ladies-of-the-lakes/aoife-omalley':"Aoife O'Malley",


'player.great-britain/welsh-league/llani-town-hobbits/bronwen-mossfoot':"Bronwen Mossfoot",
'player.great-britain/welsh-league/llani-town-hobbits/dylan-longfoot':"Dylan Longfoot",
'player.great-britain/welsh-league/llani-town-hobbits/efa-sandheaver':"Efa Sandheaver",
'player.great-britain/welsh-league/llani-town-hobbits/eric-gervais':"Eric Gervais",
'player.great-britain/welsh-league/llani-town-hobbits/hugh-edwards':"Hugh Edwards",
'player.great-britain/welsh-league/llani-town-hobbits/idris-underhill':"Idris Underhill",
'player.great-britain/welsh-league/llani-town-hobbits/llew-goodbarrel':"Llew Goodbarrel",
'player.great-britain/welsh-league/llani-town-hobbits/marian-gervais':"Marian Gervais",
'player.great-britain/welsh-league/llani-town-hobbits/owen-brandybuck':"Owen Brandybuck",
'player.great-britain/welsh-league/llani-town-hobbits/seren-bagshot':"Seren Bagshot",
'player.great-britain/welsh-league/llani-town-hobbits/taliesin-greenhill':"Taliesin Greenhill",
'player.great-britain/welsh-league/newport-chartists/andrew-oliver':"Andrew Oliver",
'player.great-britain/welsh-league/newport-chartists/dai-barwell':"Dai Barwell",
'player.great-britain/welsh-league/newport-chartists/danielle-evans':"Danielle Evans",
'player.great-britain/welsh-league/newport-chartists/fiona-jones':"Fiona Jones",
'player.great-britain/welsh-league/newport-chartists/kristine-hughes':"Kristine Hughes",
'player.great-britain/welsh-league/newport-chartists/leslie-moran':"Leslie Moran",
'player.great-britain/welsh-league/newport-chartists/michael-davies':"Michael Davies",
'player.great-britain/welsh-league/newport-chartists/nigel-roberts':"Nigel Roberts",
'player.great-britain/welsh-league/newport-chartists/olivia-morgan':"Olivia Morgan",
'player.great-britain/welsh-league/newport-chartists/rachel-lewis':"Rachel Lewis",
'player.great-britain/welsh-league/newport-chartists/sian-powell':"Sian Powell",
'player.great-britain/welsh-league/pembrokeshire-mermaids/aine':"Aine",
'player.great-britain/welsh-league/pembrokeshire-mermaids/eirlys':"Eirlys",
'player.great-britain/welsh-league/pembrokeshire-mermaids/elspeth':"Elspeth",
'player.great-britain/welsh-league/pembrokeshire-mermaids/islay':"Islay",
'player.great-britain/welsh-league/pembrokeshire-mermaids/mara':"Mara",
'player.great-britain/welsh-league/pembrokeshire-mermaids/moira':"Moira",
'player.great-britain/welsh-league/pembrokeshire-mermaids/morwenna':"Morwenna",
'player.great-britain/welsh-league/pembrokeshire-mermaids/niamh':"Niamh",
'player.great-britain/welsh-league/pembrokeshire-mermaids/peregrine':"Peregrine",
'player.great-britain/welsh-league/pembrokeshire-mermaids/rhiannon':"Rhiannon",
'player.great-britain/welsh-league/pembrokeshire-mermaids/siobhan':"Siobhan",
'player.great-britain/welsh-league/port-talbot-orcs/bethan-uzbad':"Bethan Uzbad",
'player.great-britain/welsh-league/port-talbot-orcs/ceri-gashnak':"Ceri Gashnak",
'player.great-britain/welsh-league/port-talbot-orcs/dai-korgar':"Dai Korgar",
'player.great-britain/welsh-league/port-talbot-orcs/eira-groth':"Eira Groth",
'player.great-britain/welsh-league/port-talbot-orcs/gareth-muzgash':"Gareth Muzgash",
'player.great-britain/welsh-league/port-talbot-orcs/gwenth-brakgul':"Gwenth Brakgul",
'player.great-britain/welsh-league/port-talbot-orcs/ieuan-skarfang':"Ieuan Skarfang",
'player.great-britain/welsh-league/port-talbot-orcs/megan-thurgash':"Megan Thurgash",
'player.great-britain/welsh-league/port-talbot-orcs/nerys-dushnamub':"Nerys Dushnamub",
'player.great-britain/welsh-league/port-talbot-orcs/owen-snagtooth':"Owen Snagtooth",
'player.great-britain/welsh-league/port-talbot-orcs/rhys-lokk':"Rhys Lokk",
'player.great-britain/welsh-league/port-talbot-orcs/emily-groth':"Emily Groth",
'player.great-britain/welsh-league/port-talbot-orcs/hamish-gashnak':"Hamish Gashnak",
'player.great-britain/welsh-league/port-talbot-orcs/sean-skarfang':"Sean Skarfang",
'player.great-britain/welsh-league/port-talbot-orcs/alex-korgar':"Alex Korgar",


'player.great-britain/welsh-league/rhayader-red-kites/angharad-griffiths':"Angharad Griffiths",
'player.great-britain/welsh-league/rhayader-red-kites/bethan-williams':"Bethan Williams",
'player.great-britain/welsh-league/rhayader-red-kites/carys-evans':"Carys Evans",
'player.great-britain/welsh-league/rhayader-red-kites/eleri-thomas':"Eleri Thomas",
'player.great-britain/welsh-league/rhayader-red-kites/ffion-edwards':"Ffion Edwards",
'player.great-britain/welsh-league/rhayader-red-kites/gwenllian-morgan':"Gwenllian Morgan",
'player.great-britain/welsh-league/rhayader-red-kites/megan-lloyd':"Megan Lloyd",
'player.great-britain/welsh-league/rhayader-red-kites/nia-hughes':"Nia Hughes",
'player.great-britain/welsh-league/rhayader-red-kites/rhian-jones':"Rhian Jones",
'player.great-britain/welsh-league/rhayader-red-kites/seren-roberts':"Seren Roberts",
'player.great-britain/welsh-league/rhayader-red-kites/sian-davies':"Sian Davies",
'player.great-britain/welsh-league/rhayader-red-kites/clara-macintyre':"Clara MacIntyre",
'player.great-britain/welsh-league/rhayader-red-kites/saoirse-obrien':"Saoirse O'Brien",
'player.great-britain/welsh-league/rhayader-red-kites/isla-thompson':"Isla Thompson",


'player.great-britain/welsh-league/swansea-black-swans/alun-taylor':"Alun Taylor",
'player.great-britain/welsh-league/swansea-black-swans/ann-rangel':"Ann Rangel",
'player.great-britain/welsh-league/swansea-black-swans/ashley-jones':"Ashley Jones",
'player.great-britain/welsh-league/swansea-black-swans/bethan-davies':"Bethan Davies",
'player.great-britain/welsh-league/swansea-black-swans/bonnie-wilfred':"Bonnie Wilfred",
'player.great-britain/welsh-league/swansea-black-swans/isabella-vorm':"Isabella Vorm",
'player.great-britain/welsh-league/swansea-black-swans/jenna-shelvey':"Jenna Shelvey",
'player.great-britain/welsh-league/swansea-black-swans/josie-allen':"Josie Allen",
'player.great-britain/welsh-league/swansea-black-swans/lee-trickster':"Lee Trickster",
'player.great-britain/welsh-league/swansea-black-swans/lyla-britton':"Lyla Britton",
'player.great-britain/welsh-league/swansea-black-swans/noah-davenport':"Noah Davenport",
'player.great-britain/welsh-league/wrexham-red-stars/alexis-steele':"Alexis Steele",
'player.great-britain/welsh-league/wrexham-red-stars/eva-blazek':"Eva Blazek",
'player.great-britain/welsh-league/wrexham-red-stars/jade-liu':"Jade Liu",
'player.great-britain/welsh-league/wrexham-red-stars/natalie-nakamura':"Natalie Nakamura",
'player.great-britain/welsh-league/wrexham-red-stars/paul-mighty':"Paul Mighty",
'player.great-britain/welsh-league/wrexham-red-stars/red-paulson':"Red Paulson",
'player.great-britain/welsh-league/wrexham-red-stars/rico-montoya':"Rico Montoya",
'player.great-britain/welsh-league/wrexham-red-stars/rob-maverick':"Rob Maverick",
'player.great-britain/welsh-league/wrexham-red-stars/ryan-pool':"Ryan Pool",
'player.great-britain/welsh-league/wrexham-red-stars/scarlett-jones':"Scarlett Jones",
'player.great-britain/welsh-league/wrexham-red-stars/wild-card-willy':"Wild Card Willy",
'player.south-europe/italian-league/centuriones-rubri/adrianus-bellus-balbo':"Adrianus Bellus Balbo",
'player.south-europe/italian-league/centuriones-rubri/alesius-romanus-francesco':"Alesius Romanus Francesco",
'player.south-europe/italian-league/centuriones-rubri/antonius-carolus-zagus':"Antonius Carolus Zagus",
'player.south-europe/italian-league/centuriones-rubri/christianus-romanus-zanettus':"Christianus Romanus Zanettus",
'player.south-europe/italian-league/centuriones-rubri/eduardus-brasiliensis-emersonius':"Eduardus Brasiliensis Emersonius",
'player.south-europe/italian-league/centuriones-rubri/flavius-augustus-brazilius':"Flavius Augustus Brazilius",
'player.south-europe/italian-league/centuriones-rubri/gabrielus-argentus-batistuta':"Gabrielus Argentus Batistuta",
'player.south-europe/italian-league/centuriones-rubri/gabrielus-argentus-brutus':"Gabrielus Argentus Brutus",
'player.south-europe/italian-league/centuriones-rubri/gaius-aurelius-marcellus':"Gaius Aurelius Marcellus",
'player.south-europe/italian-league/centuriones-rubri/gaius-julius-zottus':"Gaius Julius Zottus",
'player.south-europe/italian-league/centuriones-rubri/georgius-bellator-totti':"Georgius Bellator Totti",
'player.south-europe/italian-league/centuriones-rubri/hidekius-japonicus-nakata':"Hidekius Japonicus Nakata",
'player.south-europe/italian-league/centuriones-rubri/julius-francus-zebina':"Julius Francus Zebina",
'player.south-europe/italian-league/centuriones-rubri/lucas-augustus-assunção':"Lucas Augustus Assunção",
'player.south-europe/italian-league/centuriones-rubri/lucius-julius-aldarius':"Lucius Julius Aldarius",
'player.south-europe/italian-league/centuriones-rubri/marcus-valerius-samuellus':"Marcus Valerius Samuellus",
'player.south-europe/italian-league/centuriones-rubri/ricardus-romanus-tommasius':"Ricardus Romanus Tommasius",
'player.south-europe/italian-league/centuriones-rubri/robertus-domicus-delvecchius':"Robertus Domicus Delvecchius",
'player.south-europe/italian-league/centuriones-rubri/vincentius-gallicus-candela':"Vincentius Gallicus Candela",
'player.south-europe/italian-league/diavoli-immortali/andrea-corsini':"Andrea Corsini",
'player.south-europe/italian-league/diavoli-immortali/daniele-marchisio':"Daniele Marchisio",
'player.south-europe/italian-league/diavoli-immortali/danijel-stojanovic':"Danijel Stojanovic",
'player.south-europe/italian-league/diavoli-immortali/davide-albertini':"Davide Albertini",
'player.south-europe/italian-league/diavoli-immortali/federico-galante':"Federico Galante",
'player.south-europe/italian-league/diavoli-immortali/gabriele-lombardo':"Gabriele Lombardo",
'player.south-europe/italian-league/diavoli-immortali/jean-marc-pelletier':"Jean-Marc Pelletier",
'player.south-europe/italian-league/diavoli-immortali/lorenzo-baratti':"Lorenzo Baratti",
'player.south-europe/italian-league/diavoli-immortali/luciano-ferraro':"Luciano Ferraro",
'player.south-europe/italian-league/diavoli-immortali/marc-antoine-dussault':"Marc-Antoine Dussault",
'player.south-europe/italian-league/diavoli-immortali/matteo-taverna':"Matteo Taverna",
'player.south-europe/italian-league/diavoli-immortali/pietro-baldi':"Pietro Baldi",
'player.south-europe/italian-league/diavoli-immortali/riccardo-donato':"Riccardo Donato",
'player.south-europe/italian-league/diavoli-immortali/zlatko-babic':"Zlatko Babic",
'player.south-europe/italian-league/giglio-ardente/alessandra-zandoni':"Alessandra Zandoni",
'player.south-europe/italian-league/giglio-ardente/andrea-tarozza':"Andrea Tarozza",
'player.south-europe/italian-league/giglio-ardente/eduarda':"Eduarda",
'player.south-europe/italian-league/giglio-ardente/emilia-bigatti':"Emilia Bigatti",
'player.south-europe/italian-league/giglio-ardente/franca-bellini':"Franca Bellini",
'player.south-europe/italian-league/giglio-ardente/gabe-batigolli':"Gabe Batigolli",
'player.south-europe/italian-league/giglio-ardente/giorgia-heinrich':"Giorgia Heinrich",
'player.south-europe/italian-league/giglio-ardente/guillermo-amora':"Guillermo Amora",
'player.south-europe/italian-league/giglio-ardente/lorenzo-colucci':"Lorenzo Colucci",
'player.south-europe/italian-league/giglio-ardente/luca-repko':"Luca Repko",
'player.south-europe/italian-league/giglio-ardente/luisa-olivieri':"Luisa Olivieri",
'player.south-europe/italian-league/giglio-ardente/moreno-torelli':"Moreno Torelli",
'player.south-europe/italian-league/giglio-ardente/pasqualina-padovano':"Pasqualina Padovano",
'player.south-europe/italian-league/giglio-ardente/rita-costa':"Rita Costa",
'player.south-europe/italian-league/giglio-ardente/sandra-cois':"Sandra Cois",
'player.south-europe/italian-league/giglio-ardente/stefania-bertini':"Stefania Bertini",
'player.south-europe/italian-league/murmillo-caeruleus/antonius-bellator':"Antonius Bellator",
'player.south-europe/italian-league/murmillo-caeruleus/brujita':"Brujita",
'player.south-europe/italian-league/murmillo-caeruleus/cassio-muralis':"Cassio Muralis",
'player.south-europe/italian-league/murmillo-caeruleus/dante-virtus':"Dante Virtus",
'player.south-europe/italian-league/murmillo-caeruleus/hector-ferrum':"Hector Ferrum",
'player.south-europe/italian-league/murmillo-caeruleus/lucius-portarius':"Lucius Portarius",
'player.south-europe/italian-league/murmillo-caeruleus/marco-pugnator':"Marco Pugnator",
'player.south-europe/italian-league/murmillo-caeruleus/pavel-luptator':"Pavel Luptator",
'player.south-europe/italian-league/murmillo-caeruleus/primo-scudiero':"Primo Scudiero",
'player.south-europe/italian-league/murmillo-caeruleus/sergio-velox':"Sergio Velox",
'player.south-europe/italian-league/murmillo-caeruleus/valerius-velox':"Valerius Velox",
'player.south-europe/italian-league/rettili-nerazzurri/alessio-winteri':"Alessio Winteri",
'player.south-europe/italian-league/rettili-nerazzurri/alvaro-recalda':"Alvaro Recalda",
'player.south-europe/italian-league/rettili-nerazzurri/angelo-mazzini':"Angelo Mazzini",
'player.south-europe/italian-league/rettili-nerazzurri/benoît-courto':"Benoît Courto",
'player.south-europe/italian-league/rettili-nerazzurri/diego-simero':"Diego Simero",
'player.south-europe/italian-league/rettili-nerazzurri/fernando-galiardo':"Fernando Galiardo",
'player.south-europe/italian-league/rettili-nerazzurri/giorgio-bergato':"Giorgio Bergato",
'player.south-europe/italian-league/rettili-nerazzurri/ismael-zambrana':"Ismael Zambrana",
'player.south-europe/italian-league/rettili-nerazzurri/julio-zanatta':"Julio Zanatta",
'player.south-europe/italian-league/rettili-nerazzurri/luciano-fresini':"Luciano Fresini",
'player.south-europe/italian-league/rettili-nerazzurri/marco-tarantini':"Marco Tarantini",
'player.south-europe/italian-league/rettili-nerazzurri/nando-bertasso':"Nando Bertasso",
'player.south-europe/italian-league/rettili-nerazzurri/paolo-saurini':"Paolo Saurini",
'player.south-europe/italian-league/rettili-nerazzurri/roberto-lizario':"Roberto Lizario",
'player.south-europe/italian-league/rettili-nerazzurri/salvo-pellegrini':"Salvo Pellegrini",
'player.south-europe/italian-league/rettili-nerazzurri/tino-westini':"Tino Westini",
'player.south-europe/italian-league/rettili-nerazzurri/yves-djorkscale':"Yves Djorkscale",
'player.south-europe/italian-league/rettili-nerazzurri/zeno-elias':"Zeno Elias",
'player.south-europe/italian-league/squadra-partenopea/aleixo':"Aleixo",
'player.south-europe/italian-league/squadra-partenopea/alessandro-renica':"Alessandro Renica",
'player.south-europe/italian-league/squadra-partenopea/andrea-carnivale':"Andrea Carnivale",
'player.south-europe/italian-league/squadra-partenopea/antonio-bucciarelli':"Antonio Bucciarelli",
'player.south-europe/italian-league/squadra-partenopea/carina':"Carina",
'player.south-europe/italian-league/squadra-partenopea/claudia-ferraro':"Claudia Ferraro",
'player.south-europe/italian-league/squadra-partenopea/daniela-maradona':"Daniela Maradona",
'player.south-europe/italian-league/squadra-partenopea/diego-milagroso':"Diego Milagroso",
'player.south-europe/italian-league/squadra-partenopea/fernanda-de-napolitano':"Fernanda De Napolitano",
'player.south-europe/italian-league/squadra-partenopea/giancarla-corradini':"Giancarla Corradini",
'player.south-europe/italian-league/squadra-partenopea/gianfranca-zola':"Gianfranca Zola",
'player.south-europe/italian-league/squadra-partenopea/giovanni-francini':"Giovanni Francini",
'player.south-europe/italian-league/squadra-partenopea/giulia-giuliani':"Giulia Giuliani",
'player.south-europe/italian-league/squadra-partenopea/lucia-fusi':"Lucia Fusi",
'player.south-europe/italian-league/squadra-partenopea/marco-belloni':"Marco Belloni",
'player.south-europe/italian-league/squadra-partenopea/massima-crippa':"Massima Crippa",
'player.south-europe/italian-league/squadra-partenopea/maurizia-neri':"Maurizia Neri",
'player.south-europe/italian-league/squadra-partenopea/rafaela-di-francesco':"Rafaela Di Francesco",
'player.south-europe/italian-league/squadra-partenopea/tebalda-bigliardi':"Tebalda Bigliardi",
'player.south-europe/italian-league/squadra-partenopea/teresa-silvestri':"Teresa Silvestri",
'player.south-europe/italian-league/tornado-gialloblu/abigail-balboa':"Abigail Balboa",
'player.south-europe/italian-league/tornado-gialloblu/alize-bohos':"Alize Bohos",
'player.south-europe/italian-league/tornado-gialloblu/annalisa-benatti-':"Annalisa Benatti ",
'player.south-europe/italian-league/tornado-gialloblu/delia-bagnoli':"Delia Bagnoli",
'player.south-europe/italian-league/tornado-gialloblu/denis-fusco':"Denis Fusco",
'player.south-europe/italian-league/tornado-gialloblu/elena-chiesi':"Elena Chiesi",
'player.south-europe/italian-league/tornado-gialloblu/fabiola-aspri':"Fabiola Aspri",
'player.south-europe/italian-league/tornado-gialloblu/federico-cantoro':"Federico Cantoro",
'player.south-europe/italian-league/tornado-gialloblu/giada-bufalini':"Giada Bufalini",
'player.south-europe/italian-league/tornado-gialloblu/helena-crespi':"Helena Crespi",
'player.south-europe/italian-league/tornado-gialloblu/julia-s.-verona':"Julia S. Verona",
'player.south-europe/italian-league/tornado-gialloblu/liane-turam':"Liane Turam",
'player.south-europe/italian-league/tornado-gialloblu/lionel-sensi':"Lionel Sensi",
'player.south-europe/italian-league/tornado-gialloblu/lorena-sarto':"Lorena Sarto",
'player.south-europe/italian-league/tornado-gialloblu/lucia-apoloni':"Lucia Apoloni",
'player.south-europe/italian-league/tornado-gialloblu/marta-stanic':"Marta Stanic",
'player.south-europe/italian-league/tornado-gialloblu/patrizia-vanoli':"Patrizia Vanoli",
'player.south-europe/italian-league/tornado-gialloblu/pietra-orlandi':"Pietra Orlandi",
'player.south-europe/italian-league/tornado-gialloblu/raphaela-lungo':"Raphaela Lungo",
'player.south-europe/italian-league/tornado-gialloblu/stefania-fiori':"Stefania Fiori",
'player.south-europe/italian-league/toro-infucato/cristiano-vernieri':"Cristiano Vernieri",
'player.south-europe/italian-league/toro-infucato/esteban-alvarez':"Esteban Alvarez",
'player.south-europe/italian-league/toro-infucato/ezio-scintilla':"Ezio Scintilla",
'player.south-europe/italian-league/toro-infucato/gabriele-bresci':"Gabriele Bresci",
'player.south-europe/italian-league/toro-infucato/giulio-sordini':"Giulio Sordini",
'player.south-europe/italian-league/toro-infucato/giuseppe-conti':"Giuseppe Conti",
'player.south-europe/italian-league/toro-infucato/guglielmo-lazzarini':"Guglielmo Lazzarini",
'player.south-europe/italian-league/toro-infucato/leonardo-ferrini':"Leonardo Ferrini",
'player.south-europe/italian-league/toro-infucato/leonardo-marcatelli':"Leonardo Marcatelli",
'player.south-europe/italian-league/toro-infucato/lorenzo-pastori':"Lorenzo Pastori",
'player.south-europe/italian-league/toro-infucato/michele-bertucci':"Michele Bertucci",
'player.south-europe/italian-league/toro-infucato/piero-bravi':"Piero Bravi",
'player.south-europe/italian-league/toro-infucato/rafael-marquez-velasquez':"Rafael Marquez Velasquez",
'player.south-europe/italian-league/toro-infucato/riccardo-de-francesco':"Riccardo De Francesco",
'player.south-europe/italian-league/toro-infucato/riccardo-montesi':"Riccardo Montesi",
'player.south-europe/italian-league/toro-infucato/romano-calvetti':"Romano Calvetti",
'player.south-europe/italian-league/toro-infucato/salvatore-corsini':"Salvatore Corsini",
'player.south-europe/italian-league/toro-infucato/silvio-benassi':"Silvio Benassi",
'player.south-europe/italian-league/tritoni-blucerchiati/adriano-lombarda':"Adriano Lombarda",
'player.south-europe/italian-league/tritoni-blucerchiati/cesare-ferretti':"Cesare Ferretti",
'player.south-europe/italian-league/tritoni-blucerchiati/fausto-sireno':"Fausto Sireno",
'player.south-europe/italian-league/tritoni-blucerchiati/filippo-colvetti':"Filippo Colvetti",
'player.south-europe/italian-league/tritoni-blucerchiati/gavino-palumbo':"Gavino Palumbo",
'player.south-europe/italian-league/tritoni-blucerchiati/giorgio-vialle':"Giorgio Vialle",
'player.south-europe/italian-league/tritoni-blucerchiati/luigi-fuso':"Luigi Fuso",
'player.south-europe/italian-league/tritoni-blucerchiati/maresco-mannetti':"Maresco Mannetti",
'player.south-europe/italian-league/tritoni-blucerchiati/pietro-ventola':"Pietro Ventola",
'player.south-europe/italian-league/tritoni-blucerchiati/roberto-mareni':"Roberto Mareni",
'player.south-europe/italian-league/tritoni-blucerchiati/tonio-pescatore':"Tonio Pescatore",
'player.south-europe/italian-league/vecchia-signori/alessandra-del-piatti':"Alessandra Del Piatti",
'player.south-europe/italian-league/vecchia-signori/angelina-pellegruzzi':"Angelina Pellegruzzi",
'player.south-europe/italian-league/vecchia-signori/antonia-contessa':"Antonia Contessa",
'player.south-europe/italian-league/vecchia-signori/bianca-baggioli':"Bianca Baggioli",
'player.south-europe/italian-league/vecchia-signori/carla-ferranti':"Carla Ferranti",
'player.south-europe/italian-league/vecchia-signori/daniela-descharmes':"Daniela Descharmes",
'player.south-europe/italian-league/vecchia-signori/elena-jarni':"Elena Jarni",
'player.south-europe/italian-league/vecchia-signori/fabrizia-ravanelli':"Fabrizia Ravanelli",
'player.south-europe/italian-league/vecchia-signori/giovanna-vialletti':"Giovanna Vialletti",
'player.south-europe/italian-league/vecchia-signori/jolanda-carreri':"Jolanda Carreri",
'player.south-europe/italian-league/vecchia-signori/lorenzo-squizzi':"Lorenzo Squizzi",
'player.south-europe/italian-league/vecchia-signori/marco-torricelli':"Marco Torricelli",
'player.south-europe/italian-league/vecchia-signori/monica-torelli':"Monica Torelli",
'player.south-europe/italian-league/vecchia-signori/paola-sotto':"Paola Sotto",
'player.south-europe/italian-league/vecchia-signori/patrizia-sousa':"Patrizia Sousa",
'player.south-europe/italian-league/vecchia-signori/roberta-jarnik':"Roberta Jarnik",
'player.south-europe/italian-league/vecchia-signori/serena-porretti':"Serena Porretti",
'player.south-europe/italian-league/vecchia-signori/silvia-contessa':"Silvia Contessa",
'player.south-europe/spanish-league/royal-celestials/aisha-dubois':"Aisha Dubois",
'player.south-europe/spanish-league/royal-celestials/cannon':"Cannon",
'player.south-europe/spanish-league/royal-celestials/carla-markelle':"Carla Markelle",
'player.south-europe/spanish-league/royal-celestials/ilana-castillo':"Ilana Castillo",
'player.south-europe/spanish-league/royal-celestials/maestro':"Maestro",
'player.south-europe/spanish-league/royal-celestials/marcos-lopez':"Marcos Lopez",
'player.south-europe/spanish-league/royal-celestials/phenom':"Phenom",
'player.south-europe/spanish-league/royal-celestials/raúl-álvarez':"Raúl Álvarez",
'player.south-europe/spanish-league/royal-celestials/space-balls':"Space Balls",
'player.south-europe/spanish-league/royal-celestials/stallion':"Stallion",
'player.south-europe/spanish-league/royal-celestials/wise-wolf':"Wise Wolf",
}