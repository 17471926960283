import { Colours } from '@alienheadwars/football-card-manager-model';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface D20Props {
    result: number;
    rollFromTop?: boolean;
    colours: Colours;
    roll?: boolean;
    rollTime?: number
    rollCompleteCallback?: () => void
}

const impactFonts = ['Norwester', 'sans-serif'].join(',');

export const D20 = (props: D20Props) => {
    const [diceValue, setDiceValue] = useState(props.roll ? 20 : props.result)
    const [rollingInterval, setRollingInterval] = useState(0)

    useEffect(() => {
        if (props.roll && !rollingInterval) {
            const interval = setInterval(() => {
                setDiceValue(currentDiceValue => {
                    const newValue = Math.floor(Math.random() * 20) + 1;
                    return newValue
                })
            }, Math.floor(Math.random() * 200) + 50);
            setTimeout(() => {
                clearInterval(interval);
                setDiceValue(props.result)
                if (props.rollCompleteCallback) {
                    props.rollCompleteCallback()
                }
            }, props.rollTime?? Math.floor(Math.random() * 3000) + 500);
            console.log(props.rollTime)
            setRollingInterval(interval)
        } else if (!props.roll) {
            if (rollingInterval) {

                clearInterval(rollingInterval)
            }
            setDiceValue(props.result)
        }
    }, [props.roll, props.result, rollingInterval, props])
    return <Box sx={{
        height: '10vh',
        width: '10vh',
        position: 'relative',
        fontFamily: impactFonts,
        color: props.colours.primaryTextColour,
        textAlign: 'center',
        fontSize: '4vh',
        lineHeight: '10vh'
    }}>
        <D20SVG primaryColour={props.colours.primaryColour} secondaryColour={props.colours.secondaryColour} fillColour={props.colours.primaryBackgroundColour} />
        <b style={{

            position: 'relative'
        }}>{diceValue}</b>
    </Box>
}


export const D20SVG = (props: { primaryColour: string, secondaryColour: string, fillColour: string }) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        opacity: 0.5,
    }}>
        <path stroke={props.secondaryColour} fill={props.fillColour} d="M64 169.6l0 150.7 59.2-107.6L64 169.6zm13.9-29.4l61.4 44.7L217.5 56.4 77.9 140.2zm4 213.9l143.3 11.5L145 239.4 81.9 354zM104.8 388L240 469.1l0-70.3L104.8 388zm67.7-196l167.1 0L256 54.8 172.5 192zm.7 32L256 354.2 338.9 224l-165.7 0zM272 398.8l0 70.3L407.2 388 272 398.8zm14.7-33.3L430.1 354 367 239.4 286.7 365.5zm7.7-309.1l78.2 128.4 61.4-44.7L294.5 56.4zm94.4 156.2L448 320.3l0-150.7-59.2 43z" />
        <path stroke={props.secondaryColour} fill={props.primaryColour} d="M217.5 56.4L77.9 140.2l61.4 44.7L217.5 56.4zM64 169.6l0 150.7 59.2-107.6L64 169.6zM104.8 388L240 469.1l0-70.3L104.8 388zM272 469.1L407.2 388 272 398.8l0 70.3zM448 320.3l0-150.7-59.2 43L448 320.3zM434.1 140.2L294.5 56.4l78.2 128.4 61.4-44.7zM243.7 3.4c7.6-4.6 17.1-4.6 24.7 0l200 120c7.2 4.3 11.7 12.1 11.7 20.6l0 224c0 8.4-4.4 16.2-11.7 20.6l-200 120c-7.6 4.6-17.1 4.6-24.7 0l-200-120C36.4 384.2 32 376.4 32 368l0-224c0-8.4 4.4-16.2 11.7-20.6l200-120zM225.3 365.5L145 239.4 81.9 354l143.3 11.5zM338.9 224l-165.7 0L256 354.2 338.9 224zM256 54.8L172.5 192l167.1 0L256 54.8zm30.7 310.7L430.1 354 367 239.4 286.7 365.5z" />
    </svg>
}