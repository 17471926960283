"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineBonuses = void 0;
exports.calculatePlayer = calculatePlayer;
const nationDetails_1 = require("./nationDetails");
const race_1 = require("./race");
const roles_1 = require("./roles/roles");
function calculatePlayer(player) {
    var _a;
    const roleBonus = (0, roles_1.getRoleBonus)(player.roleId);
    const racialBonus = (0, race_1.getRaceBonus)(player.raceId);
    // Calculate national bonus using nationalLevel from national metadata
    const nationBonus = (0, nationDetails_1.calculateNationalBonus)(player.national.nationId, player.national.nationalLevel);
    const combinedBonus = (0, exports.combineBonuses)([roleBonus, racialBonus, nationBonus]);
    const calculatedPlayer = Object.assign(Object.assign({}, player), { combinedBonus: combinedBonus, nationBonus: nationBonus, roleBonus: roleBonus, racialBonus: racialBonus, actualLevel: player.baseLevel + ((_a = player.levelChange) !== null && _a !== void 0 ? _a : 0) });
    return calculatedPlayer;
}
// Helper function to combine bonuses
const combineBonuses = (bonuses) => {
    var _a;
    const combinedBonus = {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
        preferredOpponent: [],
    };
    for (const bonus of bonuses) {
        // Combine StatBlock
        for (const stat in bonus.statBlock) {
            if (combinedBonus.statBlock[stat]) {
                combinedBonus.statBlock[stat] += bonus.statBlock[stat];
            }
            else {
                combinedBonus.statBlock[stat] = bonus.statBlock[stat];
            }
        }
        // Combine positionsEnabled - any true value should make the combined value true
        combinedBonus.positionsEnabled = [...new Set([...combinedBonus.positionsEnabled, ...bonus.positionsEnabled])];
        // Combine playerTraits - ensuring uniqueness
        for (const trait of bonus.playerTraits) {
            if (!combinedBonus.playerTraits.includes(trait)) {
                combinedBonus.playerTraits.push(trait);
            }
        }
        //Combine preferred opponents
        for (const opponent of (_a = bonus.preferredOpponent) !== null && _a !== void 0 ? _a : []) {
            if (!combinedBonus.preferredOpponent.includes(opponent)) {
                combinedBonus.preferredOpponent.push(opponent);
            }
        }
    }
    return combinedBonus;
};
exports.combineBonuses = combineBonuses;
