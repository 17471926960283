// SignIn.tsx
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import React, { useEffect, useState } from 'react'; // Import useState
import { useHistory } from 'react-router-dom';
import { auth } from "../../firebase/firebase";
import { HelpTextComponent } from "../base/helpText/HelpTextComponent";
import { PageContainerComponent } from "../base/pageContainer/PageContainer";
import { PlainSection } from "../base/plainSection/PlainSection";
import { Title } from "../base/title/Title";
// --- Import your Loading component ---
import { Loading } from "../base/loading/Loading"; // Adjust path if needed
import './SignIn.css';
import StyledFirebaseAuth from './StyledFirebaseAuth';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /teams/ after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // --- Ensure this matches your desired landing page after login ---
  signInSuccessUrl: '/teams/', // Consider redirecting to '/teams/news' if that's the default now
  // We will display Google and Email as auth providers.
  signInOptions: [
    EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      GoogleAuthProvider.PROVIDER_ID,
  ],
  // Optional callbacks
//   callbacks: {
//     signInSuccessWithAuthResult: function(authResult, redirectUrl) {
//       // User successfully signed in.
//       // Return type determines whether we continue the redirect automatically
//       // or whether we leave that to developer to handle.
//       // Use this to potentially set flags or perform actions *before* redirecting.
//       return true; // True means continue with signInSuccessUrl redirect.
//     },
//     uiShown: function() {
//       // The widget is rendered, hide the loader if you were showing one specific to the widget.
//       // document.getElementById('loader').style.display = 'none';
//     }
//   },
};

export const SignIn = () => {
  const history = useHistory();
  // --- Add loading state ---
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Check auth state on mount
  useEffect(() => {
    // Start in loading state (already default, but explicit)
    setIsLoading(true);
    console.log("SignIn: Setting up auth listener...");

    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
       console.log("SignIn: Auth state changed, user:", !!user); // Log if user exists

      // This callback runs once on initial load with either the user or null.
      if (user) {
        // User is signed in.
        console.log("SignIn: User found, redirecting to /teams/ ...");
        // Redirect. Loading state remains true until redirect completes.
        history.push('/teams/'); // Or '/teams/news'
      } else {
        // User is signed out / No user initially found.
        console.log("SignIn: No user found, displaying sign-in UI.");
        // Stop loading and show the sign-in options.
        setIsLoading(false);
      }
    });

    // Cleanup function
    return () => {
        console.log("SignIn: Cleaning up auth listener.");
        unregisterAuthObserver(); // Un-register Firebase observers on unmount.
    }
    // history is stable, should only run once on mount
  }, [history]);

  // --- Conditional Rendering based on loading state ---
  if (isLoading) {
    // While checking auth state or if redirecting, show Loading
     console.log("SignIn: Rendering Loading component.");
    return <Loading />; // Render your custom loading component
  }

  // --- Render Sign-In UI once loading is false ---
  console.log("SignIn: Rendering Sign-In UI.");
  return (
    <PageContainerComponent>
      <div className='title-container'>
        <Title light={false}>FOOTBALL CARD MANAGER</Title>
      </div>
      <PlainSection>
        <h1 className='welcome-text'>Welcome</h1>
        <HelpTextComponent>Welcome to football card mananger, please sign in</HelpTextComponent>
        {/* FirebaseUI Auth Widget */}
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </PlainSection>
    </PageContainerComponent>
  )
}