import { PackInstance } from "@alienheadwars/football-card-manager-model";
import { Box, Card } from "@mui/material";
// Added useCallback
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getCallableFunction } from "../../../../firebase/firebase";
import { listenToDocument } from "../../../../firebase/firestore";
import { localise } from "../../../../locale/locale";
import { ButtonComponent } from "../../../base/button/Button";
import { Loading } from "../../../base/loading/Loading";
import { PageContainerComponent } from "../../../base/pageContainer/PageContainer";
import { HelpOverlay } from "../../../base/tutorial/helpOverlay/HelpOverlay";
import { PlayerCardComponent } from "../../../cards/playerCards/v2/PlayerCard";
import { TeamContext } from "../../TeamContext";
import { PackSummary } from "./PackSummary";

export interface PackUrlParams {
    teamId: string;
    packId: string;
}

const ANIMATION_DURATION = 500;

export const ViewPackInstance = () => {
    const teamContext = useContext(TeamContext);
    const history = useHistory();
    const packUrlParams = useParams<PackUrlParams>();

    const [pack, setPack] = useState<PackInstance | undefined>(undefined);
    const [openPackPromise, setOpenPackPromise] = useState<Promise<any> | undefined>(undefined);
    // currentIndex now tracks the index of the card *at the top* / *to be revealed next*
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    // Set of indices currently animating away
    const [animatingIndices, setAnimatingIndices] = useState<Set<number>>(new Set());
    const [showSummary, setShowSummary] = useState<boolean>(false);
    // Ref to store timeout IDs, keyed by card index
    const timeoutRefs = useRef<Map<number, number>>(new Map());

    // Calculate total items once pack is loaded (cover + assignedItems)
    const totalItemsInStack = useMemo(() => pack?.assignedItems ? pack.assignedItems.length + 1 : 0, [pack]);

    // --- Effect for fetching/listening to pack data ---
    useEffect(() => {
        let isMounted = true;
        let unsubscribe: (() => void) | undefined;

        // Capture the current value of the ref's Map when the effect runs
        const currentTimeoutMap = timeoutRefs.current; // <-- Capture the Map

        const fetchAndListen = async () => {
            const existingPack = teamContext.getUnopenedPacks()?.find(p => p.uid === packUrlParams.packId);
            if (isMounted && existingPack) {
                setPack(existingPack);
            }
            try {
                unsubscribe = listenToDocument(
                    `teams/${packUrlParams.teamId}/packs/${packUrlParams.packId}`,
                    (data) => {
                        if (isMounted) {
                            setPack(data);
                        }
                    }
                );
            } catch (error) {
                console.error("Error setting up pack listener:", error)
            }
        };
        fetchAndListen();

        // --- Cleanup Function ---
        return () => {
            isMounted = false;
            if (unsubscribe) unsubscribe();

            // Use the captured Map in the cleanup function
            console.log("Cleaning up timeouts:", currentTimeoutMap); // Optional: Log which timeouts are being cleared
            currentTimeoutMap.forEach(timeoutId => clearTimeout(timeoutId));
            // It might be safer not to clear the ref's map itself here,
            // just clear the timeouts based on the state when effect ran.
            // Clearing the ref's map directly (timeoutRefs.current.clear()) could
            // potentially interfere if another effect instance runs immediately after.
            // Just clearing the timeouts using the captured map is sufficient.
            // If you absolutely need to clear the ref map on unmount, do it here:
            // currentTimeoutMap.clear(); // Or timeoutRefs.current.clear(); - debatable which is "safer"

            // Let's stick to just clearing the timeouts based on the captured map:
            // currentTimeoutMap.clear(); // <-- Remove this line if you just want to clear timeouts

        };
        // Dependencies remain the same
    }, [teamContext, packUrlParams.teamId, packUrlParams.packId]);

    // --- Effect for calling the open pack function ---
    useEffect(() => {
        if (pack && !pack.opened && !openPackPromise) {
            const openPackFunction = getCallableFunction('v2OpenPack');
            setOpenPackPromise(openPackFunction({ packId: pack.uid }));
        }
    }, [pack, openPackPromise]);


    // --- Define items array, memoized based on pack ---
    const items = useMemo(() => {
        if (!pack?.assignedItems) return [];
        // Ensure correct key usage consistent with map below
        const coverKey = "pack-cover";
        return [
            <Card sx={{ borderRadius: '2%' }}>
                <img
                    key={coverKey}
                    src={`/images/${pack.art[0]?.image}.png`}
                    alt={pack.id}
                    style={{
                        width: 'min(95vw, 65vh)',
                        display: 'block'
                        // height: 'calc(min(95vw, 65vh) *1280/896'
                    }}
                />
            </Card>,
            ...(pack.assignedItems || []).map((player) => {
                const playerKey = player.id;
                return <PlayerCardComponent key={playerKey} player={player} />;
            }).reverse()
        ];
    }, [pack]);


    // --- Handle Card Tap ---
    const handleCardTap = useCallback(() => {
        // Ignore taps if summary is shown or pack isn't ready
        if (showSummary || totalItemsInStack === 0) return;

        const indexToAnimate = currentIndex;

        // Check if we are trying to animate past the last card
        if (indexToAnimate >= totalItemsInStack) {
            return;
        }

        // --- Trigger Animation ---
        setAnimatingIndices(prev => new Set(prev).add(indexToAnimate));

        // --- Determine Next State ---
        const nextIndex = currentIndex + 1;
        const isLastCardTap = nextIndex >= totalItemsInStack;

        // --- Show Summary Immediately on Last Tap ---
        if (isLastCardTap) {
            setShowSummary(true);
        }

        // --- Advance to Next Card Index ---
        // Still advance index even on last tap to ensure rendering state is correct
        setCurrentIndex(nextIndex);

        // --- Manage Animation Completion ---
        // Clear existing timeout for this index, if any, to prevent conflicts
        const existingTimeoutId = timeoutRefs.current.get(indexToAnimate);
        if (existingTimeoutId) {
            clearTimeout(existingTimeoutId);
        }

        // Set new timeout to remove from animating set after duration
        const newTimeoutId = setTimeout(() => {
            setAnimatingIndices(prev => {
                const nextSet = new Set(prev);
                nextSet.delete(indexToAnimate);
                return nextSet;
            });
            timeoutRefs.current.delete(indexToAnimate); // Remove from ref map
        }, ANIMATION_DURATION);

        // Store the new timeout ID
        timeoutRefs.current.set(indexToAnimate, newTimeoutId);

    }, [currentIndex, showSummary, totalItemsInStack]); // Dependencies


    // --- Loading State ---
    // Added check for totalItemsInStack > 0 to ensure items array is populated
    if (!pack || !pack.assignedItems || totalItemsInStack === 0) {
        return <Loading />;
    }

    // --- Confirm Action (unchanged) ---
    const confirmAction = async () => {
        if (openPackPromise) {
            try {
                await openPackPromise;
            } catch (error) {
                console.error("Error resolving openPackPromise:", error);
            }
        }
        history.goBack();
    };

    // Placeholder Card (unchanged)
    const placeholderCard = pack.assignedItems?.[0];


    // --- Render Logic ---
    return (
        <PageContainerComponent key={pack.uid}>
            {showSummary ? (
                // --- Summary View ---
                <>
                    <PackSummary pack={pack} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', paddingBottom: '20px' }}>
                        <ButtonComponent onClick={confirmAction} loadOnExecute>
                            {/* Using Awesome! based on previous choice */}
                            {localise('pack.confirmButton')}
                        </ButtonComponent>
                    </Box>
                </>
            ) : (
                // --- Card Stacking View ---
                <>
                    <HelpOverlay
                        helpText={localise('openPack.helpText')}
                        tutorialId="openPack.helpText"
                        blockSkip={true}
                        additionalCondition={currentIndex === 0}
                        textHeight="10vh"
                    >
                        {/* Container Box receives the tap */}
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={handleCardTap} // Tap triggers animation
                        >
                            {/* Invisible Placeholder (unchanged) */}
                            {placeholderCard && (
                                <div style={{ opacity: 0, width: 'min(95vw, 65vh)', pointerEvents: 'none' }}>
                                    {/* Use a placeholder with consistent key or structure */}
                                    <PlayerCardComponent player={placeholderCard} />
                                </div>
                            )}

                            {/* Render Absolutely Positioned Cards */}
                            {items.map((item, index) => {
                                const isAnimatingAway = animatingIndices.has(index);
                                // Card is "gone" if its index is less than the current top card's index,
                                // AND it's finished animating (not in animatingIndices).
                                const isGone = index < currentIndex && !isAnimatingAway;

                                return (
                                    <div
                                        // Use the key defined when creating the items array
                                        key={item.key}
                                        style={{
                                            display: isGone ? 'none' : 'flex', // Hide when fully gone
                                            justifyContent: 'center', // Center card content
                                            position: 'absolute',
                                            top: 0, left: 0, right: 0, margin: 'auto', // Centering
                                            width: 'min(95vw, 65vh)', // Sizing consistent with placeholder/cover
                                            pointerEvents: 'none', // Disable direct interaction on cards
                                            zIndex: items.length - index, // Maintain stacking order
                                            transform: `translateY(${isAnimatingAway ? '100vh' : '0'})`,
                                            // Apply transition consistently
                                            transition: `transform ${ANIMATION_DURATION}ms ease-in-out`,
                                        }}
                                    >
                                        {item} {/* Render the actual Card Component or Img */}
                                    </div>
                                );
                            })}
                        </Box>
                    </HelpOverlay>
                </>
            )}
        </PageContainerComponent>
    );
};