"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitBonuses = exports.PlayerTraits = void 0;
var PlayerTraits;
(function (PlayerTraits) {
    PlayerTraits["PRESS_RESISTANT"] = "PRESS_RESISTANT";
    PlayerTraits["SHIELD"] = "SHIELD";
    PlayerTraits["PLAYMAKER"] = "PLAYMAKER";
    PlayerTraits["GOAL_KEEPER"] = "GOAL_KEEPER";
    // SWEEPER = "SWEEPER", // Attempts intercept automatically if teammate failed.
    // COVERING_TACKLER = "COVERING_TACKLER", // Attempts tackle automatically if teammate failed.
    // SOUL_SAP = "SOUL_SAP", // Instead of discarding this player, choose two human teammates to discard
    // LEGEND_OF_LEGENDS = "LEGEND_OF_LEGENDS", // +3 vs non human players
    // LONG_BALL = "LONG_BALL", // Can play the ball directly from defense to attack
    // GOAL_MACHINE = "GOAL_MACHINE", // Do not discard card if scores a goal.
    // ROCKET_SHOT = "ROCKET_SHOT", // Shots gain +1d4
    PlayerTraits["CLINICAL"] = "CLINICAL";
    // FOX_IN_THE_BOX = "FOX_IN_THE_BOX", // Player gains +2 attacking against centerbacks (Consider clarifying "centerbacks")
    // POCKETS = "POCKETS", // Player gains +2 attacking against Defenders (Consider clarifying "Defenders")
    PlayerTraits["POWERFUL"] = "POWERFUL";
    PlayerTraits["RAPID"] = "RAPID";
    PlayerTraits["CHARGE"] = "CHARGE";
    // STAMINA = "STAMINA", // Subtract 2 from the player's level instead of discarding. Discard once level is less than 0.
    // FINESSE = "FINESSE", // Player can choose to use 11 for their dice score instead of rolling a d20
    // TARGET = "TARGET", // Player can be targeted from anywhere on the pitch
    PlayerTraits["TIKI_TAKA"] = "TIKI_TAKA";
    PlayerTraits["LUCKY"] = "LUCKY";
    // RELIABLE = "RELIABLE", // Player dice score is a minimum of 4 (Consider clarifying which types of rolls)
    PlayerTraits["FLAIR"] = "FLAIR";
    // ADAPTABLE = "ADAPTABLE", // 1/2 XP to level up
    // PRODIGY = "PRODIGY", // 1/4 XP to level up
    PlayerTraits["MANS_BEST_FRIEND"] = "MANS_BEST_FRIEND";
    // INTELLIGENCE = "INTELLIGENCE", // Allows choosing an action after seeing what card the opponent chooses to defend with (Consider clarifying mechanics)
    // STAR = "STAR", // +1 in attack zone of the pitch (Consider clarifying if this stacks with other bonuses)
    PlayerTraits["UNDERDOG"] = "UNDERDOG";
    PlayerTraits["DOMINANT"] = "DOMINANT";
    PlayerTraits["HERO"] = "HERO";
    PlayerTraits["DETERMINED"] = "DETERMINED";
    // ICON = "ICON", // Sets all players of same nationality to minimum hero level
    // DEFENSIVE_LEADER = "DEFENSIVE_LEADER", // Provides +1 to teammates who are defenders
    PlayerTraits["UNYIELDING"] = "UNYIELDING";
})(PlayerTraits || (exports.PlayerTraits = PlayerTraits = {}));
exports.TraitBonuses = {
    [PlayerTraits.PRESS_RESISTANT]: 4 /* BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION */,
    [PlayerTraits.SHIELD]: 4 /* BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION */,
    [PlayerTraits.PLAYMAKER]: 4 /* BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION */,
    [PlayerTraits.GOAL_KEEPER]: 0 /* BonusTypes.NO_BONUS */,
    [PlayerTraits.POWERFUL]: 2 /* BonusTypes.CONTESTABLE */,
    [PlayerTraits.RAPID]: 2 /* BonusTypes.CONTESTABLE */,
    [PlayerTraits.CHARGE]: 4 /* BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION */,
    [PlayerTraits.TIKI_TAKA]: 4 /* BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION */,
    [PlayerTraits.LUCKY]: 20,
    [PlayerTraits.FLAIR]: 2 /* BonusTypes.FIFTY_FIFTY */,
    [PlayerTraits.MANS_BEST_FRIEND]: 4 /* BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION */,
    [PlayerTraits.UNDERDOG]: 2 /* BonusTypes.CONTROLLABLE_LINEUP */,
    [PlayerTraits.DOMINANT]: 2 /* BonusTypes.CONTROLLABLE_LINEUP */,
    [PlayerTraits.HERO]: 2 /* BonusTypes.CONTROLLABLE_LINEUP */,
    [PlayerTraits.DETERMINED]: 4,
    [PlayerTraits.CLINICAL]: 4,
    [PlayerTraits.UNYIELDING]: 2,
};
