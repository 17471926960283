"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stripDownToLookupDetailsOnly = void 0;
const stripDownToLookupDetailsOnly = (lookupDetails) => {
    return {
        id: lookupDetails.id,
        collectionId: lookupDetails.collectionId
    };
};
exports.stripDownToLookupDetailsOnly = stripDownToLookupDetailsOnly;
