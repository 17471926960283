import React from "react"
import { Route, Switch } from "react-router-dom"
import { TeamNews } from "./TeamNewsList"
import { NewPackListPage } from "./newpack/NewPackListPage"
import { ViewPackInstance } from "./newpack/ViewPackInstance"


export const TeamNewsBase = () => {
    return <Switch>
        <Route exact path="/teams/:teamId/news" component={TeamNews} />
        <Route exact path="/teams/:teamId/news/packs" component={NewPackListPage} />
        <Route path="/teams/:teamId/news/packs/:packId" component={ViewPackInstance} />
    </Switch>
}