import { RoundState, TeamState, Zones } from '@alienheadwars/football-card-manager-model';
import { Box, Orientation, Stack } from '@mui/material';
import React from 'react';
import { BallComponent, BallDirection } from '../boardComponents/ball'; // Adjust paths as needed
import { CommentaryComponent } from '../commentary/RoundCommentary';
import { ROUND_FADE_TIME } from './matchConstants';
import { MatchPlayerComponent } from './matchPlayer';
import { PitchComponent } from './pitch';
import { PlayerChoiceComponent } from './playerChoice';

export interface BoardProps {
    matchId: string;
    roundState: RoundState;
    playerTeamId: string; // ID of the team controlled by the player
    teams: {
        [key: string]: TeamState;
    }
    finishRound(): void
    orientation?: Orientation
}

// Keep orientation default here for Board layout context
const defaultBoardOrientation: Orientation = 'horizontal';

const ballDirectionMap: Record<Orientation, Record<string, BallDirection>> = {
    'vertical': { true: 'up', false: 'down' },
    'horizontal': { true: 'right', false: 'left' }
};

export const BoardComponent = (props: BoardProps) => {
    const { roundState, playerTeamId } = props;
    const playerIsAttacking = roundState.attackingTeam === playerTeamId;
    // Calculate player's perspective zone
    const playerZone = playerIsAttacking ? props.roundState.zone : {
        [Zones.ATTACK]: Zones.DEFENSE,
        [Zones.MIDFIELD]: Zones.MIDFIELD,
        [Zones.DEFENSE]: Zones.ATTACK
    }[props.roundState.zone];

    const orientation = props.orientation ?? defaultBoardOrientation;


    // Effect for finishing round (unchanged)
    React.useEffect(() => {
        // ... (effect logic unchanged) ...
         if (roundState.roundFinished) {
             const timer = setTimeout(() => {
                 props.finishRound();
             }, ROUND_FADE_TIME);
             return () => clearTimeout(timer);
         }
    }, [roundState.roundId, roundState.roundFinished, props.finishRound, props]);


    // Possession component displaying players in action (unchanged structure)
    const possessionComponent = ( /* ... possessionComponent JSX unchanged ... */
        <Stack direction='row' alignItems="center" justifyContent="center" spacing={0.5} sx={{ p: 0.5 }}>
             {/* GK Hint: Added key and simplified Box */}
             {!playerIsAttacking && playerZone === Zones.DEFENSE && props.roundState.defendingHand?.[0] && (
                 <Box key="gk-def" sx={{ opacity: props.roundState.contests.length > 1 ? 1 : 0 }}>
                    <MatchPlayerComponent playerRoundState={props.roundState.defendingHand[0]} />
                 </Box>
             )}
             {/* Attacking Player */}
             {playerIsAttacking && roundState.attackingPlayer !== undefined && roundState.attackingHand?.[roundState.attackingPlayer] && (
                <MatchPlayerComponent key={roundState.attackingHand[roundState.attackingPlayer].playerDetails.id} playerRoundState={roundState.attackingHand[roundState.attackingPlayer]} />
             )}
             {/* Defending Player (if player is NOT attacking) */}
             {!playerIsAttacking && roundState.defendingPlayer !== undefined && roundState.defendingPlayer > -1 && roundState.defendingHand?.[roundState.defendingPlayer] && (
                 <MatchPlayerComponent key={roundState.defendingHand[roundState.defendingPlayer].playerDetails.id} playerRoundState={roundState.defendingHand[roundState.defendingPlayer]} />
             )}

             <BallComponent direction={ballDirectionMap[orientation][`${playerIsAttacking}`]} />

             {/* Attacking Player (if player is NOT attacking) */}
             {!playerIsAttacking && roundState.attackingPlayer !== undefined && roundState.attackingHand?.[roundState.attackingPlayer] && (
                 <MatchPlayerComponent key={roundState.attackingHand[roundState.attackingPlayer].playerDetails.id + '-right'} playerRoundState={roundState.attackingHand[roundState.attackingPlayer]} showOnRight />
             )}
             {/* Defending Player (if player IS attacking) */}
              {playerIsAttacking && roundState.defendingPlayer !== undefined && roundState.defendingPlayer > -1 && roundState.defendingHand?.[roundState.defendingPlayer] && (
                 <MatchPlayerComponent key={roundState.defendingHand[roundState.defendingPlayer].playerDetails.id + '-right'} playerRoundState={roundState.defendingHand[roundState.defendingPlayer]} showOnRight />
             )}
             {/* GK Hint: Added key and simplified Box */}
              {playerIsAttacking && playerZone === Zones.ATTACK && props.roundState.defendingHand?.[0] && (
                  <Box key="gk-atk" sx={{ opacity: props.roundState.contests.length > 1 ? 1 : 0 }}>
                    <MatchPlayerComponent playerRoundState={props.roundState.defendingHand[0]} showOnRight/>
                 </Box>
             )}
        </Stack>
    );


// --- Inside BoardComponent ---

    // --- Determine Position for Possession Component ---
    const possessionPositionStyle: React.CSSProperties = {
        position: 'absolute',
        zIndex: 2, // Ensure it's above the pitch background
        top: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 0, // Container has no size
        width: 0,
        overflow: 'visible', // Child card renders outside
    };

    // Adjust positioning based on BOTH orientation and playerZone
    if (orientation === 'vertical') {
        // Vertical Layout Positioning (Original Logic)
        possessionPositionStyle.left = '50%'; // Center horizontally

        if (playerZone === Zones.ATTACK) {
            possessionPositionStyle.top = '33%'; // Near top
            // Reset bottom if previously set (though unlikely)
             delete possessionPositionStyle.bottom;
        } else if (playerZone === Zones.MIDFIELD) {
            possessionPositionStyle.top = '50%'; // Mid
             delete possessionPositionStyle.bottom;
        } else { // Zones.DEFENSE
            possessionPositionStyle.bottom = '33%'; // Near bottom
            // Reset top if previously set
             delete possessionPositionStyle.top;
        }
    } else { // orientation === 'horizontal'
        // Horizontal Layout Positioning (New Logic)
        // Vertical centering is already set initially

        if (playerZone === Zones.ATTACK) {
             // Player attacks towards right side
             possessionPositionStyle.right = '33%';
             // Reset left if previously set
              delete possessionPositionStyle.left;
        } else if (playerZone === Zones.MIDFIELD) {
             // Mid
             possessionPositionStyle.left = '50%';
             // Reset right if previously set
              delete possessionPositionStyle.right;
        } else { // Zones.DEFENSE
             // Player defends on the left side
             possessionPositionStyle.left = '33%';
             // Reset right if previously set
              delete possessionPositionStyle.right;
        }
    }

// --- Rest of the component ---

    return (
        // Main container for the board elements
        <Stack onClick={roundState.roundFinished ? props.finishRound : undefined} sx={{ position: 'relative', alignItems: 'center' }}>

            {/* Container that defines the Pitch size and holds positioned elements */}
            <Box
                sx={{
                    position: 'relative', // Context for absolute positioning
                }}
            >
                {/* Render the Pitch Background */}
                <PitchComponent orientation={orientation} />

                {/* Render Possession Component Absolutely Positioned */}
                <Box style={possessionPositionStyle}>
                    {possessionComponent}
                </Box>

                {/* Potentially other absolutely positioned elements */}

            </Box>

            {/* Commentary and Player Choice outside the pitch container */}
            <CommentaryComponent key={`${props.roundState.roundId}-commentary`} {...props} showResult />
            <PlayerChoiceComponent key={`${props.roundState.roundId}-choice`} {...props} />
        </Stack>
    );
};

