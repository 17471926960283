"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullBackRoleBonuses = exports.FullBackExpandedRoles = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var FullBackExpandedRoles;
(function (FullBackExpandedRoles) {
    FullBackExpandedRoles["FULL_BACK"] = "FULL_BACK";
    FullBackExpandedRoles["RAPID_FULL_BACK"] = "RAPID_FULL_BACK";
    FullBackExpandedRoles["POWERFUL_FULL_BACK"] = "POWERFUL_FULL_BACK";
    FullBackExpandedRoles["WING_BACK"] = "WING_BACK";
    FullBackExpandedRoles["RAPID_WING_BACK"] = "RAPID_WING_BACK";
    FullBackExpandedRoles["TENACIOUS_FULL_BACK"] = "TENACIOUS_FULL_BACK";
    FullBackExpandedRoles["AGGRESSIVE_FULLBACK"] = "AGGRESSIVE_FULLBACK";
    FullBackExpandedRoles["ROBUST_FULLBACK"] = "ROBUST_FULLBACK";
})(FullBackExpandedRoles || (exports.FullBackExpandedRoles = FullBackExpandedRoles = {}));
exports.FullBackRoleBonuses = {
    FULL_BACK: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            tackle: 3,
            intercept: 2,
            block: 1
        },
        positionsEnabled: [zones_1.Zones.DEFENSE, zones_1.Zones.MIDFIELD],
        playerTraits: [],
    },
    RAPID_FULL_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.FULL_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_FULL_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.FULL_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    WING_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.FULL_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            pass: 2,
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    RAPID_WING_BACK: {
        parentRoles: [FullBackExpandedRoles.RAPID_FULL_BACK, FullBackExpandedRoles.WING_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    TENACIOUS_FULL_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.FULL_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.UNDERDOG],
    },
    AGGRESSIVE_FULLBACK: {
        parentRoles: [FullBackExpandedRoles.RAPID_FULL_BACK, FullBackExpandedRoles.TENACIOUS_FULL_BACK],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    ROBUST_FULLBACK: {
        parentRoles: [FullBackExpandedRoles.TENACIOUS_FULL_BACK, FullBackExpandedRoles.POWERFUL_FULL_BACK],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
};
