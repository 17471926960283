import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Options } from "../options/Options";
import { TeamContextComponent } from "./TeamContext";
import { TeamNav } from './TeamNav';
import { TeamPlayComponent } from "./play/TeamPlay";
import { TeamSnack } from './TeamSnack';
import { Container } from "@mui/material";
import { auth } from "../../firebase/firebase";
import {EditLineup} from "./myteam/lineup/EditLineup";
import { TeamNewsBase } from "./news/TeamNewsBase";

export const TeamBase = () => {

    const history = useHistory();
    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
          if (!user) {
            history.push('/')
          }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <TeamContextComponent>
            <Container maxWidth="md" sx={{paddingTop:'2vh'}}>
            <TeamSnack/>
            <Switch>
                <Route path="/teams/:teamId/news" component={TeamNewsBase} />
                <Route path="/teams/:teamId/myteam/editLineup/:lineupId" component={EditLineup} />
                <Route path="/teams/:teamId/play" component={TeamPlayComponent} />
                <Route path="/teams/:teamId/options" component={Options} />
            </Switch>
            
            </Container>
            <TeamNav/>
            
        </TeamContextComponent>
    )
}