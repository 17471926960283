import { Pack } from "@alienheadwars/football-card-manager-model"
import { Card, CardActions, CardHeader, Stack } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { localise, localiseAndReplace } from "../../../../locale/locale"
import { ButtonComponent } from "../../../base/button/Button"
import { HelpOverlay } from "../../../base/tutorial/helpOverlay/HelpOverlay"
import { TeamContext } from "../../TeamContext"

export const NewPackListPage = () => {

    const teamContext = useContext(TeamContext)
    const history = useHistory()

    useEffect(() => {
        if (teamContext.getUnopenedPacks().length === 0
        ) {
            history.goBack()
        }
    }, [teamContext, history]
    )

    return <Stack spacing={2}>{
        teamContext.getUnopenedPacks().map((pack) => {
            return <HelpOverlay tutorialId='newPack' helpText={localise('tutorial.newPack')} blockSkip={true}>
                <NewPackCard key={pack.uid} pack={pack} openFunction={() => {
                    history.push(`${teamContext.getBaseUrl()}/news/packs/${pack.uid}`)
                }} />
            </HelpOverlay>
        })
    }</Stack>
}

const NewPackCard = (props: { pack: Pack, openFunction: () => void }) => {
    return <Card>
        <CardHeader
            avatar={
                <Card sx={{borderRadius: '5%'}}>
                            <img
                                key={props.pack.id}
                                src={`/images/${props.pack.art[0]?.image}.png`}
                                alt={props.pack.id}
                                style={{
                                    width: '20vmin',
                                    display: 'block'
                                }}
                            />
                            </Card>
            }
            title={localiseAndReplace({ id: `pack.${props.pack.id}.name`, replacements: props.pack.params })}
            subheader={localiseAndReplace({ id: `pack.${props.pack.id}.description`, replacements: props.pack.params })}
        />
        <CardActions>
            <ButtonComponent
                size="small"
                onClick={props.openFunction}
            >
                Open now</ButtonComponent>
        </CardActions>

    </Card>
}

<Card sx={{borderRadius: '5%'}}></Card>