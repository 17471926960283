"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEAM_CHALLENGES = exports.REGION_CHALLENGES = exports.LEAGUE_CHALLENGES = void 0;
var LEAGUE_CHALLENGES;
(function (LEAGUE_CHALLENGES) {
    LEAGUE_CHALLENGES["X_TEAM_STARS"] = "x-team-stars";
})(LEAGUE_CHALLENGES || (exports.LEAGUE_CHALLENGES = LEAGUE_CHALLENGES = {}));
var REGION_CHALLENGES;
(function (REGION_CHALLENGES) {
    REGION_CHALLENGES["X_LEAGUE_STARS"] = "x-league-stars";
})(REGION_CHALLENGES || (exports.REGION_CHALLENGES = REGION_CHALLENGES = {}));
var TEAM_CHALLENGES;
(function (TEAM_CHALLENGES) {
    TEAM_CHALLENGES["PLAY"] = "play";
    TEAM_CHALLENGES["WIN"] = "win";
    TEAM_CHALLENGES["WIN_BY_X"] = "win-by-x";
    TEAM_CHALLENGES["WIN_SAME_LEAGUE"] = "win-same-league";
    TEAM_CHALLENGES["WIN_SAME_NATION"] = "win-same-nation";
    TEAM_CHALLENGES["WIN_SAME_LEAGUE_OR_NATION"] = "win-same-league-or-nation";
})(TEAM_CHALLENGES || (exports.TEAM_CHALLENGES = TEAM_CHALLENGES = {}));
