import { Collection, CollectionManager, SimpleCollectionManager, collectionVersion} from "@alienheadwars/football-card-manager-model";
import { getDocument } from "../firebase/firestore";


//TODO this class should really be a context component


const fetchCollection: (collectionId: string) => Promise<Collection> = async (collectionId: string) => {
    return (await getDocument(`${collectionVersion}-collections/${collectionId}`) )  as Collection
}



export const collectionManager:CollectionManager = new SimpleCollectionManager(fetchCollection)
