"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CenterBackRoleBonuses = exports.CenterBackExpandedRoles = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var CenterBackExpandedRoles;
(function (CenterBackExpandedRoles) {
    CenterBackExpandedRoles["RAPID_CENTER_BACK"] = "RAPID_CENTER_BACK";
    CenterBackExpandedRoles["POWERFUL_CENTER_BACK"] = "POWERFUL_CENTER_BACK";
    CenterBackExpandedRoles["ATHLETIC_CENTER_BACK"] = "ATHLETIC_CENTER_BACK";
    CenterBackExpandedRoles["PLAYMAKING_CENTER_BACK"] = "PLAYMAKING_CENTER_BACK";
    CenterBackExpandedRoles["ALL_ROUND_CENTER_BACK"] = "ALL_ROUND_CENTER_BACK";
    CenterBackExpandedRoles["TENACIOUS_CENTER_BACK"] = "TENACIOUS_CENTER_BACK";
    CenterBackExpandedRoles["AGGRESSIVE_CENTERBACK"] = "AGGRESSIVE_CENTERBACK";
    CenterBackExpandedRoles["ROBUST_CENTERBACK"] = "ROBUST_CENTERBACK";
    CenterBackExpandedRoles["HEROIC_CENTERBACK"] = "HEROIC_CENTERBACK";
})(CenterBackExpandedRoles || (exports.CenterBackExpandedRoles = CenterBackExpandedRoles = {}));
exports.CenterBackRoleBonuses = {
    CENTER_BACK: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            tackle: 1,
            block: 3,
            intercept: 2,
        },
        positionsEnabled: [zones_1.Zones.DEFENSE],
        playerTraits: [],
    },
    ALL_ROUND_CENTER_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.CENTER_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            tackle: 1,
            intercept: 1,
            pass: 1,
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    RAPID_CENTER_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.CENTER_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_CENTER_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.CENTER_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    ATHLETIC_CENTER_BACK: {
        parentRoles: [CenterBackExpandedRoles.RAPID_CENTER_BACK, CenterBackExpandedRoles.POWERFUL_CENTER_BACK],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    PLAYMAKING_CENTER_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.CENTER_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            pass: 2,
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    TENACIOUS_CENTER_BACK: {
        parentRoles: [baseRoles_1.BaseRoles.CENTER_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.UNDERDOG],
    },
    AGGRESSIVE_CENTERBACK: {
        parentRoles: [CenterBackExpandedRoles.RAPID_CENTER_BACK, CenterBackExpandedRoles.TENACIOUS_CENTER_BACK],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    ROBUST_CENTERBACK: {
        parentRoles: [CenterBackExpandedRoles.POWERFUL_CENTER_BACK, CenterBackExpandedRoles.TENACIOUS_CENTER_BACK],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    HEROIC_CENTERBACK: {
        parentRoles: [baseRoles_1.BaseRoles.CENTER_BACK],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.HERO],
    },
};
