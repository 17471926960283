import { BaseRoles, CalculatedPlayer, Colours, LookupDetails, StatBlock } from '@alienheadwars/football-card-manager-model';
import { Card, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { localise } from '../../../../locale/locale';
import { Icon, IconId } from '../../../base/icon/Icon';
import { Avatar, AvatarComponentProps } from '../avatarComponents/Avatar';
import { CardDimensions, HandCardDimensions, LargeCardDimensions, MediumCardDimensions, PitchAvatar, SmallCardDimensions } from './CardDimensions';
// import Avatar as MUIavatar from '@mui/material/Avatar';

//TODO move away from css files
import { getNationData, NationalLevel } from '@alienheadwars/football-card-manager-model/dist/players/nationDetails';
import '@fontsource/norwester';
import { collectionManager } from '../../../../collection/collectionManager';
import IconisedString from '../../../base/icon/IconisedString';
import { Overlay } from '../../../base/overlay/Overlay';

interface PlayerCardComponentProps {
    player: CalculatedPlayer | LookupDetails;
    size?: 'small' | 'medium' | 'hand' | 'large' | 'pitch'
    dimensions?: CardDimensions;
    onClick?(): void
    actions?: React.ReactNode
    // margin?: boolean
    selected?: boolean //todo update box shadow to 0 if selected
}

export const ExpandablePlayerCard = (props: PlayerCardComponentProps) => {

    const [viewPlayer, setViewPlayer] = React.useState(false)
    return <>{viewPlayer ? <Overlay onclick={() => setViewPlayer(false)}>
        <PlayerCardComponent {...props} size='large' />
    </Overlay> : ''}
        <PlayerCardComponent {...props} size={props.size ?? 'small'} onClick={() => {
            // Quick work around to being selected
            console.log(`${props.player.id} props.selected ${props.selected} === false  ${props.selected === false && props.onClick}`)
            if (props.selected === false && props.onClick) {
                props.onClick()
            }
            else {
                setViewPlayer(true)
            }
        }} actions={undefined} />
    </>

}

export const PlayerCardComponent = (props: PlayerCardComponentProps) => {
    const { size, dimensions: customDimensions } = props;
    // check if is calculated player, otherwise lookup from collection manager
    const [player, setPlayer] = useState(undefined as unknown as CalculatedPlayer)

    useEffect(() => {
        const fetchPlayer = async () => {
            if ((props.player as CalculatedPlayer).combinedBonus) {
                setPlayer(props.player as CalculatedPlayer);
            } else {
                setPlayer(await collectionManager.getCalculatedPlayer(props.player));
            }
        };
        fetchPlayer();
    }, [props.player]);

    // Determine the dimensions to use
    const dimensions = customDimensions ? customDimensions // 1. Use custom dimensions if provided
        : {
            small: SmallCardDimensions,
            medium: MediumCardDimensions,
            hand: HandCardDimensions,
            large: LargeCardDimensions,
            pitch: PitchAvatar
        }[size ?? 'large']// default lt to Large if no size or custom dimensions are provided

    if (!player) {
        return <></>
    }


    return (<Stack>
        <CardFrame colours={player.colours} dimensions={dimensions} onclick={props.onClick} selected={props.selected}>
            <PlayerStats player={player} dimensions={dimensions} />
            <Artwork {...player} dimensions={dimensions} />

            {dimensions.showPlayerSummary ? (
                <>
                    <div style={{ opacity: 0, paddingBottom: `calc(3 * ${dimensions.borderWidth})` }}>
                        <PlayerName name={localise(`player.${player.id}`)} colours={player.colours} dimensions={dimensions} />
                        {dimensions.showPlayerSummary && <PlayerSummary {...player} dimensions={dimensions} />}
                        {dimensions.showCharacterSheet && <Icon iconId="SWIPE_UP" fontSize="small" primaryColor={player.colours.primaryTextColour} secondaryColor={player.colours.primaryBackgroundColour} />}
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            left: '-5%',
                            top: `calc(2 * ${dimensions.borderWidth})`,
                            width: '110%',
                            overflowY: dimensions.showCharacterSheet ? 'scroll' : 'hidden',
                            overflowX: 'clip',
                            zIndex: 103,
                            scrollbarWidth: 'none',
                            /* Avatar placeholder */
                            // paddingTop: dimensions.artworkContainerHeight,
                            alignContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div style={{ 'height': `calc(${dimensions.artworkContainerHeight} - 2 * ${dimensions.borderWidth})` }} />
                        <PlayerName name={localise(`player.${player.id}`)} colours={player.colours} dimensions={dimensions} />
                        <div style={{ 'height': 0, width: '90%', overflow: 'visible' }}>
                            <CharacterSheet {...player} dimensions={dimensions} />
                        </div>
                        <div style={{ opacity: 0, paddingBottom: '' }}>
                            <PlayerSummary {...player} dimensions={dimensions} />
                            {dimensions.showCharacterSheet && <Icon iconId="SWIPE_UP" fontSize="small" primaryColor={player.colours.primaryTextColour} secondaryColor={player.colours.primaryBackgroundColour} />}
                        </div>
                    </div>
                </>
            )
                :
                <><PlayerName name={localise(`player.${player.id}`)} colours={player.colours} dimensions={dimensions} />
                </>}
        </CardFrame>
        {props.actions}
    </Stack>
    );
};

interface CharacterSheetProps extends CalculatedPlayer {
    dimensions: CardDimensions;
}

const CharacterSheet = (props: CharacterSheetProps) => {
    const { dimensions } = props;
    const sectionBreak = <div style={{
        height: '1vmin',
        width: '100%',
        backgroundColor: props.colours.primaryColour,
        borderRadius: '1vmin',
        marginTop: dimensions.playerDescriptionHeaderFontSize,
        marginBottom: dimensions.playerDescriptionHeaderFontSize,
    }} />
    const nationData = getNationData(props.national.nationId)
    return (
        <Card sx={{
            marginLeft: dimensions.characterSheetPadding,
            marginRight: dimensions.characterSheetPadding,
            backgroundColor: props.colours.primaryBackgroundColour,
            borderRadius: '0',
            paddingBottom: dimensions.characterSheetPadding,

            color: props.colours.primaryTextColour,
        }}>

            <Stack
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <PlayerSummary {...props} dimensions={dimensions} />
                {dimensions.showCharacterSheet && <>
                    {/* Swipe up icon */}
                    <Icon iconId="SWIPE_UP" fontSize="small" primaryColor={props.colours.primaryTextColour} secondaryColor={props.colours.primaryBackgroundColour} />

                    <div style={{ width: '80%', textAlign: 'left' }}>


                        {/* Nationality Section */}
                        {/* header */}
                        {sectionBreak}
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionHeaderFontSize }}>
                            <img
                                src={`/images/flags/192x144/${props.national.nationId}.png`}
                                alt={`${props.national.nationId} flag`}
                                style={{ width: dimensions.playerDescriptionHeaderFontSize, marginRight: '1vmin' }}
                            />
                            {localise(`nationalities.${props.national.nationId}`)}
                            {props.national.nationalLevel && props.national.nationalLevel !== NationalLevel.BASIC && ` ${localise(`national.level.${props.national.nationalLevel}`)} `}
                        </Typography>
                        {/* Bonuses */}

                        {/* Stat bonuses */}
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>{localise('national.bonus')}</Typography>
                        <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                            <StatBonuses statBlock={props.nationBonus.statBlock} colours={props.colours} />
                        </Typography>

                        {/* Favoured Enemy */}
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>
                            {localise('national.favoured-enemy')}</Typography>
                        {nationData.favoredOpponent.map(nation => <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                            +4 vs<img

                                src={`/images/flags/192x144/${nation}.png`}
                                alt={`${nation} flag`}
                                style={{ height: dimensions.playerDescriptionHeaderFontSize }}
                            />{localise(`nationalities.${nation}`)}
                        </Typography>
                        )}
                        {/* Hero Trait */}
                        {props.national.nationalLevel !== NationalLevel.BASIC ? <>
                            <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>
                                {localise('national.hero-trait')}</Typography>
                            <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                                <b>{localise(`playertraits.${nationData.nationalTrait}`)}</b> - {localise(`playertraits.${nationData.nationalTrait}.bonuses`)}</Typography>
                        </> : ''
                        }
                        {/* Icon */}
                        {props.national.nationalLevel === NationalLevel.ICON ? <>
                            <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>
                                {localise('national-icon')}</Typography>
                            <Typography variant="body2" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                                {localise(`playertraits.ICON.bonuses`)}</Typography>
                        </> : ''}



                        {sectionBreak}
                        {/* Role Section */}
                        <Typography variant="h6" component="span" style={{ fontSize: dimensions.playerDescriptionHeaderFontSize }}>
                            {localise(`playerroles.${props.roleId}`)}
                        </Typography>
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>{localise('playerroles.bonuses')}</Typography>
                        <Typography variant="body2" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                            {/* Display bonus stats */}
                            {props.roleBonus.statBlock &&
                                <StatBonuses statBlock={props.roleBonus.statBlock} colours={props.colours} />}
                        </Typography>
                        {/* Display traits descriptions */}
                        {props.roleBonus.playerTraits.length > 0 && <>
                            <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>{localise('playerroles.traits')}</Typography>
                            {props.roleBonus.playerTraits.map((trait) => (
                                <Typography variant="body2" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                                    <span key={trait}>
                                        <b>{localise(`playertraits.${trait}`)}</b> - {localise(`playertraits.${trait}.bonuses`)}
                                    </span>
                                </Typography>
                            ))}
                        </>
                        }
                        {sectionBreak}
                        {/* Race Section */}
                        <Typography variant="h6" component="span" style={{ fontSize: dimensions.playerDescriptionHeaderFontSize }}>
                            <img
                                src={`/images/races/${props.raceId.toLowerCase()}.png`}
                                alt={`${props.raceId}`}
                                style={{ width: dimensions.playerDescriptionHeaderFontSize, marginRight: '1vmin' }}
                            />
                            {localise(`races.${props.raceId}`)}
                        </Typography>
                        {/* Display traits descriptions */}
                        <Typography variant="h6" component="p" style={{ fontSize: dimensions.playerDescriptionBodyFontSize, opacity: 0.3 }}>{localise('races.traits')}</Typography>
                        {props.racialBonus.playerTraits && props.racialBonus.playerTraits.map((trait) => (
                            <Typography variant="body2" style={{ fontSize: dimensions.playerDescriptionBodyFontSize }}>
                                <span key={trait}>
                                    <b>{localise(`playertraits.${trait}`)}</b> - {localise(`playertraits.${trait}.bonuses`)}
                                </span>
                            </Typography>
                        ))}
                    </div>
                </>}
            </Stack>
        </Card >
    );
};

const StatBonuses = (props: { statBlock: StatBlock, colours: Colours }) => {
    return <>{props.statBlock &&
        Object.entries(props.statBlock)
            .filter(([stat, value]) => value !== undefined)
            .map(([stat, value]) => (
                <IconisedString
                    key={stat}
                    string={` +${value}_${stat.toUpperCase()}_`}
                    iconProps={{
                        fontSize: 'inherit',
                        primaryColor: props.colours.primaryTextColour,
                        secondaryColor: props.colours.primaryBackgroundColour,
                    }}
                />
            ))}</>
}


interface PlayerStatsProps {
    player: CalculatedPlayer;
    dimensions: CardDimensions;
}

const PlayerStats = (props: PlayerStatsProps) => {
    const { combinedBonus } = props.player;
    const { dimensions } = props;

    return (
        <><div
            style={{
                position: 'absolute',
                top: `calc(${dimensions.playerLevelSize} * -0.5 + 2.5 * ${dimensions.borderWidth})`,
                left: `calc(${dimensions.playerLevelSize} * -0.5 + 2.5 * ${dimensions.borderWidth})`,
                zIndex: 105,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <PlayerLevel level={props.player.actualLevel} colours={props.player.colours} dimensions={dimensions} />
            {combinedBonus.statBlock.shoot && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.shoot} iconId="SHOOT" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.pass && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.pass} iconId="PASS" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.run && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.run} iconId="RUN" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.intercept && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.intercept} iconId="INTERCEPT" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.tackle && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.tackle} iconId="TACKLE" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
            {combinedBonus.statBlock.block && props.dimensions.showPlayerStats ? (
                <PlayerStat value={combinedBonus.statBlock.block} iconId="BLOCK" colours={props.player.colours} dimensions={dimensions} />
            ) : (
                <></>
            )}
        </div>
            <div
                style={{
                    position: 'absolute',
                    top: `calc(${dimensions.playerLevelSize} * -0.5 + 2.5 * ${dimensions.borderWidth})`,
                    right: `calc(${dimensions.playerLevelSize} * -0.5 + 2.5 * ${dimensions.borderWidth})`,
                    zIndex: 105,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >

                <PlayerBaseRole baseRole={props.player.baseRole} colours={props.player.colours} dimensions={dimensions} />
            </div>
        </>
    );
};

interface PlayerStatProps {
    value: number;
    iconId: IconId;
    colours: Colours;
    dimensions: CardDimensions;
}

export const PlayerStat = (props: PlayerStatProps) => {
    const { dimensions } = props;
    return (
        <Card
            style={{
                height: dimensions.playerStatSize,
                width: dimensions.playerStatSize,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: props.colours.primaryTextColour,
                fontWeight: '400',
                fontSize: dimensions.playerStatFontSize,
                lineHeight: dimensions.playerStatLineHeight,
                backgroundColor: props.colours.primaryBackgroundColour,
                borderRadius: '50%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                margin: dimensions.playerStatMargin,
            }}
        >
            <b>+{props.value}</b>{' '}
            <Icon
                iconId={props.iconId}
                fontSize="inherit"
                primaryColor={props.colours.primaryTextColour}
                secondaryColor={props.colours.primaryBackgroundColour}
            />
        </Card>
    );
};

interface PlayerBaseRoleProps {
    baseRole: BaseRoles;
    colours: Colours;
    dimensions: CardDimensions;
}

const PlayerBaseRole = (props: PlayerBaseRoleProps) => {
    const { dimensions } = props;
    return (
        <Card
            style={{
                height: dimensions.playerLevelSize,
                width: dimensions.playerLevelSize,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: props.colours.primaryTextColour,
                fontWeight: '400',
                fontSize: dimensions.playerLevelFontSize,
                lineHeight: dimensions.playerLevelLineHeight,
                backgroundColor: props.colours.primaryBackgroundColour,
                borderRadius: '50%',
            }}
        >
            {localise(`baserole.shortHand.${props.baseRole}`)}
        </Card>
    );
};

interface PlayerLevelProps {
    level: number | string;
    colours: Colours;
    dimensions: CardDimensions;
}

export const PlayerLevel = (props: PlayerLevelProps) => {
    const { dimensions } = props;
    return (
        <Card
            style={{
                height: dimensions.playerLevelSize,
                width: dimensions.playerLevelSize,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: props.colours.primaryTextColour,
                fontWeight: '400',
                fontSize: dimensions.playerLevelFontSize,
                lineHeight: dimensions.playerLevelLineHeight,
                backgroundColor: props.colours.primaryBackgroundColour,
                borderRadius: '50%',
            }}
        >
            <b>{props.level}</b>
            <Icon
                iconId="LEVEL"
                fontSize="inherit"
                primaryColor={props.colours.primaryTextColour}
                secondaryColor={props.colours.primaryBackgroundColour}
            />
        </Card>
    );
};

const impactFonts = ['Norwester', 'sans-serif'].join(',');

interface PlayerNameProps {
    name: string;
    colours: Colours;
    dimensions: CardDimensions;
}

const PlayerName = (props: PlayerNameProps) => {
    const { dimensions } = props;
    return (
        <Card
            sx={{
                backgroundColor: props.colours.primaryColour,
                width: '110%',
                justifyItems: 'center',
                zIndex: 104,
                top: 0,
                position: 'sticky',
                padding: dimensions.playerNamePadding,
            }}
        >
            <Typography
                gutterBottom
                variant="h3"
                component="div"
                sx={{
                    color: props.colours.primaryTextColour,
                    textAlign: 'center',
                    width: '100%',
                    marginBottom: 0,
                    fontSize: dimensions.playerNameFontSize,
                    lineHeight: dimensions.playerNameLineHeight,
                    fontFamily: impactFonts,
                }}
            >
                {localise(props.name)}
            </Typography>
        </Card>
    );
    // </div>
};

interface CardFrameProps {
    children: any;
    colours: Colours;
    dimensions: CardDimensions;
    onclick?(): void;
    selected?: boolean;
}

const CardFrame = (props: CardFrameProps) => {
    const { dimensions } = props;
    return (
        <Card
            onClick={props.onclick}
            style={{
                marginTop: `calc(${dimensions.playerLevelSize} * 0.5 - 2.5 * ${dimensions.borderWidth})`,
                marginLeft: `calc(${dimensions.playerLevelSize} * 0.5 - 2.5 * ${dimensions.borderWidth})`,
                marginRight: `calc(${dimensions.playerLevelSize} * 0.5 - 2.5 * ${dimensions.borderWidth})`,
                marginBottom: `calc(${dimensions.cardWidth} *0.03)`,
            }}
            sx={{
                width: dimensions.cardWidth,
                backgroundColor: props.colours.secondaryColour,
                borderRadius: dimensions.cardBorderRadius,
                overflow: 'visible', // This will allow the child components to overflow,
                position: 'relative',
                justifyItems: 'center',
                boxShadow: props.selected ? 0 : 3,
                border: props.selected && !dimensions.showPlayerSummary ? `calc(${dimensions.borderWidth} * 2) solid ${props.colours.primaryColour}` : 'none'
                // border: dimensions.showPlayerSummary? 'none':`${dimensions.borderWidth} solid ${props.colours.primaryColour}`,
            }}
        >
            {dimensions.showPlayerSummary && <div
                style={{
                    position: 'absolute',
                    left: `calc(${dimensions.borderWidth} * 2)`,
                    top: `calc(${dimensions.borderWidth} * 2)`,
                    width: `calc(100% - (${dimensions.borderWidth} * 6))`,
                    height: `calc(100% - (${dimensions.borderWidth} * 6))`,
                    border: `${dimensions.borderWidth} solid ${props.colours.primaryColour}`,
                    borderRadius: dimensions.cardBorderRadius,
                    zIndex: 101,
                }}
            />}
            {props.children}
        </Card>
    );
};

interface ArtworkProps extends CalculatedPlayer {
    dimensions: CardDimensions;
}

const Artwork = (props: ArtworkProps) => {
    const { dimensions } = props;
    const avatarComponentsProps: AvatarComponentProps[] = [...props.avatar ?? []];
    if (avatarComponentsProps.length === 0) {
        avatarComponentsProps.push({
            type: 'image',
            componentName: 'Player 2',
        });
    }
    const avatarComponents = avatarComponentsProps.map((props) => {
        return <Avatar {...props} style={{
            position: 'absolute',
            left: '0',
            top: '0',
            borderRadius: `${dimensions.cardBorderRadius} ${dimensions.cardBorderRadius} 0vh 0vh`,
            height: dimensions.artworkContainerHeight,
            width: dimensions.artworkContainerWidth,
        }} />;
    });
    return (
        <div
            style={{
                overflow: 'hidden',
                borderRadius: `${dimensions.cardBorderRadius} ${dimensions.cardBorderRadius} 0vh 0vh`,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: dimensions.artworkContainerHeight,
                width: dimensions.artworkContainerWidth,
                pointerEvents: 'none',
            }}
        >
            {avatarComponents}
        </div>
    );
};

interface PlayerSummaryProps extends CalculatedPlayer {
    dimensions: CardDimensions;
}

const PlayerSummary = (props: PlayerSummaryProps) => {
    const { dimensions } = props;
    return (
        <Stack
            direction="row"
            style={{
                justifyContent: 'space-evenly',
                paddingLeft: dimensions.playerSummaryIconPadding,
                paddingRight: dimensions.playerSummaryIconPadding,
                alignItems: 'center'
            }}
        >
            <img
                src={`/images/flags/192x144/${props.national.nationId}.png`}
                alt={`${props.national.nationId} flag`}
                style={{ width: dimensions.raceImageWidth }}
            />
            {/* Role */}
            <Typography variant="h2" component="span"
                style={{
                    minWidth: dimensions.playerSummaryIconSize,
                    maxWidth: dimensions.playerSummaryRoleMaxWidth,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: props.colours.primaryTextColour,
                    fontWeight: '400',
                    fontSize: dimensions.playerSummaryRoleFontSize,
                    lineHeight: dimensions.playerSummaryRoleLineHeight,
                    backgroundColor: props.colours.primaryBackgroundColour,
                    borderRadius: dimensions.playerSummaryIconSize,
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    paddingTop: dimensions.playerSummaryRolePadding,
                    paddingBottom: dimensions.playerSummaryRolePadding,
                    paddingLeft: `calc(${dimensions.playerSummaryRolePadding} * 2)`,
                    paddingRight: `calc(${dimensions.playerSummaryRolePadding} * 2)`,
                    fontFamily: impactFonts,
                }}
            >
                {localise(`playerroles.${props.roleId}`)}
            </Typography>
            {/* Race */}
            <img
                src={`/images/races/${props.raceId.toLowerCase()}.png`}
                alt={`${props.raceId}`}
                style={{ width: dimensions.raceImageWidth }}
            />
        </Stack>
    );
};
