"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MatchStateError = exports.MatchStateErrors = void 0;
var MatchStateErrors;
(function (MatchStateErrors) {
    MatchStateErrors["TURN_OUT_OF_ORDER"] = "TURN_OUT_OF_ORDER";
    MatchStateErrors["REPEATED_ACTION"] = "REPEATED_ACTION";
})(MatchStateErrors || (exports.MatchStateErrors = MatchStateErrors = {}));
class MatchStateError extends Error {
    constructor(type, message) {
        super(message !== null && message !== void 0 ? message : type);
        this.name = type;
    }
}
exports.MatchStateError = MatchStateError;
