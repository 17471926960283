"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleCollectionManager = void 0;
const player_1 = require("../players/player");
class SimpleCollectionManager {
    constructor(collectionFetch) {
        this.cachedCollections = {};
        this.collectionFetch = collectionFetch;
    }
    getCollection(collectionId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.cachedCollections[collectionId]) {
                this.cachedCollections[collectionId] = this.collectionFetch(collectionId);
            }
            return this.cachedCollections[collectionId];
        });
    }
    getRegion(lookupDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            const collection = yield this.getCollection(lookupDetails.collectionId);
            const normalisedRegion = collection.regions[lookupDetails.id];
            return Object.assign(Object.assign({}, normalisedRegion), { leagues: yield Promise.all(normalisedRegion.leagues.map(league => this.getLeague(league))) });
        });
    }
    getLeague(lookupDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            const collection = yield this.getCollection(lookupDetails.collectionId);
            const normalisedLeague = collection.leagues[lookupDetails.id];
            return Object.assign(Object.assign({}, normalisedLeague), { teams: yield Promise.all(normalisedLeague.teams.map(team => this.getTeam(team))) });
        });
    }
    getTeam(lookupDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            const collection = yield this.getCollection(lookupDetails.collectionId);
            const normalisedTeam = collection.teams[lookupDetails.id];
            return Object.assign(Object.assign({}, normalisedTeam), { players: yield Promise.all(normalisedTeam.players.map(player => {
                    return this.getPlayer(player).then(templatePlayer => {
                        return Object.assign(Object.assign({}, templatePlayer), player);
                    });
                })) });
        });
    }
    getPlayer(lookupDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            //This is to preserve backwards compatability
            //Todo delete after software release and db cleardown.
            if (!lookupDetails.collectionId) {
                return lookupDetails;
            }
            const collection = yield this.getCollection(lookupDetails.collectionId);
            return collection.players[lookupDetails.id];
        });
    }
    getCalculatedPlayer(lookupDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getPlayer(lookupDetails).then(templatePlayer => (0, player_1.calculatePlayer)(Object.assign(Object.assign({}, lookupDetails), templatePlayer)));
        });
    }
}
exports.SimpleCollectionManager = SimpleCollectionManager;
