"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NationDetails = exports.DefaultNationData = exports.NationalLevel = void 0;
exports.getNationData = getNationData;
exports.calculateNationalBonus = calculateNationalBonus;
const nations_1 = require("./nations");
const playerTraits_1 = require("./playerTraits");
var NationalLevel;
(function (NationalLevel) {
    NationalLevel["BASIC"] = "BASIC";
    NationalLevel["HERO"] = "HERO";
    NationalLevel["ICON"] = "ICON";
})(NationalLevel || (exports.NationalLevel = NationalLevel = {}));
exports.DefaultNationData = {
    favoredOpponent: [nations_1.Nations.KOREA_NORTH],
    nationalTrait: playerTraits_1.PlayerTraits.POWERFUL,
    bonusStat: { block: 1 }
};
exports.NationDetails = {
    [nations_1.Nations.ARGENTINA]: {
        favoredOpponent: [nations_1.Nations.ENGLAND],
        // nationalTrait: PlayerTraits.FINESSE,
        // Will change to finesse once implemented
        nationalTrait: playerTraits_1.PlayerTraits.DETERMINED,
        bonusStat: { run: 1 }
    },
    [nations_1.Nations.BRAZIL]: {
        favoredOpponent: [nations_1.Nations.ARGENTINA],
        nationalTrait: playerTraits_1.PlayerTraits.FLAIR,
        bonusStat: { shoot: 1 }
    },
    [nations_1.Nations.ENGLAND]: {
        favoredOpponent: [nations_1.Nations.GERMANY, nations_1.Nations.WALES],
        // nationalTrait: PlayerTraits.LONG_BALL, 
        // Will change to long ball once implemented
        nationalTrait: playerTraits_1.PlayerTraits.DETERMINED,
        bonusStat: { tackle: 1 }
    },
    [nations_1.Nations.FRANCE]: {
        favoredOpponent: [nations_1.Nations.ITALY],
        nationalTrait: playerTraits_1.PlayerTraits.DOMINANT,
        bonusStat: { pass: 1 }
    },
    [nations_1.Nations.GERMANY]: {
        favoredOpponent: [nations_1.Nations.BRAZIL],
        // nationalTrait: PlayerTraits.RELIABLE,
        // Will change to reliable once implemented
        nationalTrait: playerTraits_1.PlayerTraits.DETERMINED,
        bonusStat: { intercept: 1 }
    },
    [nations_1.Nations.ITALY]: {
        favoredOpponent: [nations_1.Nations.GERMANY],
        nationalTrait: playerTraits_1.PlayerTraits.DOMINANT,
        bonusStat: { intercept: 1 }
    },
    [nations_1.Nations.PORTUGAL]: {
        favoredOpponent: [nations_1.Nations.SPAIN],
        // nationalTrait: PlayerTraits.ROCKET_SHOT,
        // Will change to rocket shot once implemented
        nationalTrait: playerTraits_1.PlayerTraits.DETERMINED,
        bonusStat: { shoot: 1 }
    },
    [nations_1.Nations.SPAIN]: {
        // Changed to array
        favoredOpponent: [nations_1.Nations.FRANCE],
        nationalTrait: playerTraits_1.PlayerTraits.TIKI_TAKA,
        bonusStat: { pass: 1 }
    },
    [nations_1.Nations.WALES]: {
        favoredOpponent: [nations_1.Nations.BELGIUM, nations_1.Nations.SCOTLAND], // Changed to array
        nationalTrait: playerTraits_1.PlayerTraits.UNDERDOG,
        bonusStat: { run: 1 }
    },
    [nations_1.Nations.SCOTLAND]: {
        favoredOpponent: [nations_1.Nations.ENGLAND], // Changed to array
        nationalTrait: playerTraits_1.PlayerTraits.UNDERDOG,
        bonusStat: { tackle: 1 }
    },
    [nations_1.Nations.IRELAND]: {
        favoredOpponent: [nations_1.Nations.ENGLAND], // Changed to array
        nationalTrait: playerTraits_1.PlayerTraits.LUCKY,
        bonusStat: { tackle: 1 }
    },
    [nations_1.Nations.BELGIUM]: {
        favoredOpponent: [nations_1.Nations.FRANCE], // Changed to array
        nationalTrait: playerTraits_1.PlayerTraits.DETERMINED,
        bonusStat: { pass: 1 }
    },
    [nations_1.Nations.NORWAY]: {
        favoredOpponent: [nations_1.Nations.BRAZIL], // Changed to array
        // nationalTrait: PlayerTraits.GOAL_MACHINE,
        // Will change to goal machine once implemented
        nationalTrait: playerTraits_1.PlayerTraits.CLINICAL,
        bonusStat: { shoot: 1 }
    },
    [nations_1.Nations.SWEDEN]: {
        favoredOpponent: [nations_1.Nations.ENGLAND], // Changed to array
        nationalTrait: playerTraits_1.PlayerTraits.CLINICAL,
        bonusStat: { shoot: 1 }
    },
};
function getNationData(nation) {
    return exports.NationDetails[nation] || exports.DefaultNationData;
}
// Updated to use NationalLevel enum
function calculateNationalBonus(nation, nationalLevel = NationalLevel.BASIC) {
    const nationData = getNationData(nation);
    const bonus = {
        statBlock: nationData.bonusStat,
        positionsEnabled: [],
        playerTraits: [],
        preferredOpponent: nationData.favoredOpponent
    };
    if (nationalLevel === NationalLevel.HERO || nationalLevel === NationalLevel.ICON) {
        bonus.playerTraits.push(nationData.nationalTrait);
    }
    // TODO once icon trait implemented
    // if (nationalLevel === NationalLevel.ICON) {
    //     bonus.playerTraits.push(PlayerTraits.ICON);
    // }
    return bonus;
}
