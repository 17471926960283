import { PlayerRoundState } from "@alienheadwars/football-card-manager-model"
import { Box } from "@mui/material"
import React from "react"
import { ExpandablePlayerCard } from "../../../../cards/playerCards/v2/PlayerCard"
import { ROUND_FADE_INCREMENT, ROUND_FADE_TIME } from "./matchConstants"

export interface MatchPlayerProps {
    playerRoundState: PlayerRoundState,
    showOnRight?: boolean
}

export const MatchPlayerComponent = (props: MatchPlayerProps) => {
    const { playerRoundState } = props
    const player = playerRoundState.playerDetails
    const [discarded, setDiscarded] = React.useState(false)
    const [opacity, setOpacity] = React.useState(1)

    React.useEffect(

        () => {
            if (!discarded && playerRoundState.discarded) {
                setDiscarded(true)
                const interval = setInterval(() => {
                    setOpacity(currentOpacity => Math.max(0, currentOpacity - (ROUND_FADE_INCREMENT / ROUND_FADE_TIME)))
                }, ROUND_FADE_INCREMENT);
                setTimeout(() => {
                    clearInterval(interval);
                }, ROUND_FADE_TIME);

            }
            if (discarded && !playerRoundState.discarded) {
                setDiscarded(false)
                setOpacity(1)
            }
        }
        , [discarded, playerRoundState.discarded, setOpacity]
    )



    return <Box sx={{ opacity: opacity }}>
        <ExpandablePlayerCard size='pitch' player={player} />
    </Box>
}
