"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roleBonuses = void 0;
exports.getBaseRole = getBaseRole;
exports.getRoleBonus = getRoleBonus;
exports.getRoleRarity = getRoleRarity;
const player_1 = require("../player");
const rarity_1 = require("../rarity");
const attackingMidfielderRoles_1 = require("./attackingMidfielderRoles");
const baseRoles_1 = require("./baseRoles");
const centralMidfielderRoles_1 = require("./centralMidfielderRoles");
const centerbackRoles_1 = require("./centerbackRoles");
const defensiveMidfielderRoles_1 = require("./defensiveMidfielderRoles");
const fullbackRoles_1 = require("./fullbackRoles");
const goalkeeperRoles_1 = require("./goalkeeperRoles");
const strikerRoles_1 = require("./strikerRoles"); // Updated import
const wideMidfielderRoles_1 = require("./wideMidfielderRoles");
const wingerRoles_1 = require("./wingerRoles");
exports.roleBonuses = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, goalkeeperRoles_1.GoalkeeperRoleBonuses), fullbackRoles_1.FullBackRoleBonuses), centerbackRoles_1.CenterBackRoleBonuses), defensiveMidfielderRoles_1.DefensiveMidfielderRoleBonuses), wideMidfielderRoles_1.WideMidfielderRoleBonuses), centralMidfielderRoles_1.CentralMidfielderRoleBonuses), attackingMidfielderRoles_1.AttackingMidfielderRoleBonuses), strikerRoles_1.StrikerRoleBonuses), wingerRoles_1.WingerRoleBonuses);
function getBaseRole(role) {
    if (Object.values(baseRoles_1.BaseRoles).includes(role)) {
        return role;
    }
    if (Object.values(goalkeeperRoles_1.GoalkeeperExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.GOAL_KEEPER;
    }
    if (Object.values(fullbackRoles_1.FullBackExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.FULL_BACK;
    }
    if (Object.values(centerbackRoles_1.CenterBackExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.CENTER_BACK;
    }
    if (Object.values(defensiveMidfielderRoles_1.DefensiveMidfielderExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER;
    }
    if (Object.values(wideMidfielderRoles_1.WideMidfielderExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.WIDE_MIDFIELDER;
    }
    if (Object.values(centralMidfielderRoles_1.CentralMidfielderExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER;
    }
    if (Object.values(attackingMidfielderRoles_1.AttackingMidfielderExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.ATTACKING_MIDFIELDER;
    }
    if (Object.values(strikerRoles_1.StrikerExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.STRIKER;
    }
    if (Object.values(wingerRoles_1.WingerExpandedRoles).includes(role)) {
        return baseRoles_1.BaseRoles.WINGER;
    }
    throw Error(`Invalid role: ${role}`);
}
function getRoleBonus(role) {
    const bonus = exports.roleBonuses[role];
    if (!bonus) {
        return { statBlock: {}, positionsEnabled: [], playerTraits: [] }; // Return a default Bonus
    }
    if (bonus.parentRoles && bonus.parentRoles.length > 0) {
        const uniqueRoles = [];
        getAllParentRoles(role).forEach(role => {
            if (!uniqueRoles.includes(role)) {
                uniqueRoles.push(role);
            }
        });
        return (0, player_1.combineBonuses)(uniqueRoles.map(uniqueRole => exports.roleBonuses[uniqueRole]));
    }
    else {
        return bonus;
    }
}
const getAllParentRoles = (role) => {
    const bonus = exports.roleBonuses[role];
    if (bonus.parentRoles && bonus.parentRoles.length > 0) {
        return [...bonus.parentRoles.flatMap(getAllParentRoles), role];
    }
    else {
        return [role];
    }
};
function getRoleRarity(role) {
    // Added null check
    if (exports.roleBonuses[role] == null) {
        return rarity_1.Rarity.COMMON;
    }
    return exports.roleBonuses[role].rarity;
}
