import { Box, Card } from "@mui/material"
import React from "react"
import NorthIcon from '@mui/icons-material/North';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SouthIcon from '@mui/icons-material/South';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

export type BallDirection = 'up' | 'down' | 'right' | 'left'
export interface BallComponentProps {
    direction: BallDirection
}
const ballSize = '3vh'
export const BallComponent = (props: BallComponentProps) => {

    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        flexDirection: props.direction==='up' || props.direction==='down' ? 'column' : 'row',
    }}>
        {props.direction === 'up' && <NorthIcon sx={{ height: ballSize, width: ballSize }} />}
        {props.direction === 'left' &&  <WestIcon sx={{ height: ballSize, width: ballSize }} />}
        <Card sx={{
            height: ballSize,
            width: ballSize,
            borderRadius: ballSize
        }}>
            <SportsSoccerIcon sx={{ height: ballSize, width: ballSize }} />
        </Card>
        {props.direction === 'down' && <SouthIcon sx={{ height: ballSize, width: ballSize }} />}
        {props.direction === 'right' && <EastIcon sx={{ height: ballSize, width: ballSize }} />}
    </Box>
}