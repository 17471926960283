// --- Lineup.tsx --- Corrected Positioning Logic
import { AssignedPlayer, LookupDetails, PlayerInstance } from "@alienheadwars/football-card-manager-model";
import { Box } from "@mui/material"; // Removed Orientation import - using string literals
import React from "react";
import { ExpandablePlayerCard } from "../../../cards/playerCards/v2/PlayerCard"; // Ensure path is correct
import { PitchComponent } from "../../play/match/boardComponents/pitch"; // Ensure path is correct

interface LineupProps {
    players: (AssignedPlayer | PlayerInstance | LookupDetails)[];
    callback?(index: number): void;
    flip?: boolean; // Vertical flip
    orientation?: 'vertical' | 'horizontal'; // Orientation

    // --- Props added for EditLineup ---
    selectedSlotIndex?: number | null;
    mismatchedFilterIndices?: Set<number>;
    allPlayersMap?: { [id: string]: PlayerInstance };
}

// --- Single position map (Vertical orientation using bottom/left) ---
const playerPositions: { [key: number]: { bottom: number, left: number } } = {
    1: { left: 50, bottom: 10 }, //GK
    2: { left: 15, bottom: 33 }, //LB
    3: { left: 85, bottom: 33 }, //RB
    4: { left: 37, bottom: 28 }, //LCB
    5: { left: 63, bottom: 28 }, //RCB
    6: { left: 20, bottom: 50 }, //LM/LCM
    7: { left: 80, bottom: 50 }, //RM/RCM
    8: { left: 50, bottom: 55 }, //CM/CAM
    9: { left: 20, bottom: 75 }, //LW
    10: { left: 80, bottom: 75 }, //RW
    11: { left: 50, bottom: 80 }, //ST
};

// --- GetPositionStyle using single map and rotation logic ---
const getPositionStyle = (
    index: number,
    orientation: 'vertical' | 'horizontal' = 'vertical',
    flip?: boolean // Vertical flip
): React.CSSProperties => {
    const pos = playerPositions[index + 1] || { bottom: 50, left: 50 }; // Default center
    const rotate = orientation === 'horizontal';

    // Calculate vertical position (bottom), applying flip if needed
    const bottomVal = flip ? 100 - pos.bottom : pos.bottom;
    // Get horizontal position (left)
    const leftVal = pos.left;

    // Apply rotation by swapping coordinate assignment
    return {
        position: 'absolute',
        left: `${rotate ? bottomVal : leftVal}%`,   // Use bottom value for CSS left if rotating
        bottom: `${rotate ? leftVal : bottomVal}%`, // Use left value for CSS bottom if rotating
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        height: 0, width: 0, overflow: 'visible'
    };
};

// --- Lineup Component (Main Logic Unchanged) ---
export const Lineup = (props: LineupProps) => {
    const {
        players,
        callback,
        flip,
        orientation = 'vertical',
        selectedSlotIndex,
        mismatchedFilterIndices,
        allPlayersMap
    } = props;

    return (
        <PitchComponent orientation={orientation}>
            {players.map((playerData, index) => {
                if (!playerData || playerData.id === 'placeholder') return null;

                // --- Resolve Player Data (Unchanged) ---
                let resolvedPlayer: PlayerInstance | LookupDetails = playerData;
                if (allPlayersMap && !('attributes' in playerData)) {
                     const instance = allPlayersMap[playerData.id];
                     if (instance) { resolvedPlayer = instance; }
                     else { console.warn(`Lineup: Player instance not found for ID: ${playerData.id}`); return null; }
                }
                // --- End Resolution ---

                // --- Calculate Style & State (Unchanged) ---
                const positionStyle = getPositionStyle(index, orientation, flip);
                const handleOnClick = callback ? () => callback(index) : undefined;
                const isSelected = selectedSlotIndex === index;
                const isMismatched = !!mismatchedFilterIndices?.has(index);
                const cardSx = isMismatched ? { filter: 'grayscale(90%)', opacity: 0.7 } : {};

                return (
                    <Box key={playerData.id || `player-${index}`} sx={{...positionStyle, ...cardSx}}>
                        <ExpandablePlayerCard
                            player={resolvedPlayer}
                            onClick={handleOnClick}
                            selected={isSelected}
                            size='pitch'
                        />
                    </Box>
                );
            })}
        </PitchComponent>
    );
};