"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOTS = void 0;
var BOTS;
(function (BOTS) {
    //Occasionaly stalls
    BOTS["DUMB_BOT"] = "DUMB_BOT";
    //Won't press in final third
    BOTS["LAZY_BOT"] = "LAZY_BOT";
    //Plays a random card
    BOTS["RANDOM_BOT"] = "RANDOM_BOT";
    //Plays a random card in the right position
    BOTS["BASIC_BOT"] = "BASIC_BOT";
    //Plays the best available card and action, but only considers level and attributes, not any modifiers
    BOTS["BASIC_POWER_BOT"] = "BASIC_POWER_BOT";
})(BOTS || (exports.BOTS = BOTS = {}));
