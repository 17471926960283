"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoalkeeperRoleBonuses = exports.GoalkeeperExpandedRoles = void 0;
const zones_1 = require("../zones");
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const baseRoles_1 = require("./baseRoles");
var GoalkeeperExpandedRoles;
(function (GoalkeeperExpandedRoles) {
    GoalkeeperExpandedRoles["BALL_PLAYING_GOALIE"] = "BALL_PLAYING_GOALIE";
    GoalkeeperExpandedRoles["SHOT_BLOCKER"] = "SHOT_BLOCKER";
    GoalkeeperExpandedRoles["COMPLETE_GOALKEEPER"] = "COMPLETE_GOALKEEPER";
    GoalkeeperExpandedRoles["ECCENTRIC_GOALKEEPER"] = "ECCENTRIC_GOALKEEPER";
})(GoalkeeperExpandedRoles || (exports.GoalkeeperExpandedRoles = GoalkeeperExpandedRoles = {}));
exports.GoalkeeperRoleBonuses = {
    GOAL_KEEPER: {
        statBlock: {
            block: 3,
        },
        positionsEnabled: [
            zones_1.Zones.DEFENSE,
        ],
        playerTraits: [playerTraits_1.PlayerTraits.GOAL_KEEPER],
        rarity: rarity_1.Rarity.COMMON,
    },
    BALL_PLAYING_GOALIE: {
        parentRoles: [baseRoles_1.BaseRoles.GOAL_KEEPER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            pass: 3,
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    SHOT_BLOCKER: {
        parentRoles: [baseRoles_1.BaseRoles.GOAL_KEEPER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            block: 2,
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    COMPLETE_GOALKEEPER: {
        parentRoles: [GoalkeeperExpandedRoles.BALL_PLAYING_GOALIE, GoalkeeperExpandedRoles.SHOT_BLOCKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    ECCENTRIC_GOALKEEPER: {
        parentRoles: [baseRoles_1.BaseRoles.GOAL_KEEPER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR],
    },
};
