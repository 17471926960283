"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CentralMidfielderRoleBonuses = exports.CentralMidfielderExpandedRoles = void 0;
// centralMidfielderRoles.ts
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var CentralMidfielderExpandedRoles;
(function (CentralMidfielderExpandedRoles) {
    CentralMidfielderExpandedRoles["BOX_TO_BOX_MIDFIELDER"] = "BOX_TO_BOX_MIDFIELDER";
    CentralMidfielderExpandedRoles["COMPLETE_MIDFIELDER"] = "COMPLETE_MIDFIELDER";
    CentralMidfielderExpandedRoles["RAPID_CENTRAL_MIDFIELDER"] = "RAPID_CENTRAL_MIDFIELDER";
    CentralMidfielderExpandedRoles["POWERFUL_CENTRAL_MIDFIELDER"] = "POWERFUL_CENTRAL_MIDFIELDER";
    CentralMidfielderExpandedRoles["ATHLETIC_CENTRAL_MIDFIELDER"] = "ATHLETIC_CENTRAL_MIDFIELDER";
    CentralMidfielderExpandedRoles["TIKI_TAKA_MIDFIELDER"] = "TIKI_TAKA_MIDFIELDER";
    CentralMidfielderExpandedRoles["PLAYMAKER"] = "PLAYMAKER";
    CentralMidfielderExpandedRoles["TENACIOUS_MIDFIELDER"] = "TENACIOUS_MIDFIELDER";
    CentralMidfielderExpandedRoles["ROBUST_MIDFIELDER"] = "ROBUST_MIDFIELDER";
    CentralMidfielderExpandedRoles["TRICKY_MIDFIELDER"] = "TRICKY_MIDFIELDER";
    CentralMidfielderExpandedRoles["DETERMINED_MIDFIELDER"] = "DETERMINED_MIDFIELDER";
    CentralMidfielderExpandedRoles["HEROIC_MIDFIEDER"] = "HEROIC_MIDFIEDER";
    CentralMidfielderExpandedRoles["MIDFIELD_TALISMAN"] = "MIDFIELD_TALISMAN";
})(CentralMidfielderExpandedRoles || (exports.CentralMidfielderExpandedRoles = CentralMidfielderExpandedRoles = {}));
exports.CentralMidfielderRoleBonuses = {
    CENTRAL_MIDFIELDER: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            pass: 3,
            intercept: 2,
            run: 1,
        },
        positionsEnabled: [zones_1.Zones.DEFENSE, zones_1.Zones.MIDFIELD, zones_1.Zones.ATTACK],
        playerTraits: [],
    },
    TIKI_TAKA_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.TIKI_TAKA],
    },
    BOX_TO_BOX_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            shoot: 2, //Total of 3
            tackle: 1,
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    COMPLETE_MIDFIELDER: {
        parentRoles: [CentralMidfielderExpandedRoles.BOX_TO_BOX_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {
            run: 1,
            tackle: 1,
            shoot: 1
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    RAPID_CENTRAL_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_CENTRAL_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    ATHLETIC_CENTRAL_MIDFIELDER: {
        parentRoles: [
            CentralMidfielderExpandedRoles.RAPID_CENTRAL_MIDFIELDER,
            CentralMidfielderExpandedRoles.POWERFUL_CENTRAL_MIDFIELDER,
        ],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    PLAYMAKER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.PLAYMAKER],
    },
    TENACIOUS_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.UNDERDOG],
    },
    ROBUST_MIDFIELDER: {
        parentRoles: [CentralMidfielderExpandedRoles.TENACIOUS_MIDFIELDER, CentralMidfielderExpandedRoles.POWERFUL_CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    TRICKY_MIDFIELDER: {
        parentRoles: [CentralMidfielderExpandedRoles.PLAYMAKER, CentralMidfielderExpandedRoles.RAPID_CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR],
    },
    DETERMINED_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DETERMINED],
    },
    HEROIC_MIDFIEDER: {
        parentRoles: [baseRoles_1.BaseRoles.CENTRAL_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.HERO],
    },
    MIDFIELD_TALISMAN: {
        parentRoles: [CentralMidfielderExpandedRoles.HEROIC_MIDFIEDER, CentralMidfielderExpandedRoles.DETERMINED_MIDFIELDER],
        rarity: rarity_1.Rarity.LEGENDARY,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
};
