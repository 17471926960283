import { useEffect, useCallback, useRef } from 'react';
import { listenToDocument } from "./firestore"; // Adjust path as needed
import { useVisibility } from "../context/VisibilityContext"; // Adjust path as needed

export const useFirestoreDocumentListener = <T>(documentPath: string, callback: (data: T) => void) => {

    const { registerVisibilityCallback } = useVisibility(); // Updated import
    const listenerUnsubscribe = useRef<(() => void) | null>(null);

    const setupDocumentListener = useCallback(() => {
        console.log(`Setting up Firestore listener for document: ${documentPath}`);
        listenerUnsubscribe.current = listenToDocument(documentPath, callback);
        return listenerUnsubscribe.current; // Return unsubscribe function for VisibilityContext to manage
    }, [documentPath, callback]); // Dependencies: documentPath and callback

    useEffect(() => {
        registerVisibilityCallback(setupDocumentListener); // Register listener setup with VisibilityContext

        return () => {
            console.log(`Unmounting or visibility change. Unsubscribing Firestore listener for: ${documentPath}`);
            if (listenerUnsubscribe.current) {
                listenerUnsubscribe.current();
                listenerUnsubscribe.current = null;
            }
        };
        // Dependencies: registerListenerSetup -  setupDocumentListener is already wrapped in useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerVisibilityCallback, setupDocumentListener]); // Only dependency is registerListenerSetup and setupDocumentListener
};