import { TraitBonuses } from "@alienheadwars/football-card-manager-model";

export const enTraits: { [key: string]: string } = {
    'playertraits.PRESS_RESISTANT': 'Press Resistant',

    'playertraits.DIRECT': 'Direct',
    'playertraits.GOAL_KEEPER': 'Goal Keeper',
    'playertraits.SWEEPER': 'Sweeper',
    'playertraits.LUCKY': 'Lucky',
    'playertraits.RELIABLE': 'Reliable',
    'playertraits.FLAIR': 'Flair',
    'playertraits.ADAPTABLE': 'Adaptable',
    'playertraits.PRODIGY': 'Prodigy',
    'playertraits.MANS_BEST_FRIEND': "Man's Best Friend",
    'playertraits.INTELLIGENCE': 'Intelligence',
    'playertraits.STAR': 'Star',
    'playertraits.UNDERDOG': 'Underdog',
    'playertraits.DOMINANT': 'Dominant',
    'playertraits.HERO': 'Hero',
    'playertraits.DETERMINED': 'Determined',
    'playertraits.COVERING_TACKLER': 'Covering Tackler',
    'playertraits.SOUL_SAP': 'Soul Sap',
    'playertraits.LEGEND_OF_LEGENDS': 'Legend of Legends',
    'playertraits.LONG_BALL': 'Long Ball',
    'playertraits.GOAL_MACHINE': 'Goal Machine',
    'playertraits.ROCKET_SHOT': 'Rocket Shot',
    'playertraits.CLINICAL': 'Clinical',
    'playertraits.FOX_IN_THE_BOX': 'Fox in the Box',
    'playertraits.POCKETS': 'Pockets',
    'playertraits.STRONG': 'Strong',
    'playertraits.PACEY': 'Pacey',
    'playertraits.CHARGE': 'Charge',
    'playertraits.STAMINA': 'Stamina',
    'playertraits.FINESSE': 'Finesse',
    'playertraits.TARGET': 'Target',
    'playertraits.TIKI_TAKA': 'Tiki Taka',
    'playertraits.ICON': 'Icon',
    'playertraits.DEFENSIVE_LEADER': 'Defensive Leader',
    'playertraits.SHIELD': 'Shield',
    'playertraits.POWERFUL': 'Powerful',
    'playertraits.RAPID': 'Rapid',
    'playertraits.PLAYMAKER': 'Playmaker',
    'playertraits.UNYIELDING': 'Unyielding',


'playertraits.PRESS_RESISTANT.bonuses': `+${TraitBonuses.PRESS_RESISTANT} if won the ball in the last round in either defense or midfield.`, //BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION,
'playertraits.SHIELD.bonuses': `+${TraitBonuses.SHIELD} when preventing the opponent from moving into attack`, //BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION,
'playertraits.PLAYMAKER.bonuses': `+${TraitBonuses.PLAYMAKER} passing or running into the attacking zone`, // changed from DIRECT //BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION,
'playertraits.GOAL_KEEPER.bonuses': 'Attempts block automatically if teammate failed. Card does not get discarded on succesfully/failed challenge but instead loses a level. Card can not be played manually.', // BonusTypes.NO_BONUS,
// 'playertraits.SWEEPER.bonuses': 'Attempts intercept automatically if teammate failed.',
// 'playertraits.COVERING_TACKLER.bonuses': 'Attempts tackle automatically if teammate failed.',
// 'playertraits.SOUL_SAP.bonuses': 'Instead of discarding this player, choose two human teammates to discard',
// 'playertraits.LEGEND_OF_LEGENDS.bonuses': '+3 vs non human players',
// 'playertraits.LONG_BALL.bonuses': 'Can play the ball directly from defense to attack',
// 'playertraits.GOAL_MACHINE.bonuses': 'Do not discard card if scores a goal.',
// 'playertraits.ROCKET_SHOT.bonuses': 'Shots gain +1d4',
'playertraits.CLINICAL.bonuses': `Player gains +${TraitBonuses.CLINICAL} versus goalies`,
// 'playertraits.FOX_IN_THE_BOX.bonuses': 'Player gains +2 attacking against centerbacks',
// 'playertraits.POCKETS.bonuses': 'Player gains +2 attacking against Defenders',
'playertraits.POWERFUL.bonuses': `Player gains +${TraitBonuses.POWERFUL} attacking against players who aren't powerful, and +4 defending against players who are.`, // BonusTypes.CONTESTABLE,
'playertraits.RAPID.bonuses': `Player gains +${TraitBonuses.RAPID} against players who aren't rapid, and +4 defending against players who are.`, //BonusTypes.CONTESTABLE,
'playertraits.CHARGE.bonuses': `Gain +${TraitBonuses.CHARGE} to actions after a successful run.`,  //BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION,
// 'playertraits.STAMINA.bonuses': 'Subtract 2 from the player\'s level instead of discarding. Discard once level is less than 0.',
// 'playertraits.FINESSE.bonuses': 'Player can choose to use 11 for their dice score instead of rolling a d20',
// 'playertraits.TARGET.bonuses': 'Player can be targeted from anywhere on the pitch',
'playertraits.TIKI_TAKA.bonuses': `Player receives +${TraitBonuses.TIKI_TAKA} in contests if they were passed the ball in the last round`, // BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION, // simplified description
'playertraits.LUCKY.bonuses': `Gain +${TraitBonuses.LUCKY} on a roll of 1`,  // Kept your original, more explicit wording
// 'playertraits.RELIABLE.bonuses': 'Minimum dice roll of 4',
'playertraits.FLAIR.bonuses': `+${TraitBonuses.FLAIR} to score if dice roll is odd`, // BonusTypes.FIFTY_FIFTY,
// 'playertraits.ADAPTABLE.bonuses': '1/2 XP to level up',
// 'playertraits.PRODIGY.bonuses': '1/4 XP to level up',
'playertraits.MANS_BEST_FRIEND.bonuses': `Receives +${TraitBonuses.MANS_BEST_FRIEND} if it received the ball from a human in the last round`, // BonusTypes.CONTROLLABLE_LOCATION_AND_OR_ACTION,
// 'playertraits.INTELLIGENCE.bonuses': 'Choose an action after seeing opponent\'s defensive card',
// 'playertraits.STAR.bonuses': '+1 in attack zone',
'playertraits.UNDERDOG.bonuses': `+${TraitBonuses.UNDERDOG} against opponents with a higher level`, // BonusTypes.CONTROLLABLE_LINEUP,
'playertraits.DOMINANT.bonuses': `+${TraitBonuses.DOMINANT} against opponents with a lower level`,  // BonusTypes.CONTROLLABLE_LINEUP,
'playertraits.HERO.bonuses': `+${TraitBonuses.HERO} if highest rated player in squad`, // BonusTypes.CONTROLLABLE_LINEUP,
'playertraits.DETERMINED.bonuses': `+${TraitBonuses.DETERMINED} if team is losing`,
// 'playertraits.ICON.bonuses': 'Lifts all players of the same nationality to national hero level',
// 'playertraits.DEFENSIVE_LEADER.bonuses': '+1 to defender teammates when defending',
'playertraits.UNYIELDING.bonuses': `+${TraitBonuses.UNYIELDING} If team has not conceded a goal.`,




}