// src/components/team/lineup/EditLineup.tsx
import { BaseRoles, Lineup as LineupModel, LookupDetails, PlayerInstance, TeamConfig } from '@alienheadwars/football-card-manager-model'; // Use LineupModel alias
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, Drawer, Grid, IconButton, InputAdornment, Button as MuiButton, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { localise } from '../../../../locale/locale';
import { HelpTextComponent } from '../../../base/helpText/HelpTextComponent';
import { Loading } from '../../../base/loading/Loading';
// Card component assumes it can handle PlayerInstance
import { ExpandablePlayerCard } from '../../../cards/playerCards/v2/PlayerCard';
// --- Import the standard Lineup component ---
import { ChoiceButtonsComponent } from '../../../base/button/ChoiceButtons';
import { TeamContext } from '../../TeamContext';
import { Lineup } from "./Lineup"; // Adjust path as needed

// --- Constants and Types ---

export interface LineupUrlParams {
    teamId: string;
    lineupId: string;
}

interface SwapPlayerDetail {
    searchTokens: string[];
    player: PlayerInstance;
}

// --- Sub-Components ---

// #region Sub-Components

// --- ReplacementPlayerList (Includes Cancel Button) ---
interface ReplacementPlayerListProps {
    players: SwapPlayerDetail[];
    selectedSlotIndex: number | null;
    currentLineupMap: { [playerId: string]: number };
    onSelectReplacement: (playerToSwapIn: PlayerInstance) => void;
    onCancelSelection: () => void; // Callback to deselect slot
}
const ReplacementPlayerList: React.FC<ReplacementPlayerListProps> = React.memo(({
    players, selectedSlotIndex, currentLineupMap, onSelectReplacement, onCancelSelection
}) => {
    // Show prompt if no slot is selected
    if (selectedSlotIndex === null) {
        return (
            <Paper sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1vmin' }}>
                <HelpTextComponent>{localise('lineup.selectPitchPlayerPrompt')}</HelpTextComponent>
            </Paper>
        );
    }
    // Show list or 'No Players Found' if a slot IS selected
    return (
        <Paper sx={{ flexGrow: 1, overflowY: 'auto', padding: '1vmin' }}>
            {players.length > 0 ? (
                <Grid container spacing={1} justifyContent="center">
                    {players.map((swapPlayerDetail) => {
                        const playerInstance = swapPlayerDetail.player;
                        const existingIndex = currentLineupMap[playerInstance.id];
                        const isAlreadyInLineup = existingIndex !== undefined && existingIndex !== selectedSlotIndex;
                        const isPlayerBeingReplaced = existingIndex === selectedSlotIndex;
                        const isSwapAction = isAlreadyInLineup;
                        const buttonTextKey = isSwapAction ? 'lineup.swap' : 'lineup.select';

                        return (
                             // Apply greyscale to Grid item
                            <Grid item key={playerInstance.id} sx={isAlreadyInLineup ? { filter: 'grayscale(80%)', opacity: 0.8 } : {}}>
                                <ExpandablePlayerCard
                                    player={playerInstance}
                                    size="small"
                                    actions={ // Pass actions directly
                                        <ChoiceButtonsComponent
                                        primaryButtonText={buttonTextKey} // Dynamic Button Text
                                        primaryButtonAction={() =>  onSelectReplacement(playerInstance)}
                                        primaryButtonDisabled={isPlayerBeingReplaced} // Disable selecting the same player
                                        secondardaryButtonText={'button.cancel'} // Use key
                                        secondaryButtonAction={onCancelSelection} // Does nothing in this context
                                    />
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <HelpTextComponent>{localise('lineup.noPlayersFound')}</HelpTextComponent>
                </Box>
            )}
        </Paper>
    );
});


// --- FilterControls ---
interface FilterControlsProps {
    searchText: string;
    filters: { [key: string]: string };
    filterDrawerContent: ReactElement | null;
    isFilterDrawerOpen: boolean;
    onSearchChange: (text: string) => void;
    onFiltersChange: (filters: { [key: string]: string }) => void;
    onFilterDrawerOpen: () => void;
    onFilterDrawerClose: () => void;
}
const FilterControls: React.FC<FilterControlsProps> = React.memo(({
    searchText,
    filters,
    filterDrawerContent,
    isFilterDrawerOpen,
    onSearchChange,
    onFiltersChange,
    onFilterDrawerOpen,
    onFilterDrawerClose
}) => {
    // Workaround for blur closing drawer too quickly
    const [leftSearch, setLeftSearch] = useState<boolean>(false);
    useEffect(() => {
        if (leftSearch) {
            const timer = setTimeout(onFilterDrawerClose, 150);
            setLeftSearch(false);
            return () => clearTimeout(timer);
        }
    }, [leftSearch, onFilterDrawerClose]);

    return (
        <>
            <Card sx={{ flexShrink: 0, display: 'flex', alignItems: 'center', p: 0.5 }}>
                <TextField id="Search" sx={{ flexGrow: 1 }} variant="outlined" size="small"
                    label={<Box sx={{ display: 'flex', alignItems: 'flex-end' }}><SearchIcon sx={{ color: 'action.active', mr: 0.5 }} /> {localise('search.label')}</Box>}
                    value={searchText}
                    onChange={(event) => onSearchChange(event.target.value)}
                    onBlur={() => setLeftSearch(true)} // Trigger delayed close
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {Object.entries(filters).map(([key, value]) => (
                                    <MuiButton
                                        key={key}
                                        variant="contained" size="small"
                                        endIcon={<CloseIcon />}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent blur
                                            const newFilters = { ...filters };
                                            delete newFilters[key];
                                            onFiltersChange(newFilters);
                                        }}
                                        sx={{ margin: '0.5vmin', textTransform: 'none' }}
                                    >
                                        {localise(value) || value} {/* Assuming value might be a loc key */}
                                    </MuiButton>
                                ))}
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton onClick={onFilterDrawerOpen} sx={{ ml: 1 }}>
                    <FilterListIcon />
                </IconButton>
            </Card>

            <Drawer anchor="right" open={isFilterDrawerOpen} onClose={onFilterDrawerClose}>
                <Box sx={{ width: 250, overflowY: 'auto' }} role="presentation">
                    {filterDrawerContent}
                </Box>
            </Drawer>
        </>
    );
});


// #endregion Sub-Components


// --- Main Component ---

export const EditLineup = () => {
    const { lineupId } = useParams<LineupUrlParams>(); // Should be 'default'
    const location = useLocation<{ playerIndex?: number }>();
    const teamContext = useContext(TeamContext);

    // --- State ---
    const [lineupSlotIndex, setLineupSlotIndex] = useState<number | null>(null);
    const [editedLineup, setEditedLineup] = useState<LineupModel | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [filters, setFilters] = useState<{ [key: string]: string }>({});
    const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInitialized, setIsInitialized] = useState(false); // Prevent effect loops
    const [allPlayers, setAllPlayers] = useState<PlayerInstance[]>([]);
    const [defaultCreationAttempted, setDefaultCreationAttempted] = useState(false);


    // --- Effects (Handles Initialization and Default Lineup Creation) ---
    useEffect(() => {
        if (isInitialized) return; // Prevent re-running after completion/failure

        const team = teamContext.getTeam();
        const config = teamContext.getTeamConfig(); // May be null initially
        const players = team?.players;

        // Guard: Wait for player data (needed for default creation)
        if (!team || !players) {
            console.log("EditLineup Effect: Waiting for team/player data...");
            // Let context updates trigger re-render
            return;
        }

        // If config is null/undefined OR the specific lineup is missing/incomplete
        const currentLineup = config?.lineups?.[lineupId];
        const lineupExistsAndComplete = currentLineup?.startingLineup?.length === 11;

        if (lineupExistsAndComplete) {
            // Lineup exists and is complete - Load it and finish loading state
            console.log("Effect: Lineup found and complete, setting state.");
            // Only update state if it's different to avoid potential loops
            // Although structuredClone should create a new ref anyway
            setEditedLineup(structuredClone(currentLineup));
            const indexFromState = location.state?.playerIndex;
            setLineupSlotIndex(indexFromState !== undefined ? indexFromState : null);
            setAllPlayers(players);
            setIsLoading(false); // *** Set loading false HERE ***
            setIsInitialized(true)
        } else {
            // Lineup missing or incomplete
            if (players.length >= 11) {
                // Only attempt creation ONCE
                if (!defaultCreationAttempted) {
                     console.log("Effect: Default lineup missing/invalid, attempting creation...");
                     setDefaultCreationAttempted(true); // Mark attempt
                     teamContext.updateTeamConfig((existingConfig): TeamConfig => {
                         const newConfig = structuredClone(existingConfig ?? { lineups: {} });
                         if (!newConfig.lineups) newConfig.lineups = {};
                         // Ensure lineup object exists
                         if (!newConfig.lineups[lineupId]) {
                            newConfig.lineups[lineupId] = { startingLineup: [] };
                         }
                         // Only overwrite if needed
                         if (newConfig.lineups[lineupId].startingLineup?.length !== 11) {
                             console.log("Effect: Applying default lineup in updateTeamConfig");
                             newConfig.lineups[lineupId].startingLineup = players.slice(0, 11).map(p => ({ id: p.id, collectionId: p.collectionId }));
                         }
                         return newConfig;
                     });
                     // Don't set loading false here, wait for the updateTeamConfig
                     // to trigger a re-render, which will run this effect again.
                     // The next run should find lineupExistsAndComplete = true.
                } else {
                    // Creation was attempted, but lineup is STILL not complete.
                    // This might indicate an issue with updateTeamConfig or context propagation.
                    // We should stop loading and potentially show an error.
                    console.error("Effect: Default lineup creation attempted but lineup still invalid.");
                    setIsLoading(false); // Stop loading to show error state below
                }
            } else {
                // Cannot create default lineup
                console.warn("Effect: Cannot create default lineup, not enough players.");
                setIsLoading(false); // Stop loading, lineup remains null
                 // setDefaultCreationAttempted(true); // Mark attempt as done (failed)
            }
        }

    // Watch relevant parts of the context and location state
    }, [
        teamContext.getTeam, // Watch the getter functions if context value itself rarely changes
        teamContext.getTeamConfig,
        lineupId,
        isInitialized,
        location.state,
        defaultCreationAttempted, // Re-run if attempt flag changes
        teamContext // Include context itself as a fallback dependency
    ]);

    // --- Memoized Data Processing ---

     // Map of all players for easy lookup (used by Lineup component)
     const allPlayersMap = useMemo(() => {
        const map: { [id: string]: PlayerInstance } = {};
        allPlayers.forEach(p => { if(p?.id) map[p.id] = p });
        return map;
     }, [allPlayers]);

     // Prepare details for replacement list
    const distinctPlayersDetails = useMemo((): SwapPlayerDetail[] => {
        const playerMap: { [playerId: string]: SwapPlayerDetail } = {};
        allPlayers.forEach((player) => {
            if (player?.id && !playerMap[player.id]) {
                playerMap[player.id] = { searchTokens: [JSON.stringify(player).toLowerCase()], player: player };
            }
        });
        return Object.values(playerMap).sort((b, a) => a.player.baseLevel - b.player.baseLevel);
    }, [allPlayers]);

    // Map of player IDs -> index in the current edited lineup
    const currentEditedLineupMap = useMemo(() => {
        const map: { [playerId: string]: number } = {};
        editedLineup?.startingLineup?.forEach((lookup, index) => { if (lookup.id !== 'placeholder') { map[lookup.id] = index; } });
        return map;
    }, [editedLineup]);

    // Helper to check filters against a PlayerInstance
    const playerInstanceMatchesFilters = useCallback((player: PlayerInstance | undefined): boolean => {
        if (!player) return true; // Assume matches if no data? Or false?
        // Check against active filters
        return Object.keys(filters).every(filterKey =>
            String(player[filterKey as keyof PlayerInstance]) === String(filters[filterKey])
        );
    }, [filters]);

    // Indices of players on the pitch that DON'T match filters
    const mismatchedFilterIndicesOnPitch = useMemo((): Set<number> => {
        const mismatched = new Set<number>();
        // Only apply if filters are active
        if (!editedLineup || Object.keys(filters).length === 0) {
            return mismatched;
        }

        editedLineup.startingLineup.forEach((lookup, index) => {
             if (!lookup || lookup.id === 'placeholder') return; // Skip placeholders

             const playerInstance = allPlayersMap[lookup.id]; // Find full player data

             // Add index to set if the player instance doesn't match filters
             if (!playerInstanceMatchesFilters(playerInstance)) {
                  mismatched.add(index);
             }
        });
        return mismatched;
    }, [editedLineup, allPlayersMap, filters, playerInstanceMatchesFilters]); // Added filters dependency


    // Filter options and content for the drawer
    const filterOptions = useMemo(() => {
        const nationsMap: { [nationId: string]: ReactElement } = {};
        const leaguesMap: { [leagueKey: string]: ReactElement } = {};
        distinctPlayersDetails.forEach(({ player }) => {
            const nationId = player.national?.nationId;
            if (nationId && !nationsMap[nationId]) {
                nationsMap[nationId] = (
                    <Grid item xs={4} sm={3} md={2} key={`nation-${nationId}`} onClick={() => { setFilters(f => ({ ...f, nationId: nationId })); setFilterDrawerOpen(false); }} sx={{ cursor: 'pointer', padding: 0.5, '&:hover': { opacity: 0.8 } }}>
                        <img src={`/images/flags/192x144/${nationId}.png`} alt={nationId} style={{ width: '100%', display: 'block', border: '1px solid #ccc' }} />
                    </Grid>
                );
            }
            const leagueId = player.leagueId;
            if (leagueId && !leaguesMap[String(leagueId)]) {
                const leagueKey = String(leagueId);
                leaguesMap[leagueKey] = (
                    <Grid item xs={4} sm={3} md={2} key={`league-${leagueKey}`} onClick={() => { setFilters(f => ({ ...f, leagueId: leagueKey })); setFilterDrawerOpen(false); }} sx={{ cursor: 'pointer', padding: 0.5, '&:hover': { opacity: 0.8 } }}>
                        <img src={`/images/badges/${leagueKey}.png`} alt={leagueKey} style={{ width: '100%', display: 'block', objectFit: 'contain', border: '1px solid #ccc' }} />
                    </Grid>
                );
            }
        });
        return { nations: Object.values(nationsMap), leagues: Object.values(leaguesMap) };
    }, [distinctPlayersDetails]);

    const filterDrawerContent = useMemo(() => {
        const recommendedElements: ReactElement[] = [];
        // Add recommendation logic here if needed
        const hasLeagueOptions = filterOptions.leagues.length > 0;
        const hasNationOptions = filterOptions.nations.length > 0;
        return (
            <Stack spacing={2} sx={{ padding: 2 }}>
                {recommendedElements.length > 0 && (<> <Typography variant="overline">Recommended</Typography> <Grid container spacing={1}>{recommendedElements}</Grid> </> )}
                {hasLeagueOptions ? (<> <Typography variant="overline">Leagues</Typography> <Grid container spacing={1}>{filterOptions.leagues}</Grid> </>) : (<Typography variant="caption" color="textSecondary">No league filters.</Typography>)}
                {hasNationOptions ? (<> <Typography variant="overline">Nations</Typography> <Grid container spacing={1}>{filterOptions.nations}</Grid> </>) : (<Typography variant="caption" color="textSecondary">No nation filters.</Typography>)}
                {Object.keys(filters).length > 0 && (<MuiButton onClick={() => setFilters({})} variant='outlined' size='small' sx={{mt: 2}}>Clear Filters</MuiButton>)}
            </Stack>
        );
    }, [filterOptions, filters]); // Added filters dependency for clear button

    // Filtered list of players for the replacement list
    const availablePlayersForList = useMemo(() => {
        if (lineupSlotIndex === null) return [];
        const lowerSearchText = searchText.toLowerCase();
        const isGkSlot = lineupSlotIndex === 0;
        return distinctPlayersDetails.filter(({ player, searchTokens }) => {
            const roleMatch = isGkSlot ? player.baseRole === BaseRoles.GOAL_KEEPER : player.baseRole !== BaseRoles.GOAL_KEEPER;
            if (!roleMatch) return false;
            const filterMatch = Object.keys(filters).every(key => String(player[key as keyof PlayerInstance]) === String(filters[key]));
            if (!filterMatch) return false;
            const searchMatch = !lowerSearchText || searchTokens.some(token => token.includes(lowerSearchText));
            return searchMatch;
        });
    }, [distinctPlayersDetails, lineupSlotIndex, searchText, filters]);


    // --- Interaction Handlers ---
    const handlePitchPlayerClick = useCallback((index: number) => {
        setLineupSlotIndex(prevIndex => (prevIndex === index ? null : index));
    }, []);

    const handleSelectReplacement = useCallback((playerToSwapIn: PlayerInstance) => {
        if (lineupSlotIndex === null || !editedLineup) return;
        let finalLineup: LineupModel | null = null;
        const currentStartingLineup = editedLineup.startingLineup;
        const updatedLineupArray = [...currentStartingLineup];
        const replacementLookup: LookupDetails = { id: playerToSwapIn.id, collectionId: playerToSwapIn.collectionId || 'unknown'};
        const existingReplacementIndex = currentEditedLineupMap[playerToSwapIn.id];
        const isActualSwap = existingReplacementIndex !== undefined && existingReplacementIndex !== lineupSlotIndex;

        if (isActualSwap) {
            const originalPlayerLookup = currentStartingLineup[lineupSlotIndex];
            updatedLineupArray[lineupSlotIndex] = replacementLookup;
            if (originalPlayerLookup && originalPlayerLookup.id !== 'placeholder') {
                updatedLineupArray[existingReplacementIndex] = originalPlayerLookup;
            } else {
                updatedLineupArray[existingReplacementIndex] = { id: 'placeholder', collectionId: 'placeholder' };
            }
            finalLineup = { ...editedLineup, startingLineup: updatedLineupArray };
        } else {
            updatedLineupArray[lineupSlotIndex] = replacementLookup;
            finalLineup = { ...editedLineup, startingLineup: updatedLineupArray };
        }

        setEditedLineup(finalLineup); // Update local state

        // Auto-save
        if (finalLineup && !finalLineup.startingLineup.some(p => p.id === 'placeholder')) {
             console.log("Auto-saving lineup:", finalLineup);
             teamContext.updateTeamConfig((existingConfig = { lineups: {} }): TeamConfig => {
                 const newConfig = structuredClone(existingConfig);
                 if (!newConfig.lineups) newConfig.lineups = {};
                 newConfig.lineups[lineupId] = finalLineup!;
                 return newConfig;
             });
        } else { console.warn("Auto-save skipped: Lineup incomplete."); }

    }, [editedLineup, lineupSlotIndex, lineupId, teamContext, currentEditedLineupMap]);

    // Handler for Cancel button in ReplacementPlayerList
    const handleCancelSelection = useCallback(() => {
        //Do nothing at the moment
    }, []);


    // --- Render ---
    if (isLoading) {
        return <Loading />;
    }
    if (!editedLineup) {
         return <Box sx={{p: 2}}> <Typography color="error">Lineup '{lineupId}' could not be loaded or created. Check player count or context data.</Typography> </Box>;
    }

    return (
        <Box sx={{ height: '90vh', /* Adjust based on header/nav height */ display: 'flex', flexDirection: 'column', overflow: 'hidden', alignItems: 'center' }}>
            {/* Top Section: Pitch */}
            <Box sx={{ flexShrink: 0, p: 1 }}>
                 <Lineup
                    players={editedLineup.startingLineup}
                    orientation="horizontal"
                    callback={handlePitchPlayerClick}
                    selectedSlotIndex={lineupSlotIndex}
                    mismatchedFilterIndices={mismatchedFilterIndicesOnPitch} // Corrected logic applied here
                    allPlayersMap={allPlayersMap}
                 />
            </Box>
            {/* Bottom Section: Controls and Player List */}
            <Stack sx={{ flexGrow: 1, overflow: 'hidden', padding: 1, pt: 0 }} spacing={1}>
                <FilterControls
                    searchText={searchText}
                    filters={filters}
                    filterDrawerContent={filterDrawerContent}
                    isFilterDrawerOpen={isFilterDrawerOpen}
                    onSearchChange={setSearchText}
                    onFiltersChange={setFilters}
                    onFilterDrawerOpen={() => setFilterDrawerOpen(true)}
                    onFilterDrawerClose={() => setFilterDrawerOpen(false)}
                />
                <ReplacementPlayerList
                    players={availablePlayersForList}
                    selectedSlotIndex={lineupSlotIndex}
                    currentLineupMap={currentEditedLineupMap}
                    onSelectReplacement={handleSelectReplacement}
                    onCancelSelection={handleCancelSelection} // Pass cancel handler
                />
            </Stack>
        </Box>
    );
};