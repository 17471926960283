"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefensiveMidfielderRoleBonuses = exports.DefensiveMidfielderExpandedRoles = void 0;
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var DefensiveMidfielderExpandedRoles;
(function (DefensiveMidfielderExpandedRoles) {
    DefensiveMidfielderExpandedRoles["DEEP_LYING_PLAYMAKER"] = "DEEP_LYING_PLAYMAKER";
    DefensiveMidfielderExpandedRoles["PRESS_RESISTANT"] = "PRESS_RESISTANT";
    DefensiveMidfielderExpandedRoles["SHIELD"] = "SHIELD";
    DefensiveMidfielderExpandedRoles["RAPID_DEFENSIVE_MIDFIELDER"] = "RAPID_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["POWERFUL_DEFENSIVE_MIDFIELDER"] = "POWERFUL_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["ATHLETIC_DEFENSIVE_MIDFIELDER"] = "ATHLETIC_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["TENACIOUS_DEFENSIVE_MIDFIELDER"] = "TENACIOUS_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["AGGRESSIVE_DEFENSIVE_MIDFIELDER"] = "AGGRESSIVE_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["ROBUST_DEFENSIVE_MIDFIELDER"] = "ROBUST_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["DETERMINED_DEFENSIVE_MIDFIELDER"] = "DETERMINED_DEFENSIVE_MIDFIELDER";
    DefensiveMidfielderExpandedRoles["TOUGH_DEFENSIVE_MIDFIELDER"] = "TOUGH_DEFENSIVE_MIDFIELDER";
})(DefensiveMidfielderExpandedRoles || (exports.DefensiveMidfielderExpandedRoles = DefensiveMidfielderExpandedRoles = {}));
exports.DefensiveMidfielderRoleBonuses = {
    DEFENSIVE_MIDFIELDER: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            pass: 1,
            intercept: 3,
            tackle: 2,
        },
        positionsEnabled: [zones_1.Zones.DEFENSE, zones_1.Zones.MIDFIELD],
        playerTraits: [],
    },
    DEEP_LYING_PLAYMAKER: {
        parentRoles: [baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.PLAYMAKER],
    },
    PRESS_RESISTANT: {
        parentRoles: [baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.PRESS_RESISTANT],
    },
    SHIELD: {
        parentRoles: [DefensiveMidfielderExpandedRoles.PRESS_RESISTANT],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {}, // Inherits all stats from Deep Lying Playmaker
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.SHIELD], // Adds SHIELD trait
    },
    RAPID_DEFENSIVE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_DEFENSIVE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {
            tackle: 1,
            intercept: 1,
        },
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    ATHLETIC_DEFENSIVE_MIDFIELDER: {
        parentRoles: [
            DefensiveMidfielderExpandedRoles.RAPID_DEFENSIVE_MIDFIELDER,
            DefensiveMidfielderExpandedRoles.POWERFUL_DEFENSIVE_MIDFIELDER,
        ],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    TENACIOUS_DEFENSIVE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.UNDERDOG],
    },
    ROBUST_DEFENSIVE_MIDFIELDER: {
        parentRoles: [DefensiveMidfielderExpandedRoles.TENACIOUS_DEFENSIVE_MIDFIELDER, DefensiveMidfielderExpandedRoles.POWERFUL_DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    AGGRESSIVE_DEFENSIVE_MIDFIELDER: {
        parentRoles: [DefensiveMidfielderExpandedRoles.TENACIOUS_DEFENSIVE_MIDFIELDER, DefensiveMidfielderExpandedRoles.RAPID_DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    DETERMINED_DEFENSIVE_MIDFIELDER: {
        parentRoles: [baseRoles_1.BaseRoles.DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DETERMINED],
    },
    TOUGH_DEFENSIVE_MIDFIELDER: {
        parentRoles: [DefensiveMidfielderExpandedRoles.DETERMINED_DEFENSIVE_MIDFIELDER, DefensiveMidfielderExpandedRoles.POWERFUL_DEFENSIVE_MIDFIELDER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
};
