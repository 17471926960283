import { Actions, PlayerRoundState, Zones } from "@alienheadwars/football-card-manager-model";
import { Card, Stack } from "@mui/material";
import React, { useState } from "react";
import { getCallableFunction } from "../../../../../firebase/firebase";
import { localise } from "../../../../../locale/locale";
import { ButtonComponent, Type } from "../../../../base/button/Button";
import { Icon } from "../../../../base/icon/Icon";
import { HelpOverlay } from "../../../../base/tutorial/helpOverlay/HelpOverlay";
import { ExpandablePlayerCard } from "../../../../cards/playerCards/v2/PlayerCard";
import { BoardProps } from "./board";

export interface PlayerChoiceComponentProps extends BoardProps {
}

export const PlayerChoiceComponent = (props: PlayerChoiceComponentProps) => {
    //TODO tidy and refactor so that you can go forward and backwards, building up an action as you go
    const playerIsAttacking = props.roundState.attackingTeam === props.playerTeamId;
    if (playerIsAttacking) {
        if (props.roundState.attackingPlayer === undefined)
            return <ChooseKickOffPlayer {...props} />
        if (props.roundState.action === undefined)
            return <ChooseAttackingTargetPlayerAndAction {...props} />
    }
    else {
        if (props.roundState.defendingPlayer === undefined)
            return <ChooseDefendingPlayer {...props} />
    }
    return <></>
}

export const ChooseAttackingTargetPlayerAndAction = (props: PlayerChoiceComponentProps) => {

    const [targetPlayer, setTargetPlayer] = useState(undefined as unknown as number)

    const [loading, setLoading] = useState(false)

    const chooseAction = getCallableFunction('actionMatch')
    const chooseActionAction = (action: Actions) => {
        const params = {
            matchId: props.matchId,
            gameAction: {
                action: action,
                attackingTargetPlayer: targetPlayer,
            }
        }
        chooseAction(params)
        setLoading(true)
    }
    return <Card sx={{
        margin: '1vh',
        padding: '1vw'
    }}>

        <HelpOverlay additionalCondition={targetPlayer === undefined} helpText={localise('match.set.attackingTargetPlayer.help')} tutorialId='match.set.attackingTargetPlayer.help' blockSkip={true}>
            <ChoosePlayer
                filter={(player, index) => !player.discarded && index !== props.roundState.attackingPlayer && index !== 0}
                hand={props.roundState.attackingHand}
                {...props}
                callback={(playerIndex: number) => setTargetPlayer(playerIndex)}
                currentlySelectedPlayer={targetPlayer}
                loading={loading}
            />
        </HelpOverlay>
        <Stack
            direction='row'
            sx={{
                margin: '1vh',
                padding: '1vw'
            }}
            spacing={1}
        >

            <HelpOverlay additionalCondition={targetPlayer !== undefined && !loading && 
                props.roundState.roundId!==0 //Don't show help if first round, as user may stall before doing any other actions
                } helpText={localise('match.attackStall.help')} tutorialId='match.attackStall.help'>
                <ButtonComponent loadOnExecute disabled={loading} type={Type.SECONDARY} onClick={() => chooseActionAction(Actions.STALL)}><Icon iconId='STALL' /> {localise('match.attackStall')}</ButtonComponent>
            </HelpOverlay>
            <HelpOverlay additionalCondition={targetPlayer !== undefined && !loading} helpText={localise('match.pass.help')} tutorialId='match.pass.help'>
                <ButtonComponent loadOnExecute disabled={targetPlayer === undefined || loading} onClick={() => chooseActionAction(Actions.PASS)}><Icon iconId='PASS' /> {localise('match.pass')}</ButtonComponent>
            </HelpOverlay>
            <HelpOverlay additionalCondition={targetPlayer !== undefined && !loading} helpText={localise('match.run.help')} tutorialId='match.run.help'>
                <ButtonComponent loadOnExecute disabled={targetPlayer === undefined || loading} onClick={() => chooseActionAction(Actions.RUN)}><Icon iconId='RUN' /> {localise('match.run')}</ButtonComponent>
            </HelpOverlay>
            {props.roundState.zone === Zones.ATTACK &&
                <HelpOverlay additionalCondition={targetPlayer !== undefined && !loading} helpText={localise('match.shoot.help')} tutorialId='match.shoot.help'>
                    <ButtonComponent loadOnExecute disabled={loading} onClick={() => chooseActionAction(Actions.SHOOT)}><Icon iconId='SHOOT' /> {localise('match.shoot')}</ButtonComponent>
                </HelpOverlay>}

        </Stack>
    </Card>
}




export const ChooseDefendingPlayer = (props: PlayerChoiceComponentProps) => {

    const [defendingPlayer, setDefendingPlayer] = useState(undefined as unknown as number)

    const [loading, setLoading] = useState(false)
    const chooseAction = getCallableFunction('actionMatch')
    const chooseDefender = (defendingPlayerP: number) => {
        const params = {
            matchId: props.matchId,
            gameAction: {
                defendingPlayer: defendingPlayerP,
            }
        }
        chooseAction(params)
        setLoading(true)
    }
    return <Card sx={{
        margin: '1vh',
        padding: '1vw'
    }}>

        <HelpOverlay additionalCondition={defendingPlayer === undefined} helpText={localise('match.set.defendingPlayer.help')} tutorialId='match.defendingPlayer.help' blockSkip={true}>
            <ChoosePlayer
                filter={(player, index) => !player.discarded && index !== 0}
                hand={props.roundState.defendingHand}
                {...props}
                callback={(playerIndex: number) => setDefendingPlayer(playerIndex)}
                currentlySelectedPlayer={defendingPlayer}
                loading={loading}
            />
        </HelpOverlay>
        <Stack
            direction='row'
            sx={{
                margin: '1vh',
                padding: '1vw'
            }}
            spacing={1}
        >

            <HelpOverlay additionalCondition={defendingPlayer !== undefined && !loading} helpText={localise('match.defendStall.help')} tutorialId='match.defendStall.help'>
                <ButtonComponent loadOnExecute disabled={loading} type={Type.SECONDARY} onClick={() => chooseDefender(-1)}><Icon iconId='STALL' /> {localise('match.defendStall')}</ButtonComponent>
            </HelpOverlay>
            <HelpOverlay additionalCondition={defendingPlayer !== undefined && !loading} helpText={localise('match.contest.help')} tutorialId='match.contest.help'>
                <ButtonComponent loadOnExecute disabled={defendingPlayer === undefined || loading} onClick={() => chooseDefender(defendingPlayer)}><Icon iconId='CONTEST' /> {localise('match.contest')} </ButtonComponent>
            </HelpOverlay>

        </Stack>
    </Card>

}

export const ChooseKickOffPlayer = (props: PlayerChoiceComponentProps) => {

    const [attackingPlayer, setAttackingPlayer] = useState(undefined as unknown as number)
    const [loading, setLoading] = useState(false)

    const chooseAction = getCallableFunction('actionMatch')
    const chooseAttacker = () => {
        const params = {
            matchId: props.matchId,
            gameAction: {
                attackingPlayer: attackingPlayer,
            }
        }
        chooseAction(params)
        setLoading(true)
    }
    return <Card sx={{
        margin: '1vh',
        padding: '1vw'
    }}>

        <HelpOverlay additionalCondition={attackingPlayer === undefined} helpText={localise('match.set.attackingPlayer.help')} tutorialId='match.set.attackingPlayer' blockSkip={true}>
            <ChoosePlayer
                filter={(player) => !player.discarded}
                hand={props.roundState.attackingHand}
                {...props}
                callback={(playerIndex: number) => setAttackingPlayer(playerIndex)}
                currentlySelectedPlayer={attackingPlayer}
                loading={loading}
            />
        </HelpOverlay>
        <Stack
            direction='row'
            sx={{
                margin: '1vh',
                padding: '1vw'
            }}
            spacing={1}
        >

<HelpOverlay additionalCondition={attackingPlayer !== undefined && !loading} helpText={localise('match.kickOff.help')} tutorialId='match.kickOff.help'>

            <ButtonComponent loadOnExecute disabled={attackingPlayer === undefined || loading} onClick={() => chooseAttacker()}>
                <Icon iconId='PASS' /> {localise('match.kickOff')}</ButtonComponent>
                </HelpOverlay>

        </Stack>
    </Card>

}



interface ChoosePlayerProps extends PlayerChoiceComponentProps {
    filter: (player: PlayerRoundState, index: number) => boolean,
    hand: PlayerRoundState[]
    callback(playerIndex: number): void
    currentlySelectedPlayer?: number,
    loading: boolean
}

const ChoosePlayer = (props: ChoosePlayerProps) => {
    const players = props.hand.map((player, index) => {
        if (!props.filter(player, index)) {
            return undefined
        }
        const selected: boolean = props.currentlySelectedPlayer === index
        return <ExpandablePlayerCard
            player={player.playerDetails}
            size="hand"
            selected={selected}
            onClick={() => {
                if (!props.loading) {
                    return props.callback(index)
                }
            }
            }
        />
    }).filter(player => player !== undefined)
    return <Stack direction='row' sx={{
        margin: '1vh',
        padding: '1vw',
        overflowX: 'scroll',
        filter: props.loading ? 'grayscale(75%)' : 'none',
        maxWidth: '90vw'
    }}>
        {players}
    </Stack>
}