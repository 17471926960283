import { TeamColours } from "@alienheadwars/football-card-manager-model";
import { Card, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { localise } from "../../../locale/locale";


const impactFonts = ['Norwester', 'sans-serif'].join(',');
const badgeSize = 'min(8vh,40vw)'
const fontSize = 'min(1.5vh,5vw)'

export const BadgeComponent = (props: { id: string, name: string, colours: TeamColours }) => {
    const [imageLoaded, setImageLoaded] = useState(true);

    return (
        <Stack sx={{ marginLeft: '4vmin' }}>
            <Card
                sx={{
                    backgroundColor: props.colours?.primaryBackgroundColour ?? 'white',
                    width: badgeSize,
                    height: badgeSize,
                }}
            >
                {imageLoaded && <img
                    src={`/images/badges/${props.id}.png`}
                    alt={props.id}
                    style={{ width: badgeSize }}
                    onError={() => setImageLoaded(false)} // Set the state to show the backup if there's an error
                />
                }
                {/* Conditionally render the backup badge */}
                {!imageLoaded && (
                    <img
                        src={`/images/badges/fallback.png`} // Replace with your fallback image path
                        alt="Fallback Badge"
                        style={{ width: badgeSize }}
                    />
                )}
            </Card>
            <Card
                sx={{
                    backgroundColor: props.colours?.primaryBackgroundColour ?? 'white',
                    width: `calc(${badgeSize} + 4vmin)`,
                    transform: 'translate(-2vmin, -1px)',
                    display: 'flex',
                    flexDirection: 'column', // Arrange items vertically
                    alignItems: 'center',     // Center horizontally
                    justifyContent: 'center',  // Center vertically
                    padding: '.5vw'
                }}
            >
                <Typography
                    component="div"
                    sx={{
                        color: props.colours?.primaryTextColour ?? 'black',
                        fontSize: fontSize,
                        textAlign: 'center',
                        fontFamily: impactFonts
                    }}
                >
                    {localise(props.name)}
                </Typography>
            </Card>
        </Stack>
    );
};