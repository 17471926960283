import { WorldTourTeam } from '@alienheadwars/football-card-manager-model/dist/world-tour/worldTourTeam';
import { Box,  CardActions, CardMedia, LinearProgress, Stack, Typography } from "@mui/material"; // Added Box, LinearProgress
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { TeamContext } from "../../TeamContext";
import { WorldTourCard } from './WorldTourCard';
// Removed: import { WorldTourChallengesCountCard } from './WorldTourChallenges';
import { getCallableFunction } from '../../../../firebase/firebase';
import { localise } from '../../../../locale/locale';
import { Lineup } from '../../myteam/lineup/Lineup';
import { WorldTourChallengesCountCard } from './WorldTourChallenges';
import { WorldTourContext } from './WorldTourContext';
import { ButtonComponent } from '../../../base/button/Button';

// Helper function for progress bar value
const calculateProgress = (completed: number, total: number): number => {
    if (total === 0) return 0;
    return (completed / total) * 100;
};

export interface LeagueUrlParams {
    teamId: string,
    regionId: string,
    leagueId: string
}

export const WorldTourTeamCard = (team: WorldTourTeam) => {
    const teamContext = useContext(TeamContext);
    const worldTourContext = useContext(WorldTourContext);
    const worldTourProgress = worldTourContext.getWorldTourProgress();
    const itemProgress = worldTourProgress.teams[team.id];
    const leagueUrlParams = useParams<LeagueUrlParams>()

    const challengesCompleted = itemProgress?.challengesComplete?.length ?? 0;
    const totalChallenges = team.challenges?.length ?? 0;
    const progressValue = calculateProgress(challengesCompleted, totalChallenges);
    const isUnlocked = !!itemProgress?.unlocked; // Ensure boolean conversion

    const newMatchFunction = getCallableFunction('createWorldTourMatch')
    const newMatchAction = () => {
        const params = {
            teamId: teamContext.getTeam().id,
            lineup: teamContext.getTeamConfig().lineups.default,
            region: leagueUrlParams.regionId,
            league: `${leagueUrlParams.regionId}/${leagueUrlParams.leagueId}`,
            opponentId: team.id
        }
        newMatchFunction(params)
    }

    return (
        <WorldTourCard
            header={<Typography variant="h5" component="div" sx={{ color: team.colours?.primaryTextColour ?? 'black' }}>{team.name}</Typography>}
            subHeader={isUnlocked ?
                <Box sx={{ width: '100%', color: team.colours?.primaryColour ?? 'black' }}>              <Typography variant="body2" sx={{ color: team.colours?.primaryTextColour ?? 'black', mb: 0.5 }}>
                    Challenges: {challengesCompleted} / {totalChallenges} Completed
                </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={progressValue}
                        color='inherit'
                        sx={{ height: 8, borderRadius: 4 }} // Style progress bar
                    />
                    <CardActions sx={{ justifyContent: 'flex-end' }}> {/* Align button to the right */}
                        <ButtonComponent
                            disabled={!isUnlocked} // Disable if team not unlocked
                            onClick={() => {
                                newMatchAction();
                                worldTourContext.setLastOpponentId(team.id);
                            }}
                            size='small'
                            loadOnExecute
                        >
                            Play now
                        </ButtonComponent>
                    </CardActions>
                </Box>
                : localise('worldTourTeamCard.unlock')
            }
            disabled={!isUnlocked}
            avatar={< CardMedia
                component="img"
                sx={{ width: 60, border: 2, borderColor: team.colours?.primaryColour ?? 'black', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                image={`/images/badges/${team.id}.png`}
                alt={team.id}
            />}
            contentId='teams'
            id={team.id}
            key={team.id}
            defaultExpanded={false}
            sx={{
                backgroundColor: team.colours?.primaryBackgroundColour,
                color: team.colours?.primaryTextColour ?? 'black'
            }}
        >

            <Stack spacing={3} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                <WorldTourChallengesCountCard
                    challenges={team.challenges}
                    itemProgress={itemProgress}
                />
                <Lineup players={team.players} flip orientation='horizontal'/>
            </Stack>


        </WorldTourCard >
    );
};