// --- RoundStateComponent.tsx ---
import React from 'react';
import { BoardComponent, BoardProps } from '../boardComponents/board';

interface RoundStateProps extends BoardProps {
}

export const RoundStateComponent = (props: RoundStateProps) => {

    return <>
        <BoardComponent {...props} />
    </>

};