"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseRoles = void 0;
var BaseRoles;
(function (BaseRoles) {
    BaseRoles["GOAL_KEEPER"] = "GOAL_KEEPER";
    BaseRoles["FULL_BACK"] = "FULL_BACK";
    BaseRoles["CENTER_BACK"] = "CENTER_BACK";
    BaseRoles["DEFENSIVE_MIDFIELDER"] = "DEFENSIVE_MIDFIELDER";
    BaseRoles["WIDE_MIDFIELDER"] = "WIDE_MIDFIELDER";
    BaseRoles["CENTRAL_MIDFIELDER"] = "CENTRAL_MIDFIELDER";
    BaseRoles["ATTACKING_MIDFIELDER"] = "ATTACKING_MIDFIELDER";
    BaseRoles["WINGER"] = "WINGER";
    BaseRoles["STRIKER"] = "STRIKER";
})(BaseRoles || (exports.BaseRoles = BaseRoles = {}));
