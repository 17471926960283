"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RUN_SUCCESSFUL_RESULTS = exports.PASS_SUCCESSFUL_RESULTS = exports.DEFENSE_WON_RESULTS = exports.RoundResult = void 0;
var RoundResult;
(function (RoundResult) {
    RoundResult["GOAL"] = "GOAL";
    RoundResult["SHOT_SAVED"] = "SHOT_SAVED";
    RoundResult["PASS_SUCCESSFUL"] = "PASS_SUCCESSFUL";
    RoundResult["RUN_SUCCESSFUL"] = "RUN_SUCCESSFUL";
    RoundResult["DEFENSE_WIN"] = "DEFENSE_WIN";
    RoundResult["DRAW"] = "DRAW";
    RoundResult["ATTACK_STALL"] = "ATTACK_STALL";
    RoundResult["RUN_UNCONTESTED"] = "RUN_UNCONTESTED";
    RoundResult["PASS_UNCONTESTED"] = "PASS_UNCONTESTED";
    RoundResult["BOTH_STALL"] = "BOTH_STALL";
})(RoundResult || (exports.RoundResult = RoundResult = {}));
exports.DEFENSE_WON_RESULTS = [RoundResult.DEFENSE_WIN, RoundResult.ATTACK_STALL, RoundResult.SHOT_SAVED];
exports.PASS_SUCCESSFUL_RESULTS = [RoundResult.PASS_SUCCESSFUL, RoundResult.PASS_UNCONTESTED];
exports.RUN_SUCCESSFUL_RESULTS = [RoundResult.RUN_SUCCESSFUL, RoundResult.RUN_UNCONTESTED];
