"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrikerRoleBonuses = exports.StrikerExpandedRoles = void 0;
// strikerRoles.ts  (Renamed from forwardRoles.ts)
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var StrikerExpandedRoles;
(function (StrikerExpandedRoles) {
    StrikerExpandedRoles["RAPID_STRIKER"] = "RAPID_STRIKER";
    StrikerExpandedRoles["POWERFUL_STRIKER"] = "POWERFUL_STRIKER";
    StrikerExpandedRoles["ATHLETIC_STRIKER"] = "ATHLETIC_STRIKER";
    StrikerExpandedRoles["CLINICAL_STRIKER"] = "CLINICAL_STRIKER";
    StrikerExpandedRoles["POWERFUL_FINISHER"] = "POWERFUL_FINISHER";
    StrikerExpandedRoles["PREDATOR"] = "PREDATOR";
    StrikerExpandedRoles["TRICKY_STRIKER"] = "TRICKY_STRIKER";
    StrikerExpandedRoles["RAPID_TRICKY_STRIKER"] = "RAPID_TRICKY_STRIKER";
    StrikerExpandedRoles["POWERFUL_TRICKY_STRIKER"] = "POWERFUL_TRICKY_STRIKER";
    StrikerExpandedRoles["COMPLETE_FORWARD"] = "COMPLETE_FORWARD";
    StrikerExpandedRoles["TENACIOUS_STRIKER"] = "TENACIOUS_STRIKER";
    StrikerExpandedRoles["DOMINANT_STRIKER"] = "DOMINANT_STRIKER";
    StrikerExpandedRoles["DETERMINED_STRIKER"] = "DETERMINED_STRIKER";
})(StrikerExpandedRoles || (exports.StrikerExpandedRoles = StrikerExpandedRoles = {}));
exports.StrikerRoleBonuses = {
    STRIKER: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            shoot: 3,
            run: 2,
            pass: 1,
        },
        positionsEnabled: [zones_1.Zones.ATTACK],
        playerTraits: [],
    },
    RAPID_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    POWERFUL_FINISHER: {
        parentRoles: [StrikerExpandedRoles.POWERFUL_STRIKER, StrikerExpandedRoles.CLINICAL_STRIKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    ATHLETIC_STRIKER: {
        parentRoles: [StrikerExpandedRoles.RAPID_STRIKER, StrikerExpandedRoles.POWERFUL_STRIKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    CLINICAL_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.CLINICAL],
    },
    PREDATOR: {
        parentRoles: [StrikerExpandedRoles.RAPID_STRIKER, StrikerExpandedRoles.CLINICAL_STRIKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    TRICKY_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR],
    },
    RAPID_TRICKY_STRIKER: {
        parentRoles: [StrikerExpandedRoles.TRICKY_STRIKER, StrikerExpandedRoles.RAPID_STRIKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    POWERFUL_TRICKY_STRIKER: {
        parentRoles: [StrikerExpandedRoles.TRICKY_STRIKER, StrikerExpandedRoles.POWERFUL_STRIKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    COMPLETE_FORWARD: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {
            run: 1,
            pass: 2
        },
        positionsEnabled: [],
        playerTraits: [],
    },
    TENACIOUS_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.UNDERDOG],
    },
    DOMINANT_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DOMINANT],
    },
    DETERMINED_STRIKER: {
        parentRoles: [baseRoles_1.BaseRoles.STRIKER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DETERMINED],
    },
};
