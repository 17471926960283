import React, { useState, useEffect } from 'react';
import { Overlay } from '../overlay/Overlay'; // Adjust path as needed
import { Box, CircularProgress, Typography, Fade } from '@mui/material';

// Component written by gemini, messages by myself and gemini

// --- List of Loading Messages ---
const defaultLoadingMessages = [
    "Tying shoelaces...",
    "Inflating footballs...",
    "Checking VAR...",
    "Polishing boots...",
    "Calculating offsides...",
    "Arguing with the ref...",
    "Signing autographs...",
    "Warming up the bench...",
    "Finding the magic sponge...",
    "Consulting tactics board...",
    "Practicing goal celebrations...",
    "Synchronising watches...",
    "Untangling goal nets...",
    "Applying vanishing spray...",
    "Hiding aces up sleeves...",
    "Bribing referees...",
    "Parking the bus...",
    "Re-chalking the lines...",
    "Increasing ticket prices...",
    "Inventing fake sponsors...",
    "Trying to understand the offside rule...",
    "Watering the pitch...",
    "Practicing dives...",
    "Teaching VAR how to draw straight lines...",
    "Applying excessive amounts of hair gel...",
    "Hiding the physio's magic sponge...",
    "Blaming the pitch conditions...",
    "Warming up the Bovril...",
    "Searching for lost shin pads...",
    "Ironing the kits...",
    "Rehearsing post-match clichés...",
    "Adjusting the goalpost height...",
    "Prematurely Planning the trophy parade route...",
    "Ignoring the fitness coach...",
    "Simulating injuries in training...",
    "Trying to decipher the manager's accent...",
    "Diligently counting blades of grass...", 
    "Artistically putting cones out...", 
    "Googling 'What is a false nine?'...",
    "Deflating opposition footballs...", 
    "Signing a panic buy...",
    "Extending Fergie time...", 
    "Blasting motivational techno in the dressing room...",
    "Arguing about who takes the penalty...",
    "Obsessively checking transfer rumours online...", 
    "Complaining about fixture congestion...",
    "Desperately finding a scapegoat for the last match...", 
    "Tightening goalkeeper gloves...",
    "Strategically memorising linesman's first name...", 
    "Ordering half-time oranges...",
    "Calibrating the goal-line technology...",
    "Trying to start a Mexican wave...",
    "Sneakily hiding the red card from the ref...", 
    "Negotiating player bonuses...",
    "Setting up the one-man wall...",
    "Trying to remember the rules...",
    "Accusing the CPU of cheating...",
    "Discreetly counting cards...",
    "Reading the fine print on the cards...",
    "Secretly stacking the deck in your favour...", 
    "Blowing on the cards for luck...",
    "Suffering from analysis paralysis...",
    "Hopefully top-decking the perfect card...", 
    "Preparing my victory speech...",
    "Preparing my excuses...",
    "Sleeving the rare cards...",
    "Resisting the urge to flip the table...",
    "Obsessively arranging cards by attribute...", 
    "Looking for a rules lawyer...",
    "Conveniently hiding the rulebook...", 
    "Shuffling the squad list...",
    "Dealing the starting eleven...",
    "Checking player card stats again...", 
    "Putting the goalkeeper card in a protective sleeve...",
    "Hoping for a legendary striker pull...",
    "Trading duplicate right-backs...",
    "Debating card rarity vs actual player skill...",
    "Buffing the striker's finishing stat...",
    "Nerfing the opponent's goalie card...",
    "Definitely not mining cryptocurrency...",
    "Loading the loading screen messages...",
    "Rerouting the hamsters...",
    "Compiling excuses for the delay...",
    "Counting pixels...",
    "Asking the AI for more loading messages...",
    "Digitally ensuring the ball is perfectly spherical...", 
    "Optimising the grass shader...",
    "Paying the server electricity bill...",
];

// --- Component Props ---
interface SimsStyleLoadingProps {
    messages?: string[];
    interval?: number;
}

// --- The Loading Component ---
export const SimsStyleLoading: React.FC<SimsStyleLoadingProps> = ({
    messages = defaultLoadingMessages,
    interval = 3000 // Message display duration
}) => {
    const [messageIndex, setMessageIndex] = useState(0);
    const [showText, setShowText] = useState(true);

    // Initialize with a random message on mount
    useEffect(() => {
        if (messages.length > 1) {
            setMessageIndex(Math.floor(Math.random() * messages.length));
        }
    }, [messages]); // Run only when messages array changes (e.g., on mount)


    useEffect(() => {
        if (!messages || messages.length === 0) return;

        const timerId = setInterval(() => {
            setShowText(false); // Start fade out

            const textChangeTimerId = setTimeout(() => {
                // --- Pick a new RANDOM index ---
                setMessageIndex(prevIndex => {
                    // Handle case with only one message
                    if (messages.length === 1) return 0;

                    let newIndex;
                    // Keep picking until we get a different index
                    do {
                        newIndex = Math.floor(Math.random() * messages.length);
                    } while (newIndex === prevIndex);
                    return newIndex;
                });
                 // --- End of random logic ---

                 setShowText(true); // Start fade in
            }, 400); // Text change happens during fade transition

            return () => clearTimeout(textChangeTimerId);

        }, interval); // Duration each message is shown

        // Cleanup main interval
        return () => clearInterval(timerId);

    }, [messages, interval]);

    const currentMessage = messages[messageIndex];

    return (
        <Overlay>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textAlign: 'center',
                    padding: 4,
                }}
            >
                <CircularProgress color="inherit" sx={{ marginBottom: 3 }} />
                <Box sx={{ minHeight: '3em' }}>
                    <Fade in={showText} timeout={500}>
                        {/* Key change triggers fade */}
                        <Typography variant="h6" key={currentMessage}>
                            {currentMessage}
                        </Typography>
                    </Fade>
                </Box>
            </Box>
        </Overlay>
    );
};