"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WingerRoleBonuses = exports.WingerExpandedRoles = void 0;
// wingerRoles.ts
const playerTraits_1 = require("../playerTraits");
const rarity_1 = require("../rarity");
const zones_1 = require("../zones");
const baseRoles_1 = require("./baseRoles");
var WingerExpandedRoles;
(function (WingerExpandedRoles) {
    WingerExpandedRoles["RAPID_WINGER"] = "RAPID_WINGER";
    WingerExpandedRoles["POWERFUL_WINGER"] = "POWERFUL_WINGER";
    WingerExpandedRoles["ATHLETIC_WINGER"] = "ATHLETIC_WINGER";
    WingerExpandedRoles["TRICKY_WINGER"] = "TRICKY_WINGER";
    WingerExpandedRoles["INSIDE_FORWARD"] = "INSIDE_FORWARD";
    WingerExpandedRoles["RAPID_INSIDE_FORWARD"] = "RAPID_INSIDE_FORWARD";
    WingerExpandedRoles["POWERFUL_INSIDE_FORWARD"] = "POWERFUL_INSIDE_FORWARD";
    WingerExpandedRoles["TRICKY_INSIDE_FORWARD"] = "TRICKY_INSIDE_FORWARD";
    WingerExpandedRoles["RAPID_TRICKY_WINGER"] = "RAPID_TRICKY_WINGER";
    WingerExpandedRoles["POWERFUL_TRICKY_WINGER"] = "POWERFUL_TRICKY_WINGER";
    WingerExpandedRoles["HEROIC_WINGER"] = "HEROIC_WINGER";
    WingerExpandedRoles["TALISMANIC_WINGER"] = "TALISMANIC_WINGER";
    WingerExpandedRoles["FLYING_WINGER"] = "FLYING_WINGER";
})(WingerExpandedRoles || (exports.WingerExpandedRoles = WingerExpandedRoles = {}));
exports.WingerRoleBonuses = {
    WINGER: {
        rarity: rarity_1.Rarity.COMMON,
        statBlock: {
            run: 3,
            pass: 2,
            shoot: 1,
        },
        positionsEnabled: [zones_1.Zones.MIDFIELD, zones_1.Zones.ATTACK], // Can play in midfield and attack
        playerTraits: [],
    },
    RAPID_WINGER: {
        parentRoles: [baseRoles_1.BaseRoles.WINGER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {}, // Inherits stats, modifies with trait
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.RAPID],
    },
    POWERFUL_WINGER: {
        parentRoles: [baseRoles_1.BaseRoles.WINGER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL],
    },
    ATHLETIC_WINGER: {
        parentRoles: [WingerExpandedRoles.RAPID_WINGER, WingerExpandedRoles.POWERFUL_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    TRICKY_WINGER: {
        parentRoles: [baseRoles_1.BaseRoles.WINGER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.FLAIR],
    },
    INSIDE_FORWARD: {
        parentRoles: [baseRoles_1.BaseRoles.WINGER],
        rarity: rarity_1.Rarity.UNCOMMON,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.CHARGE],
    },
    RAPID_INSIDE_FORWARD: {
        parentRoles: [WingerExpandedRoles.INSIDE_FORWARD, WingerExpandedRoles.RAPID_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    POWERFUL_INSIDE_FORWARD: {
        parentRoles: [WingerExpandedRoles.INSIDE_FORWARD, WingerExpandedRoles.POWERFUL_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    TRICKY_INSIDE_FORWARD: {
        parentRoles: [WingerExpandedRoles.INSIDE_FORWARD, WingerExpandedRoles.TRICKY_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    RAPID_TRICKY_WINGER: {
        parentRoles: [WingerExpandedRoles.RAPID_WINGER, WingerExpandedRoles.TRICKY_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    POWERFUL_TRICKY_WINGER: {
        parentRoles: [WingerExpandedRoles.POWERFUL_WINGER, WingerExpandedRoles.TRICKY_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
    HEROIC_WINGER: {
        parentRoles: [baseRoles_1.BaseRoles.WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.HERO],
    },
    TALISMANIC_WINGER: {
        parentRoles: [WingerExpandedRoles.HEROIC_WINGER,],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.DETERMINED],
    },
    FLYING_WINGER: {
        parentRoles: [WingerExpandedRoles.RAPID_WINGER, WingerExpandedRoles.TRICKY_WINGER],
        rarity: rarity_1.Rarity.RARE,
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [],
    },
};
