export const enNationalities: { [key: string]: string } = {
    'nationalities.ar': 'Argentinian',
    'nationalities.gb-wls': 'Welsh',
    'nationalities.gb-eng': 'English',
    'nationalities.gb-sct': 'Scottish',
    'nationalities.de': 'German',
    'nationalities.fr': 'French',
    'nationalities.mx': 'Mexican',
    'nationalities.be': 'Belgian',
    'nationalities.br': 'Brazilian',
    'nationalities.ie': 'Irish',
    'nationalities.it': 'Italian',
    'nationalities.pt': 'Portuguese',
    'nationalities.es': 'Spanish',
    'nationalities.af': 'Afghan',
    'nationalities.al': 'Albanian',
    'nationalities.dz': 'Algerian',
    'nationalities.ad': 'Andorran',
    'nationalities.ao': 'Angolan',
    'nationalities.ag': 'Antiguan and Barbudan',
    'nationalities.am': 'Armenian',
    'nationalities.au': 'Australian',
    'nationalities.at': 'Austrian',
    'nationalities.az': 'Azerbaijani',
    'nationalities.bs': 'Bahamian',
    'nationalities.bh': 'Bahraini',
    'nationalities.bd': 'Bangladeshi',
    'nationalities.bb': 'Barbadian',
    'nationalities.by': 'Belarusian',
    'nationalities.bz': 'Belizean',
    'nationalities.bj': 'Beninese',
    'nationalities.bt': 'Bhutanese',
    'nationalities.bo': 'Bolivian',
    'nationalities.ba': 'Bosnian and Herzegovinian',
    'nationalities.bw': 'Botswanan',
    'nationalities.bn': 'Bruneian',
    'nationalities.bg': 'Bulgarian',
    'nationalities.bf': 'Burkinabe',
    'nationalities.bi': 'Burundian',
    'nationalities.cv': 'Cabo Verdean',
    'nationalities.kh': 'Cambodian',
    'nationalities.cm': 'Cameroonian',
    'nationalities.ca': 'Canadian',
    'nationalities.cf': 'Central African',
    'nationalities.td': 'Chadian',
    'nationalities.cl': 'Chilean',
    'nationalities.cn': 'Chinese',
    'nationalities.co': 'Colombian',
    'nationalities.km': 'Comoran',
    'nationalities.cd': 'Congolese (DRC)',
    'nationalities.cg': 'Congolese (Republic)',
    'nationalities.cr': 'Costa Rican',
    'nationalities.ci': 'Ivorian',
    'nationalities.hr': 'Croatian',
    'nationalities.cu': 'Cuban',
    'nationalities.cy': 'Cypriot',
    'nationalities.cz': 'Czech',
    'nationalities.dk': 'Danish',
    'nationalities.dj': 'Djiboutian',
    'nationalities.dm': 'Dominican',
    'nationalities.do': 'Dominican',
    'nationalities.ec': 'Ecuadorian',
    'nationalities.eg': 'Egyptian',
    'nationalities.sv': 'Salvadoran',
    'nationalities.gq': 'Equatorial Guinean',
    'nationalities.er': 'Eritrean',
    'nationalities.ee': 'Estonian',
    'nationalities.sz': 'Eswatini',
    'nationalities.et': 'Ethiopian',
    'nationalities.fj': 'Fijian',
    'nationalities.fi': 'Finnish',
    'nationalities.ga': 'Gabonese',
    'nationalities.gm': 'Gambian',
    'nationalities.ge': 'Georgian',
    'nationalities.gh': 'Ghanaian',
    'nationalities.gr': 'Greek',
    'nationalities.gd': 'Grenadian',
    'nationalities.gt': 'Guatemalan',
    'nationalities.gn': 'Guinean',
    'nationalities.gw': 'Guinea-Bissauan',
    'nationalities.gy': 'Guyanese',
    'nationalities.ht': 'Haitian',
    'nationalities.hn': 'Honduran',
    'nationalities.hu': 'Hungarian',
    'nationalities.is': 'Icelandic',
    'nationalities.in': 'Indian',
    'nationalities.id': 'Indonesian',
    'nationalities.ir': 'Iranian',
    'nationalities.iq': 'Iraqi',
    'nationalities.il': 'Israeli',
    'nationalities.jm': 'Jamaican',
    'nationalities.jp': 'Japanese',
    'nationalities.jo': 'Jordanian',
    'nationalities.kz': 'Kazakhstani',
    'nationalities.ke': 'Kenyan',
    'nationalities.ki': 'Kiribati',
    'nationalities.kp': 'North Korean',
    'nationalities.kr': 'South Korean',
    'nationalities.xk': 'Kosovan',
    'nationalities.kw': 'Kuwaiti',
    'nationalities.kg': 'Kyrgyzstani',
    'nationalities.la': 'Laotian',
    'nationalities.lv': 'Latvian',
    'nationalities.lb': 'Lebanese',
    'nationalities.ls': 'Lesotho',
    'nationalities.lr': 'Liberian',
    'nationalities.ly': 'Libyan',
    'nationalities.li': 'Liechtensteiner',
    'nationalities.lt': 'Lithuanian',
    'nationalities.lu': 'Luxembourgish',
    'nationalities.mg': 'Madagascan',
    'nationalities.mw': 'Malawian',
    'nationalities.my': 'Malaysian',
    'nationalities.mv': 'Maldivian',
    'nationalities.ml': 'Malian',
    'nationalities.mt': 'Maltese',
    'nationalities.mh': 'Marshallese',
    'nationalities.mr': 'Mauritanian',
    'nationalities.mu': 'Mauritian',
    'nationalities.fm': 'Micronesian',
    'nationalities.md': 'Moldovan',
    'nationalities.mc': 'Monacan',
    'nationalities.mn': 'Mongolian',
    'nationalities.me': 'Montenegrin',
    'nationalities.ma': 'Moroccan',
    'nationalities.mz': 'Mozambican',
    'nationalities.mm': 'Myanmar',
    'nationalities.na': 'Namibian',
    'nationalities.nr': 'Nauruan',
    'nationalities.np': 'Nepalese',
    'nationalities.nl': 'Dutch',
    'nationalities.nz': 'New Zealander',
    'nationalities.ni': 'Nicaraguan',
    'nationalities.ne': 'Nigerien',
    'nationalities.ng': 'Nigerian',
    'nationalities.mk': 'North Macedonian',
    'nationalities.no': 'Norwegian',
    'nationalities.om': 'Omani',
    'nationalities.pk': 'Pakistani',
    'nationalities.pw': 'Palauan',
    'nationalities.ps': 'Palestinian',
    'nationalities.pa': 'Panamanian',
    'nationalities.pg': 'Papua New Guinean',
    'nationalities.py': 'Paraguayan',
    'nationalities.pe': 'Peruvian',
    'nationalities.ph': 'Philippine',
    'nationalities.pl': 'Polish',
    'nationalities.qa': 'Qatari',
    'nationalities.ro': 'Romanian',
    'nationalities.ru': 'Russian',
    'nationalities.rw': 'Rwandan',
    'nationalities.kn': 'Kittitian and Nevisian',
    'nationalities.lc': 'Saint Lucian',
    'nationalities.vc': 'Saint Vincentian',
    'nationalities.ws': 'Samoan',
    'nationalities.sm': 'San Marinese',
    'nationalities.st': 'Sao Tomean',
    'nationalities.sa': 'Saudi Arabian',
    'nationalities.sn': 'Senegalese',
    'nationalities.rs': 'Serbian',
    'nationalities.sc': 'Seychellois',
    'nationalities.sl': 'Sierra Leonean',
    'nationalities.sg': 'Singaporean',
    'nationalities.sk': 'Slovak',
    'nationalities.si': 'Slovenian',
    'nationalities.sb': 'Solomon Islander',
    'nationalities.so': 'Somali',
    'nationalities.za': 'South African',
    'nationalities.ss': 'South Sudanese',
    'nationalities.lk': 'Sri Lankan',
    'nationalities.sd': 'Sudanese',
    'nationalities.sr': 'Surinamese',
    'nationalities.se': 'Swedish',
    'nationalities.ch': 'Swiss',
    'nationalities.sy': 'Syrian',
    'nationalities.tw': 'Taiwanese',
    'nationalities.tj': 'Tajik',
    'nationalities.tz': 'Tanzanian',
    'nationalities.th': 'Thai',
    'nationalities.tl': 'Timorese',
    'nationalities.tg': 'Togolese',
    'nationalities.to': 'Tongan',
    'nationalities.tt': 'Trinidadian and Tobagonian',
    'nationalities.tn': 'Tunisian',
    'nationalities.tr': 'Turkish',
    'nationalities.tm': 'Turkmen',
    'nationalities.tv': 'Tuvaluan',
    'nationalities.ug': 'Ugandan',
    'nationalities.ua': 'Ukrainian',
    'nationalities.ae': 'Emirati',
    'nationalities.gb': 'British',
    'nationalities.gb-nir': 'Northern Irish',
    'nationalities.us': 'American',
    'nationalities.uy': 'Uruguayan',
    'nationalities.uz': 'Uzbek',
    'nationalities.vu': 'Vanuatuan',
    'nationalities.va': 'Vatican',
    'nationalities.ve': 'Venezuelan',
    'nationalities.vn': 'Vietnamese',
    'nationalities.ye': 'Yemeni',
    'nationalities.zm': 'Zambian',
    'nationalities.zw': 'Zimbabwean'
  };