import PersonIcon from '@mui/icons-material/Person';
import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { NewPackNewsCard } from "./newpack/NewPackNewsCard";
import { UnfinishedMatch } from './unfinishedMatch/UnfinishedMatchCard';

export interface NewsCardProps {
    parent(children: any): any
}

export const TeamNews = () => {

    const teamColours = <Grid item xs={12}><Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Coming soon
            </Typography>
            <Typography variant="h5" component="div">
                Team Colours <PersonIcon color='primary' />
            </Typography>
            <Typography variant="body2">
                Choose your colours! Unlock new kits and badges through challenges. What colour will you be?
            </Typography>
        </CardContent>
    </Card></Grid>


    const parent = (children: any) => {
        return <Grid item xs>{children}</Grid>
    }
    return (
        <Grid container spacing={2}>
            <NewPackNewsCard parent={parent} />
            <UnfinishedMatch parent={parent} />
            {teamColours}
        </Grid>
    )
}