import "./PageContainer.css";
export interface PageContainerProps {
  children: any,
  noPadding?: boolean
}

export const PageContainerComponent = (props: PageContainerProps) => {
  return (
    <div className={`container ${props.noPadding ? 'no-padding' : ''}`}>
      {props.children}
    </div>
  );
}
