export const enRaces: { [key: string]: string } = {
    
    'races.DEVIL': 'Devil',
    'races.ELF': 'Elf',
    'races.ORC': 'Orc',
    'races.GOAT': 'Goat',
    'races.WIZARD': 'Wizard',
    'races.ANGEL': 'Angel',
    'races.HUMAN': 'Human',
    'races.DOG': 'Dog',
    'races.WONDERKID': 'Wonderkid',
    'races.BULL': 'Bull',
    'races.CELESTIAL': 'Celestial',
    'races.HERO': 'Hero',
    'races.SUPER_HERO': 'Super Hero',
    'races.HALFLING': 'Halfling',
    'races.MERMAID': 'Mermaid',
    'races.MERMAN': 'Merman',
    'races.DRAGON': 'Dragon',
    'races.MACHINE': 'Machine',
    'races.GIANT': 'Giant',
    'races.KNIGHT': 'Knight',
    'races.KING': 'King',
    'races.DINOSAUR': 'Dinosaur',
    'races.LION': 'Lion',
    'races.FORGED': 'Forged'
    


}