import React, { useEffect, useRef, useState } from 'react';

const TRANSITION_DURATION_MS = 1000; // 1 second fade
const IMAGE_DISPLAY_DURATION_MS = 30000; // 30 seconds


const imagePaths = [
    'arthur.png',
    'celestial.png',
    'colleseum.png',
    'colleseum_1.png',
    'crystal.png',
    'desert.png',
    'devils.png',
    'devils_1.png',
    'devils_2.png',
    'diavoli.png',
    'dwarf.png',
    'fire.png',
    'fire_1.png',
    'forge.png',
    'foxes.png',
    'gelerts.png',
    'gelerts_1.png',
    'golden_oldies.png',
    'gunners.png',
    'hobbits.png',
    'hobbits_1.png',
    'mullets.png',
    'mullets_1.png',
    'napoli.png',
    'oil.png',
    'old_ladies.png',
    'old_ladies_1.png',
    'old_ladies_2.png',
    'olympus.png',
    'orcs.png',
    'romans.png',
    'sky.png',
    'sunday.png',
    'tangerine.png',
    'tangerine_1.png',
    'trident.png',
    'underwater.png'
].map((image) => `/images/backgrounds/${image}`);

export const BackgroundCycler= () => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(-1); // Start at -1 until initialized
    const [nextImageIndex, setNextImageIndex] = useState<number | null>(null);
    const [isFading, setIsFading] = useState<boolean>(false);

    const imageTimeoutRef = useRef<number | null>(null);
    const fadeTimeoutRef = useRef<number | null>(null);

    // Initialize with a random image index when component mounts or images change
    useEffect(() => {
        if (imagePaths.length > 0) {
            setCurrentImageIndex(Math.floor(Math.random() * imagePaths.length));
        } else {
            setCurrentImageIndex(-1); // Reset if no valid images
        }
        // Clear timeouts when the image list changes
        return () => {
            if (imageTimeoutRef.current) clearTimeout(imageTimeoutRef.current);
            if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
        };
    }, []);

    // Function to get the next random index
    const getNextRandomIndex = (currentIndex: number): number => {
        if (imagePaths.length <= 1) {
            return currentIndex;
        }
        let nextIndex;
        do {
            nextIndex = Math.floor(Math.random() * imagePaths.length);
        } while (nextIndex === currentIndex && imagePaths.length > 1); // Ensure difference if possible
        return nextIndex;
    };

    // Effect to handle the image cycling timer
    useEffect(() => {
        // Only run the timer logic if we have valid images and a current index
        if (currentImageIndex < 0) {
            return; // Do nothing if no images or not initialized
        }

        // Clear previous timeouts before setting new ones
        if (imageTimeoutRef.current) clearTimeout(imageTimeoutRef.current);
        if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);

        // Set the main timer to start the fade process
        imageTimeoutRef.current = setTimeout(() => {
            const nextIndex = getNextRandomIndex(currentImageIndex);
            setNextImageIndex(nextIndex);
            setIsFading(true);

            // Set a timeout to switch the current image after the fade completes
            fadeTimeoutRef.current = setTimeout(() => {
                setCurrentImageIndex(nextIndex);
                // setNextImageIndex(null);
                setIsFading(false);
            }, TRANSITION_DURATION_MS);

        }, IMAGE_DISPLAY_DURATION_MS);

        // Cleanup function
        return () => {
            if (imageTimeoutRef.current) clearTimeout(imageTimeoutRef.current);
            if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
        };
        // Re-run effect when the current image index changes or the image list changes
    }, [currentImageIndex]);

    // --- Inline Styles --- (Identical to previous version)
    const commonBackgroundStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        zIndex: -1,
        transition: `opacity ${TRANSITION_DURATION_MS / 1000}s ease-in-out`,
    };

    // Handle cases where index might be invalid temporarily or no images exist
    const getImageUrl = (index: number | null): string => {
        if (index !== null && index >= 0 && index < imagePaths.length) {
            return `url(${imagePaths[index]})`;
        }
        return 'none'; // Default to no image if index is invalid
    };


    const currentImageStyle: React.CSSProperties = {
        ...commonBackgroundStyle,
        backgroundImage: getImageUrl(currentImageIndex),
        opacity: isFading ? 0 : 1,
    };

    const nextImageStyle: React.CSSProperties = {
        ...commonBackgroundStyle,
        backgroundImage: getImageUrl(nextImageIndex),
        // opacity: isFading ? 1 : 0,
    };

    // Don't render anything if there are no images to display
    if (imagePaths.length === 0) {
        return null;
    }

    return (
        <>
            <div style={nextImageStyle} aria-hidden="true" />
            <div style={currentImageStyle} aria-hidden="true" />
        </>
    );
};