"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RaceDetails = exports.Races = void 0;
exports.getRaceBonus = getRaceBonus;
const playerTraits_1 = require("./playerTraits");
var Races;
(function (Races) {
    Races["HUMAN"] = "HUMAN";
    Races["WONDERKID"] = "WONDERKID";
    Races["ORC"] = "ORC";
    Races["DOG"] = "DOG";
    Races["WIZARD"] = "WIZARD";
    Races["DEVIL"] = "DEVIL";
    Races["BULL"] = "BULL";
    Races["CELESTIAL"] = "CELESTIAL";
    Races["GOAT"] = "GOAT";
    Races["HERO"] = "HERO";
    Races["SUPER_HERO"] = "SUPER_HERO";
    Races["HALFLING"] = "HALFLING";
    Races["MERMAID"] = "MERMAID";
    Races["MERMAN"] = "MERMAN";
    Races["DRAGON"] = "DRAGON";
    Races["ELF"] = "ELF";
    Races["MACHINE"] = "MACHINE";
    Races["GIANT"] = "GIANT";
    Races["KNIGHT"] = "KNIGHT";
    Races["KING"] = "KING";
    Races["DINOSAUR"] = "DINOSAUR";
    Races["LION"] = "LION";
    Races["FORGED"] = "FORGED";
})(Races || (exports.Races = Races = {}));
exports.RaceDetails = {
    [Races.HUMAN]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
        // PlayerTraits.ADAPTABLE
        ] // Now has the EFFICIENT trait
    },
    [Races.WONDERKID]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
        // PlayerTraits.PRODIGY
        ] // Now has the PRODIGY trait
    },
    [Races.ELF]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
        // PlayerTraits.FINESSE
        ]
    },
    [Races.ORC]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.POWERFUL
        ]
    },
    [Races.DOG]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.MANS_BEST_FRIEND
        ]
    },
    [Races.WIZARD]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            // PlayerTraits.INTELLIGENCE
            playerTraits_1.PlayerTraits.PLAYMAKER
        ]
    },
    [Races.DEVIL]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            // PlayerTraits.SOUL_SAP,
            playerTraits_1.PlayerTraits.DOMINANT
        ]
    },
    [Races.BULL]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.CHARGE
        ]
    },
    [Races.CELESTIAL]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
        // PlayerTraits.STAR
        ]
    },
    [Races.GOAT]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            // PlayerTraits.LEGEND_OF_LEGENDS,
            playerTraits_1.PlayerTraits.CHARGE
        ]
    },
    [Races.HERO]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.HERO
        ]
    },
    [Races.HALFLING]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.LUCKY
        ]
    },
    [Races.MERMAID]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.FLAIR
        ]
    },
    [Races.MERMAN]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.FLAIR
        ]
    },
    [Races.DRAGON]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.DETERMINED,
            playerTraits_1.PlayerTraits.DOMINANT
        ]
    },
    [Races.SUPER_HERO]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            // PlayerTraits.HERO,
            playerTraits_1.PlayerTraits.DETERMINED,
        ]
    },
    [Races.MACHINE]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            // PlayerTraits.RELIABLE
            playerTraits_1.PlayerTraits.POWERFUL
        ]
    },
    [Races.GIANT]: {
        statBlock: {
            block: 2
        },
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.POWERFUL]
    },
    [Races.KNIGHT]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [playerTraits_1.PlayerTraits.SHIELD]
    },
    [Races.KING]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.DOMINANT,
            playerTraits_1.PlayerTraits.DETERMINED
        ]
    },
    [Races.DINOSAUR]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.POWERFUL,
        ]
    },
    [Races.LION]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.POWERFUL,
            playerTraits_1.PlayerTraits.DOMINANT
        ]
    },
    [Races.FORGED]: {
        statBlock: {},
        positionsEnabled: [],
        playerTraits: [
            playerTraits_1.PlayerTraits.UNYIELDING,
        ]
    }
};
function getRaceBonus(race) {
    return exports.RaceDetails[race];
}
