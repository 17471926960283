// --- MatchStateViewer.tsx ---
import { MatchState, RoundResult } from '@alienheadwars/football-card-manager-model';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from "react";
import { ButtonComponent } from '../../../base/button/Button';
import './Match.css';
import { ScoreboardComponent } from "./Scoreboard";
import { Contest } from './challenge/Contest';
import { RoundStateComponent } from './round/RoundStateComponent'; // Import the new component

export interface HasMatchState {
    matchState: MatchState
}

export interface MatchStateProps extends HasMatchState {
    onComplete(): void;
    playerTeamId: string; // Add player's team ID
}

export const MatchStateComponent = (props: MatchStateProps) => {
    const { matchState, playerTeamId } = props;
    const [currentRound, setCurrentRound] = useState(matchState.rounds.length - 1)
    const [showContest, setShowChallenge] = useState(false)
    const [curentRoundStateToView, setCurentRoundStateToView] = useState(matchState.rounds[currentRound])

    useEffect(() => {
        const currentRoundState = matchState.rounds[currentRound];
        const roundFinished = currentRoundState.roundFinished === true
        if (roundFinished) {
            if ([RoundResult.ATTACK_STALL, RoundResult.PASS_UNCONTESTED, RoundResult.RUN_UNCONTESTED, RoundResult.BOTH_STALL].includes(currentRoundState.roundResult!)) {
                setCurentRoundStateToView(matchState.rounds[currentRound])
            }
            else {
                setShowChallenge(true)
            }

        }
        else {
            setCurentRoundStateToView(currentRoundState)
        }
    }, [currentRound, matchState.rounds])

    const nextRound = () => setCurrentRound((round) => {
        const newRound = Math.min(round + 1, matchState.rounds.length - 1)
        setCurentRoundStateToView(matchState.rounds[newRound])
        return newRound
    })

    const completeViewChallenge = () => {
        setCurentRoundStateToView(matchState.rounds[currentRound])
        setShowChallenge(false)
    }

    return <Stack direction="column"
        sx={{ width: '100%', height: '100%' }}
        alignContent='center'
        justifyContent='flex-start' alignItems='center'>
        {showContest && <Contest
            onClick={completeViewChallenge}
            roundState={matchState.rounds[currentRound]}
            playerTeamId={playerTeamId}
            teams={matchState.teams}
        />}
        <ScoreboardComponent matchState={matchState} />
        <RoundStateComponent
            key={curentRoundStateToView.roundId}
            roundState={curentRoundStateToView}
            finishRound={nextRound}
            playerTeamId={playerTeamId}
            matchId={matchState.id}
            teams={matchState.teams}
        />
        {matchState.matchFinished && <ButtonComponent onClick={props.onComplete}>Finish Game</ButtonComponent>}
    </Stack>

};