import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import RecyclingIcon from '@mui/icons-material/Recycling';
import ShieldIcon from '@mui/icons-material/Shield';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import StarIcon from '@mui/icons-material/Star';
import { Box, SvgIconPropsSizeOverrides } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types'; import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import React from 'react'; import SwipeUpIcon from '@mui/icons-material/SwipeUp';

const IconOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
}));

const IconContainer = styled(Box)({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export interface IconProps {
    fontSize?: OverridableStringUnion<"small" | "inherit" | "large" | "medium", SvgIconPropsSizeOverrides>;
    primaryColor?: string;
    secondaryColor?: string;
}

export type IconId = "LEVEL"
    | "PASS"
    | "RUN"
    | "SHOOT"
    | "INTERCEPT"
    | "BLOCK"
    | "STALL"
    | "TACKLE"
    | "SWIPE_UP"
    | "CONTEST"
;


const Icons: Record<IconId, (props: IconProps) => JSX.Element> = {
    LEVEL: ({ fontSize, primaryColor }) => <StarIcon fontSize={fontSize} sx={{ color: primaryColor }} />,
    PASS: ({ fontSize, primaryColor }) => <RecyclingIcon fontSize={fontSize} sx={{ color: primaryColor }} />,
    RUN: ({ fontSize, primaryColor }) => <DirectionsRunIcon fontSize={fontSize} sx={{ color: primaryColor }} />,
    SHOOT: ({ fontSize, primaryColor }) => <SportsSoccerIcon fontSize={fontSize} sx={{ color: primaryColor }} />,
    INTERCEPT: ({ fontSize, primaryColor, secondaryColor }) => (
        <IconContainer>
            <ShieldIcon fontSize={fontSize} sx={{ color: primaryColor }} />
            <IconOverlay sx={{ color: secondaryColor }}>
                <RecyclingIcon fontSize={fontSize} sx={{ transform: 'scale(0.7)' }} />
            </IconOverlay>
        </IconContainer>
    ),
    BLOCK: ({ fontSize, primaryColor, secondaryColor }) => (
        <IconContainer>
            <ShieldIcon fontSize={fontSize} sx={{ color: primaryColor }} />
            <IconOverlay sx={{ color: secondaryColor }}>
                <SportsSoccerIcon fontSize={fontSize} sx={{ transform: 'scale(0.7)' }} />
            </IconOverlay>
        </IconContainer>
    ),
    TACKLE: ({ fontSize, primaryColor, secondaryColor }) => (
        <IconContainer>
            <ShieldIcon fontSize={fontSize} sx={{ color: primaryColor }} />
            <IconOverlay sx={{ color: secondaryColor }}>
                <DirectionsRunIcon fontSize={fontSize} sx={{ transform: 'scale(0.7)' }} />
            </IconOverlay>
        </IconContainer>
    ),
    STALL: ({ fontSize, primaryColor, secondaryColor }) => (
        <DoNotDisturbIcon fontSize={fontSize} sx={{ color: primaryColor }} />
    ),
    SWIPE_UP: ({ fontSize, primaryColor, secondaryColor }) => (
        <IconContainer>
            <SwipeUpIcon fontSize={fontSize} sx={{ color: primaryColor }} />
        </IconContainer>
    ),
    CONTEST:  ({ fontSize, primaryColor }) => <ShieldIcon fontSize={fontSize} sx={{ color: primaryColor }} />,
};

export const Icon = (props: { iconId: IconId } & IconProps) => {
    const IconComponent = Icons[props.iconId];

    if (!IconComponent) {
        console.warn(`Icon with id "${props.iconId}" not found.`);
        return null;
    }

    return IconComponent({
        fontSize: props.fontSize || 'inherit',
        primaryColor: props.primaryColor || 'inherit',
        secondaryColor: props.secondaryColor || 'red',
    });
};